export enum ErrorCodes {
  InternalError = 500,
  success = 200,
  UnAuthorized = 401,
}

export enum ErrorTypes {
  Unhandled = "unhandled",
  Validation = "validation",
}
export enum Gender {
  Male = "Male",
  Female = "Female",
  Other = "Other",
}
export enum ApplicationStatus {
  Pending = "Pending",
  Approved = "Approved",
  Rejected = "Rejected",
  Canceled = "Canceled",
}

export enum RequestStatus {
  Pending = "Pending",
  Approved = "Approved",
  Rejected = "Rejected",
}

export enum Status {
  Pending = "Pending",
  Approved = "Approved",
  Rejected = "Rejected",
  Canceld = "Cancelled",
}

export enum Relationship {
  Father = "Father",
  Mother = "Mother",
  Son = "Son",
  Daughter = "Daughter",
  Wife = "Wife",
  Husband = "Husband",
  Cousin = "Cousin",
}
export enum MartialStatus {
  Single = "Single",
  Married = "Married",
  Unmarried = "Unmarried",
}
export enum MembershipStatus {
  Pending = "Pending",
  Approved = "Approved",
  Rejected = "Rejected",
  Canceld = "Canceld",
}

export enum Level {
  Matriculation = "Matriculation",
  HSC = "HSC",
  UG = "UG",
  PG = "PG",
  Other = "Other",
}

export enum KudumbaUnit {
  "Kumaranashan Memorial Family Unit" = "Kumaranashan Memorial Family Unit - കുമാരനാശാൻ സ്മാരക കുടുംബ യൂണിറ്റ്",
  "Dr. Palpu Memorial Family Unit" = "Dr. Palpu Memorial Family Unit - ഡോ. പൽപ്പു സ്മാരക കുടുംബ യൂണിറ്റ്",
  "TK Madhavan Memorial Family Unit" = "TK Madhavan Memorial Family Unit - ടി.കെ. മാധവൻ സ്മാരക കുടുംബ യൂണിറ്റ് ",
  "C. Keshavan Memorial Family Unit" = "C. Keshavan Memorial Family Unit - സി.കേശവൻ സ്മാരക കുടുംബ യൂണിറ്റ്",
}

export enum MicroFinanceUnit {
  "Gurusmrithy Microfinance" = "Gurusmrithy Microfinance - ഗുരുസ്മൃതി മൈക്രോഫിനാൻസ്",
  "Guruvaram Microfinance" = "Guruvaram Microfinance - ഗുരുവരം മൈക്രോഫിനാൻസ്",
  "Gurupoornima Microfinance" = "Gurupoornima Microfinance - ഗുരുപൂർണിമ മൈക്രോഫിനാൻസ്",
  "Gurujoythi Microfinance" = "Gurujoythi Microfinance - ഗുരുജ്യോതി മൈക്രോഫിനാൻസ്",
  "Swami Saswthikanandha Microfinance" = "Swami Saswthikanandha Microfinance - സ്വാമി ശാശ്വതീകാനന്ദ മൈക്രോഫിനാൻസ്",
  "Gurudharsana Purusha Microfinance" = "Gurudharsana Purusha Microfinance - ഗുരുദർശന പുരുഷ മൈക്രോഫിനാൻസ്",
  "R.Shankar Purusha Microfinance" = "R.Shankar Purusha Microfinance - ആർ.ശങ്കർ പുരുഷ മൈക്രോഫിനാൻസ്",
  "Sivagiri Microfinance" = "Sivagiri Microfinance - ശിവഗിരി മൈക്രോഫിനാൻസ്",
  "Aruvipuram Microfinance" = "Aruvipuram Microfinance - അരുവിപ്പുറം മൈക്രോഫിനാൻസ്",
  "Gurukadaksham Microfinance" = "Gurukadaksham Microfinance - ഗുരുകടാക്ഷം മൈക്രോഫിനാൻസ്",
  "Gurukulam Microfinance" = "Gurukulam Microfinance - ഗുരുകുലം മൈക്രോഫിനാൻസ്",
  "Guruthyam Purusha Microfinance" = "Guruthyam Purusha Microfinance - ഗുരുത്യം പുരുഷ മൈക്രോഫിനാൻസ്",
  "M.K.Rghavan Purusha Microfinance" = "M.K.Rghavan Purusha Microfinance - എം.കെ.രാഘവൻ പുരുഷ മൈക്രോഫിനാൻസ്",
  "Chezhannanthi Microfinance" = "Chezhannanthi Microfinance - ചെഴന്നന്തി മൈക്രോഫിനാൻസ്",
  "Saradamam Microfinance" = "Saradamam Microfinance - ശരദാമം മൈക്രോഫിനാൻസ്",
  "Natarajaguru Microfinance" = "Natarajaguru Microfinance - നടരാജഗുരു മൈക്രോഫിനാൻസ്",
  "Gurudeepam Microfinance" = "Gurudeepam Microfinance - ഗുരുദീപം മൈക്രോഫിനാൻസ് ",
  "Guruprasadam Microfinance" = "Guruprasadam Microfinance - ഗുരുപ്രസാദം മൈക്രോഫിനാൻസ്",
  "Maaadanasaan Microfinance" = "Maaadanasaan Microfinance - മാടനാശാൻ മൈക്രോഫിനാൻസ്",
  "Gurudev Microfinance" = "Gurudev Microfinance - ഗുരുദേവ് ​​മൈക്രോഫിനാൻസ്",
  "Sree narayana Purusha Microfinance" = "Sree narayana Purusha Microfinance - ശ്രീനാരായണ പുരുഷ മൈക്രോഫിനാൻസ്",
  "Gurustavam Purusha Microfinance" = "Gurustavam Purusha Microfinance - ഗുരുസ്തവം പുരുഷ മൈക്രോഫിനാൻസ്",
  "Gurupadam Purusha Microfinance" = "Gurupadam Purusha Microfinance - ഗുരുപാദം പുരുഷ മൈക്രോഫിനാൻസ്",
  "Gurusagaram Purusha Microfinance" = "Gurusagaram Purusha Microfinance - ഗുരുസാഗരം പുരുഷ മൈക്രോഫിനാൻസ്",
}

export enum MalayalamMonth {
  Chingam = "Chingam",
  Kanni = "Kanni",
  Thulam = "Thulam",
  Vrischikam = "Vrischikam",
  Dhanu = "Dhanu",
  Makaram = "Makaram",
  Kumbham = "Kumbham",
  Meenam = "Meenam",
  Medam = "Medam",
  Edavam = "Edavam",
  Midhunam = "Midhunam	",
  Karkidakam = "Karkidakam",
}

export enum ResidenceType {
  "Apartments" = "Apartments",
  "Terrace" = "Terrace",
  "Tiled" = "Tiled",
  "Thatched" = "Thatched",
}

export enum RationCardType {
  "APL" = "APL",
  "BPL" = "BPL",
}
