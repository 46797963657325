import "./style.css";
import { FC } from "react";
import { Grid, InputLabel } from "@mui/material";
import { useFormik } from "formik";
import useLazyQuery from "../../../../../shared/components/hooks/useLazyQuery";
import { getValidationSchema } from "../../../../../shared/validations/schema";
import { useParams } from "react-router-dom";
import { FormContainer } from "../../../../../shared/components/formContainer";
import InputTextfield from "../../../../../shared/components/textField";
import ToggleSwitch from "../../../../../shared/components/switch";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import Permissions from "../permissionList";

const Role: FC = () => {
  const { id } = useParams();
  const { modifyData } = useMutation("/user-management/role");

  const { data } = useLazyQuery(`/admin/roles/${id}`, null);

  const rolesForm = useFormik({
    initialValues: {
      name: (data as any)?.data?.name ?? "",
      rolePermissions: (data as any)?.data?.permissions ?? [],
      enabled: (data as any)?.data?.enabled ?? true,
    },

    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: getValidationSchema(["name", "rolePermissions"]),
    onSubmit: async (formData: any) => {
      if (id) {
        modifyData(
          `/admin/roles/${id}`,
          "patch",
          {
            ...formData,
            permissions: rolesForm.values.rolePermissions,
          },
          rolesForm
        );
      } else {
        modifyData(
          `/admin/roles`,
          "post",
          {
            ...formData,
            permissions: rolesForm.values.rolePermissions,
          },
          rolesForm
        );
      }
    },
  });

  const editPermission = (data as any)?.entityPermissions
    ? !(data as any)?.entityPermissions?.includes("Role-Update")
    : false;

  const titleBar = {
    title: id ? (data as any)?.data?.name ?? "" : "Create",
    buttonTitle: id ? "Update" : "Create",
    headerTitle: "Role",
    onClick: () => rolesForm.handleSubmit(),
    dirty: rolesForm.dirty,
    disabled: editPermission || Object.keys(rolesForm.errors).length !== 0,
  };

  return (
    <>
      <FormContainer titleBar={titleBar}>
        <div className={editPermission ? "disabled" : ""}>
          <Grid container alignItems="stretch">
            <Grid
              item
              xs={12}
              className={
                (data as any)?.data?.name === "Owner" ? "disabledGrid" : ""
              }
            >
              <ToggleSwitch
                status={rolesForm?.values?.enabled}
                handleChange={() =>
                  rolesForm.setFieldValue(
                    "enabled",
                    !rolesForm?.values?.enabled
                  )
                }
              />
            </Grid>
            <Grid
              item
              md={4}
              sm={6}
              xs={12}
              className={
                (data as any)?.data?.name === "Owner" ? "disabledGrid" : ""
              }
            >
              <InputTextfield
                form={rolesForm}
                fieldProps={{
                  id: "role-name",
                  name: "name",
                  label: "Name",
                  type: "text",
                  placeholder: "Enter name",
                  required: true,
                  capitalizedFirst: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className="allPermissionLabel">
                Grands permissions
              </InputLabel>
            </Grid>
            <Permissions
              rolePermissions={rolesForm?.values?.rolePermissions}
              rolesForm={rolesForm}
            />
          </Grid>
        </div>
      </FormContainer>
    </>
  );
};
export default Role;
