import MembershipList from "../components/membershipList";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import GroupsIcon from "@mui/icons-material/Groups";
const membershipRoute = {
  icon: GroupsIcon,
  name: "Yogam Memberships",
  link: "/yogamMemberships",
  path: "yogamMemberships",
  element: "",
  index: 3,
  children: [
    {
      icon: LibraryBooksIcon,
      name: "Eligible Members",
      path: "/yogamMemberships/membership",
      link: "yogamMemberships/membership",
      index: 1,
      element: <MembershipList />,
    },
  ],
};
export default membershipRoute;
