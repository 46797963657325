import LogoutIcon from "@mui/icons-material/Logout";
import Logout from "../component/index";

const roleRoute = {
  icon: LogoutIcon,
  name: "Logout",
  link: "/logout",
  path: "/logout",
  element: <Logout />,
  index: 9,
};
export default roleRoute;
