import MembershipRequestList from "../components/yogamMembershipRequestList";
import ListAltIcon from "@mui/icons-material/ListAlt";
import GroupsIcon from "@mui/icons-material/Groups";
const memberRequestRoute = {
  icon: GroupsIcon,
  name: "Yogam Memberships",
  link: "/yogamMemberships",
  path: "yogamMemberships",
  element: "",
  index: 3,
  children: [
    {
      path: "yogamMembershipRequests",
      element: <MembershipRequestList />,
      name: "Yogam Membership Requests",
      index: 2,
      link: "/yogamMemberships/yogamMembershipRequests",
      icon: ListAltIcon,
    },
  ],
};
export default memberRequestRoute;
