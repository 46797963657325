import { Navigate } from "react-router-dom";
import Event from "../components/event";
import EventList from "../components/eventList";

import EventIcon from "@mui/icons-material/Event";
const eventRoute = {
  icon: EventIcon,
  name: "Event Management",
  link: "/eventManagement",
  path: "eventManagement",
  element: "",
  index: 4,
  children: [
    {
      path: "events",
      element: <EventList />,
      name: "Events",
      index: 1,
      link: "/eventManagement/events",
      icon: EventIcon,
    },
    {
      path: "events/create",
      element: <Event />,
    },
    {
      path: "events/:id",
      element: <Event />,
    },

    {
      path: "",
      element: <Navigate to="events" replace />,
    },
  ],
};
export default eventRoute;
