import { FC, useContext, useEffect, useState } from "react";
import { Grid, List } from "@mui/material";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { FormContainer } from "../../../../../shared/components/formContainer";
import InputTextfield from "../../../../../shared/components/textField";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import { getValidationSchema } from "../../../../../shared/validations/schema";
import { ZSSelectBox } from "../../../../../shared/components/selectBox";
import { Gender, MalayalamMonth } from "../../../../../shared/enum";
import { ZSAutocomplete } from "../../../../../shared/components/autoComplete";
import { useLocation } from "react-router-dom";
import { EntityContext } from "../../../../../contexts/EntityContext";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import { Family } from "../../../../../shared/interfaces/family";
import { parse, isValid, format, isAfter } from "date-fns";
import InputFormLabel from "../../../../../shared/components/inputFormLabel";

const Member: FC = () => {
  const location = useLocation();

  const { id } = useParams();
  const { loading: apiLoading, modifyData } = useMutation(
    "/branchMembership/members"
  );
  const { payload, setPayload } = useContext(SidebarContext);
  const [familyID, setFamilyID] = useState(location.state?.familyID);
  const { setEntityName } = useContext(EntityContext);

  useEffect(() => {
    setEntityName({
      entityName: ["Create"],
      entityTitle: "Create Member",
    });
  }, [setEntityName]);

  const handleDOBChange = (newValue: string) => {
    let value = newValue.replace(/\D/g, "");
    let dayPart = value.slice(0, 2);
    let monthPart = value.slice(2, 4);
    let yearPartPart = value.slice(4, 8);

    if (dayPart === "00") dayPart = "01";
    if (monthPart === "00") monthPart = "01";

    if (parseInt(dayPart, 10) > 31) dayPart = "31";
    if (parseInt(monthPart, 10) > 12) monthPart = "12";

    if (monthPart === "02" && parseInt(dayPart, 10) > 29) dayPart = "29";
    else if (
      ["04", "06", "09", "11"].includes(monthPart) &&
      parseInt(dayPart, 10) > 30
    )
      dayPart = "30";
    value = dayPart + (value.length >= 2 ? "/" + monthPart : "");
    value = value + (value.length >= 4 ? "/" + yearPartPart : "");

    if (
      newValue?.length === 2 &&
      memberForm?.values?.dob.charAt(memberForm?.values?.dob?.length - 1) ===
        "/"
    ) {
      value = dayPart;
    }
    memberForm.setFieldValue("dob", value);
  };
  const numberOptions = Array.from({ length: 32 }, (_, i) => ({
    label: `${i + 1}`,
    value: `${i + 1}`,
  }));
  function validateDateFormat(dateString: string, formatString: string) {
    const parsedDate = parse(dateString, formatString, new Date());
    return (
      isValid(parsedDate) && dateString === format(parsedDate, formatString)
    );
  }
  function validateNotFutureDate(dateString: string, formatString: string) {
    const parsedDate = parse(dateString, formatString, new Date());
    const today = new Date();
    return !isAfter(parsedDate, today);
  }
  const { data: familyData } = useQuery("/admin/family", {
    params: {
      ...payload,
      take: 10,
      sortOrder: "ASC",
      enabled: true,
    },
  });
  interface SelectedFamilyOption {
    value: string;
    label: string;
  }
  const selectedFamily: SelectedFamilyOption | null =
    familyData && (familyData as Family)?.familyCode
      ? {
          value: (familyData as Family)?.familyCode,
          label: (familyData as Family)?.familyCode,
        }
      : null;

  const families = (familyData as any)?.items?.map((family: any) => ({
    label: `${family?.familyCode} - ${family?.familyAddresses[0]?.houseNameEng}`,
    value: family?.id,
  }));

  const memberForm = useFormik({
    initialValues: {
      familyID: "",
      fullNameEng: "",
      fullNameMal: "",
      email: "",
      phone: null,
      sndpMemberRegNo: "",
      joinedSangamNo: "",
      dob: "",
      gender: null,
      kollavarsham: null,
      month: null,
      date: null,
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: getValidationSchema([
      "fullNameEng",
      "fullNameMal",
      "gender",
      "phone",
      "email",
      "dob",
      "familyID",
    ]),
    validate: (values: any) => {
      const errors: any = {};

      if (values?.dob) {
        const isValidDate = validateDateFormat(values?.dob, "dd/MM/yyyy");
        if (!isValidDate) {
          errors.dob = "Invalid Date formet ( DD/MM/YYYY )";
        } else {
          const isValidAndNotFuture = validateNotFutureDate(
            values?.dob,
            "dd/MM/yyyy"
          );
          if (!isValidAndNotFuture) {
            errors.dob = "Date of birth cannot be a future date";
          }
        }
      }
      return errors;
    },
    onSubmit: async (formData: any) => {
      if (!formData.phone) {
        formData.phone = null;
      }
      if (id) {
        modifyData(
          `/admin/members/${id}`,
          "put",
          {
            ...formData,
          },
          memberForm
        );
      } else {
        modifyData(
          `/admin/members`,
          "post",
          {
            ...formData,
          },
          memberForm
        );
      }
      setFamilyID("");
    },
  });

  useEffect(() => {
    if (familyID) {
      memberForm.setFieldValue("familyID", familyID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [familyID]);

  // const editPermission = (data as any)?.entityPermissions
  //   ? !(data as any)?.entityPermissions?.includes("Member-Update")
  //   : false;
  const titleBar = {
    title: "Create Member",
    buttonTitle: "Save",
    headerTitle: "Member",
    onClick: () => memberForm.handleSubmit(),
    dirty: memberForm.dirty,
    buttonLoading: apiLoading,
  };

  return (
    <FormContainer titleBar={titleBar}>
      <div className="main-div">
        <Grid container alignItems="stretch" columnSpacing={4} rowSpacing={0}>
          {!familyID ? (
            <>
              <Grid item md={2} sm={2} xs={12}>
                <InputFormLabel
                  label={"Family"}
                  required={true}
                ></InputFormLabel>
              </Grid>
              <Grid item mb={1} md={4} sm={4} xs={12}>
                <ZSAutocomplete
                  options={families}
                  placeholder="Select Family"
                  required
                  setValue={(selectedOption: any) => {
                    memberForm.setFieldTouched("familyID", true);
                    if (selectedOption?.value) {
                      memberForm.setFieldValue(
                        "familyID",
                        selectedOption?.value
                      );
                    } else {
                      memberForm.setFieldValue("familyID", "");
                    }
                  }}
                  defaultValue={selectedFamily}
                  setBlur={() => {
                    memberForm.setFieldTouched("familyID", true);
                  }}
                  errorMessage={
                    ((memberForm.touched as any)?.familyID &&
                      (memberForm.errors as any)?.familyID) ??
                    null
                  }
                  refetchFunction={(searchString: string) => {
                    setPayload((prevData: any) => {
                      return { ...prevData, search: searchString };
                    });
                  }}
                />
              </Grid>
            </>
          ) : null}
        </Grid>
        <List dense className="border-custom">
          <Grid
            container
            alignItems="stretch"
            columnSpacing={4}
            rowSpacing={0}
            sx={{ mb: 3 }}
          >
            <Grid item md={2} sm={2} xs={12}>
              <InputFormLabel
                label={"Name in English"}
                required={true}
              ></InputFormLabel>
            </Grid>
            <Grid item mb={1} md={4} sm={4} xs={12}>
              <InputTextfield
                form={memberForm}
                value={memberForm?.values?.fullNameEng || ""}
                fieldProps={{
                  id: "fullNameEng",
                  name: "fullNameEng",
                  type: "text",
                  required: true,
                  placeholder: "Name in english",
                }}
              />
            </Grid>

            <Grid item md={2} sm={2} xs={12}>
              <InputFormLabel
                label={"Name in Malayalam"}
                required={true}
              ></InputFormLabel>
            </Grid>
            <Grid item mb={1} md={4} sm={4} xs={12}>
              <InputTextfield
                form={memberForm}
                value={memberForm?.values?.fullNameMal || ""}
                fieldProps={{
                  id: "fullNameMal",
                  name: "fullNameMal",
                  type: "text",
                  placeholder: "Name in malayalam",
                  required: true,
                }}
              />
            </Grid>

            <Grid item container spacing={2}>
              <Grid item md={2} sm={2} xs={12}>
                <InputFormLabel
                  label={"Date of birth"}
                  required={true}
                ></InputFormLabel>
              </Grid>
              <Grid item mb={1} md={4} sm={4} xs={12}>
                <InputTextfield
                  form={memberForm}
                  value={memberForm?.values?.dob || ""}
                  fieldProps={{
                    id: "dob",
                    name: "dob",
                    type: "text",
                    required: false,
                    placeholder: "DD/MM/YYYY",
                  }}
                  onChange={handleDOBChange}
                />
              </Grid>
              <Grid item md={2} sm={2} xs={12}>
                <InputFormLabel label={"DOB in malayalam"}></InputFormLabel>
              </Grid>
              <Grid item mb={1} md={1} sm={3} xs={12}>
                <Grid item ml={1}>
                  <ZSSelectBox
                    fieldProps={{
                      id: "member-date",
                      name: "date",
                      placeholder: "day",
                    }}
                    options={numberOptions}
                    form={memberForm}
                    value={memberForm.values?.date}
                  />
                </Grid>
              </Grid>
              <Grid item mb={1} md={1} sm={3} xs={12}>
                <ZSSelectBox
                  fieldProps={{
                    id: "member-month",
                    name: "month",
                    placeholder: "Month",
                  }}
                  options={[
                    {
                      label: MalayalamMonth.Chingam,
                      value: MalayalamMonth.Chingam,
                    },
                    {
                      label: MalayalamMonth.Kanni,
                      value: MalayalamMonth.Kanni,
                    },
                    {
                      label: MalayalamMonth.Thulam,
                      value: MalayalamMonth.Thulam,
                    },
                    {
                      label: MalayalamMonth.Vrischikam,
                      value: MalayalamMonth.Vrischikam,
                    },
                    {
                      label: MalayalamMonth.Dhanu,
                      value: MalayalamMonth.Dhanu,
                    },
                    {
                      label: MalayalamMonth.Makaram,
                      value: MalayalamMonth.Makaram,
                    },
                    {
                      label: MalayalamMonth.Kumbham,
                      value: MalayalamMonth.Kumbham,
                    },
                    {
                      label: MalayalamMonth.Meenam,
                      value: MalayalamMonth.Meenam,
                    },
                    {
                      label: MalayalamMonth.Medam,
                      value: MalayalamMonth.Medam,
                    },
                    {
                      label: MalayalamMonth.Edavam,
                      value: MalayalamMonth.Edavam,
                    },
                    {
                      label: MalayalamMonth.Midhunam,
                      value: MalayalamMonth.Midhunam,
                    },
                    {
                      label: MalayalamMonth.Karkidakam,
                      value: MalayalamMonth.Karkidakam,
                    },
                  ]}
                  form={memberForm}
                  value={memberForm.values?.month}
                />
              </Grid>

              <Grid item mb={1} md={2} sm={3} xs={12}>
                <InputTextfield
                  form={memberForm}
                  value={memberForm?.values?.kollavarsham || ""}
                  fieldProps={{
                    id: "kollavarsham",
                    name: "kollavarsham",
                    type: "text",
                    required: true,
                    placeholder: "Kollavarsham",
                  }}
                />
              </Grid>
            </Grid>
            <Grid item md={2} sm={2} xs={12}>
              <InputFormLabel label={"Gender"} required={true}></InputFormLabel>
            </Grid>
            <Grid item mb={1} md={4} sm={4} xs={12}>
              <ZSSelectBox
                fieldProps={{
                  id: "member-gender",
                  name: "gender",
                  placeholder: "Select gender",
                }}
                options={[
                  { label: Gender.Male, value: Gender.Male },
                  { label: Gender.Female, value: Gender.Female },
                  { label: Gender.Other, value: Gender.Other },
                ]}
                form={memberForm}
                value={memberForm.values?.gender}
              />
            </Grid>
            <Grid item md={2} sm={2} xs={12}>
              <InputFormLabel label={"Email"}></InputFormLabel>
            </Grid>
            <Grid item mb={1} md={4} sm={4} xs={12}>
              <InputTextfield
                form={memberForm}
                value={memberForm?.values?.email || ""}
                fieldProps={{
                  id: "email",
                  name: "email",
                  type: "email",
                  required: true,
                  placeholder: "Email",
                }}
              />
            </Grid>
            <Grid item md={2} sm={2} xs={12}>
              <InputFormLabel label={"Phone"} required={true}></InputFormLabel>
            </Grid>
            <Grid item mb={1} md={4} sm={4} xs={12}>
              <InputTextfield
                form={memberForm}
                value={memberForm?.values?.phone || ""}
                fieldProps={{
                  id: "phone",
                  name: "phone",
                  type: "text",
                  required: true,
                  placeholder: "Phone",
                }}
              />
            </Grid>
            <Grid item md={2} sm={2} xs={12}>
              <InputFormLabel label={"Union Membership No"}></InputFormLabel>
            </Grid>
            <Grid item mb={1} md={4} sm={4} xs={12}>
              <InputTextfield
                form={memberForm}
                value={memberForm?.values?.sndpMemberRegNo || ""}
                fieldProps={{
                  id: "sndpMemberRegNo",
                  name: "sndpMemberRegNo",
                  type: "text",
                  required: true,
                  placeholder: "Union membership number",
                }}
              />
            </Grid>
            <Grid item md={2} sm={2} xs={12}>
              <InputFormLabel label={"Joined Sangam No"}></InputFormLabel>
            </Grid>
            <Grid item mb={1} md={4} sm={4} xs={12}>
              <InputTextfield
                form={memberForm}
                value={memberForm?.values?.joinedSangamNo || ""}
                fieldProps={{
                  id: "joinedSangamNo",
                  name: "joinedSangamNo",
                  type: "joinedSangamNo",
                  required: true,
                  placeholder: "Joined sangam number",
                }}
              />
            </Grid>
          </Grid>
        </List>
        {/* <div className={editPermission ? "disabled" : ""}></div> */}
      </div>
    </FormContainer>
  );
};

export default Member;
