import { FC, useEffect } from "react";
import { Grid } from "@mui/material";
import { useFormik } from "formik";
import useLazyQuery from "../../../../../shared/components/hooks/useLazyQuery";
import { useParams } from "react-router-dom";
import { FormContainer } from "../../../../../shared/components/formContainer";
import InputTextfield from "../../../../../shared/components/textField";
import ToggleSwitch from "../../../../../shared/components/switch";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import { getValidationSchema } from "../../../../../shared/validations/schema";

const WelfareTypes: FC = () => {
  const { id } = useParams();
  const { modifyData } = useMutation("/welfareSchemes/welfareType");

  const { loading, data, fetchData } = useLazyQuery(
    `/admin/welfareType/${id}`,
    null,
    "/welfareSchemes/welfareType"
  );

  useEffect(() => {
    if (id) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const welfareTypeForm = useFormik({
    initialValues: {
      welfareTypeName: (data as any)?.data?.welfareTypeName ?? "",
      enabled: (data as any)?.data?.enabled ?? true,
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: getValidationSchema(["welfareTypeName"]),
    onSubmit: async (formData: any) => {
      if (id) {
        modifyData(
          `/admin/welfareType/${id}`,
          "put",
          {
            ...formData,
          },
          welfareTypeForm
        );
      } else {
        modifyData(
          `/admin/welfareType`,
          "post",
          {
            ...formData,
          },
          welfareTypeForm
        );
      }
    },
  });

  const editPermission = (data as any)?.entityPermissions
    ? !(data as any)?.entityPermissions?.includes("WelfareType-Update")
    : false;

  const titleBar = {
    title: id ? (data as any)?.data?.welfareTypeName ?? "" : "Create",
    buttonTitle: id ? "Update" : "Create",
    headerTitle: "Welfare Type",
    onClick: () => welfareTypeForm.handleSubmit(),
    dirty: welfareTypeForm.dirty,
    disabled:
      editPermission || Object.keys(welfareTypeForm.errors).length !== 0,
  };

  return (
    <>
      <FormContainer titleBar={titleBar} loading={loading}>
        <div className={editPermission ? "disabled" : ""}>
          <Grid container alignItems="stretch">
            <Grid item xs={12}>
              <ToggleSwitch
                status={welfareTypeForm?.values?.enabled}
                handleChange={() =>
                  welfareTypeForm.setFieldValue(
                    "enabled",
                    !welfareTypeForm?.values?.enabled
                  )
                }
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <InputTextfield
                form={welfareTypeForm}
                fieldProps={{
                  id: "welfare-type",
                  name: "welfareTypeName",
                  label: "Welfare Type Name",
                  type: "text",
                  placeholder: "Enter Welfare Type",
                  required: true,
                  capitalizedFirst: true,
                }}
              />
            </Grid>
          </Grid>
        </div>
      </FormContainer>
    </>
  );
};
export default WelfareTypes;
