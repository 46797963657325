import { Navigate, RouteObject } from "react-router-dom";
import SidebarLayout from "./layouts/SidebarLayout";
import BaseLayout from "./layouts/BaseLayout";
import { useContext } from "react";
import { SidebarContext } from "./contexts/SidebarContext";
import Home from "./pages/dashboard/components/home/home";
import ResetPassword from "./pages/reset-password/components";
import ForgotPassword from "./pages/forgot-password/components";
import Login from "./pages/authentication/components";

let appRoutes: RouteObject[];

const Router = () => {
  const { routes } = useContext(SidebarContext);

  appRoutes = [
    {
      path: "",
      element: <BaseLayout />,
      children: [
        {
          path: "",
          element: <Home />,
        },

        {
          path: "/login",
          element: <Login />,
        },
        {
          path: "/forgot-password",
          element: <ForgotPassword />,
        },
        {
          path: "/reset-password",
          element: <ResetPassword />,
        },
      ],
    },
    {
      element: <SidebarLayout />,
      children: routes?.concat([
        {
          path: "*",
          element: <Navigate to="" replace />,
        },
      ]),
    },
  ];

  return appRoutes;
};

export default Router;
