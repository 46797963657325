/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SnackBarAlertContext } from "../../../contexts/SnackbarContext";
import request from "../../../request";

const useLazyQuery = (
  endPoint: string,
  variables?: any,
  reDirectTo?: string | null
) => {
  const snackBarAlertContext = useContext(SnackBarAlertContext);

  const navigate = useNavigate();
  const [response, setResponse] = useState({
    loading: false,
    error: null,
    data: null,
  });
  const fetchData = async () => {
    try {
      setResponse({
        loading: true,
        data: null,
        error: null,
      });
      let apiResponse = await request.get(endPoint, variables);
      if (response) {
        setResponse({
          loading: false,
          data: apiResponse?.data,
          error: null,
        });
      }
    } catch (error: any) {
      setResponse({ loading: false, data: null, error: error });
      if (reDirectTo) {
        navigate(reDirectTo);
      }
      snackBarAlertContext.set({
        status: true,
        type: "error",
        message: error.message,
      });
    }
  };

  return { ...response, fetchData };
};

export default useLazyQuery;
