/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef } from "react";
import { IconButton, styled, InputBase } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import "./styles.css";
import { useLocation } from "react-router-dom";
import { SidebarContext } from "../../../../contexts/SidebarContext";
import searchPlaceHolder from "./placeholder";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "var(--zs-white)",
  marginLeft: 0,
  width: "75%",
  height: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: "100%",
  "& .MuiInputBase-input": {
    color: "var(--zs-grey)",
    paddingLeft: `calc(1em + ${theme.spacing(1)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      // width: "12ch",
      "&:focus": {
        // width: "20ch",
      },
    },
  },
}));

function SearchBox() {
  const location = useLocation();
  const searchBoxReference: any = useRef();
  const { payload, setPayload } = useContext(SidebarContext);

  const handleSearch = () => {
    setPayload((prevData: any) => {
      return {
        ...prevData,
        skip: 0,
        search: searchBoxReference?.current?.value,
      };
    });
  };

  const handleClear = () => {
    setPayload((prevData: any) => {
      return {
        ...prevData,
        skip: 0,
        search: "",
      };
    });
    searchBoxReference.current.value = "";
  };

  useEffect(() => {
    if (searchPlaceHolder[`${location.pathname.split("/").pop()}`]) {
      handleClear();
    }
  }, [location]);

  return searchPlaceHolder[`${location.pathname.split("/").pop()}`] ? (
    <Search className="searchIcon searchBoxBg">
      <StyledInputBase
        id="search"
        placeholder={
          searchPlaceHolder?.[`${location.pathname.split("/").pop()}`] ??
          "Search here"
        }
        inputProps={{ "aria-label": "search" }}
        inputRef={searchBoxReference}
        autoComplete="off"
        onKeyDown={(e) => {
          if (e?.key === "Enter") {
            handleSearch();
          }
        }}
        onChange={(e) => {
          if (e?.target?.value === "" && payload?.search) handleClear();
        }}
      />
      {payload?.search && searchBoxReference?.current?.value && (
        <IconButton size="medium" onClick={handleClear}>
          <CloseIcon />
        </IconButton>
      )}
      <IconButton size="medium" onClick={handleSearch}>
        <SearchIcon />
      </IconButton>
    </Search>
  ) : (
    <></>
  );
}

export default SearchBox;
