import "./style.css";
import { FC, useEffect, useState, useContext } from "react";
import { useFormik } from "formik";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Button,
  List,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
// import useLazyQuery from "../../../../../shared/components/hooks/useLazyQuery";
import { useParams } from "react-router-dom";
// import { FormContainer } from "../../../../../shared/components/formContainer";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import { ZSSelectBox } from "../../../../../shared/components/selectBox";
import InputTextfield from "../../../../../shared/components/textField";
import { useNavigate } from "react-router-dom";

import {
  KudumbaUnit,
  MicroFinanceUnit,
  RationCardType,
  ResidenceType,
} from "../../../../../shared/enum";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { CheckBoxOutlined } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import FamilyMembersList from "./familyMembersList";
import { EntityContext } from "../../../../../contexts/EntityContext";
import AddressHistoryList from "./addressHistoryList ";
import { Address } from "../../../../../shared/interfaces/address";
import { Family } from "../../../../../shared/interfaces/family";
import { Member } from "../../../../../shared/interfaces/member";
import { ViewContainer } from "../../../../../shared/components/viewContainer";
import { getValidationSchema } from "../../../../../shared/validations/schema";

const FamilyView: FC = () => {
  const { id } = useParams();
  const { data, fetchData } = useQuery(`/admin/family/${id}`, null);
  const navigate = useNavigate();
  const { modifyData } = useMutation();

  const [editStatus, setEditStatus] = useState(false);
  const [newAddress, setNewAddress] = useState(false);
  const [selectedMicroFinance, setSelectedMicroFinance] = useState("");
  const [selectedKudumbaUnit, setSelectedKudumbaUnit] = useState("");
  const { setEntityName } = useContext(EntityContext);
  const [editCurrentAddressStatus, setEditCurrentAddressStatus] =
    useState(false);

  useEffect(() => {
    setEntityName({
      entityName: ["Edit"],
      entityTitle: "Edit Family",
    });
  }, [setEntityName]);

  const editPermission = (data as any)?.entityPermissions
    ? !(data as any)?.entityPermissions?.includes("Member-Update")
    : false;

  const familyData: Family = (data as any)?.data || {};

  let currentAddress: Address | null =
    familyData?.familyAddresses?.find(
      (address: Address) => address.currentAddress
    ) || null;
  const familyHead: Member | null =
    familyData?.familyMembers?.find((member: Member) => member.isFamilyHead) ||
    null;
  const familyMembers: Member[] | null = familyData?.familyMembers || null;
  const handlejoinedDateChange = (newValue: string) => {
    let value = newValue.replace(/\D/g, "");
    let dayPart = value.slice(0, 2);
    let monthPart = value.slice(2, 4);
    let yearPartPart = value.slice(4, 8);
    if (dayPart === "00") dayPart = "01";
    if (monthPart === "00") monthPart = "01";
    if (parseInt(dayPart, 10) > 31) dayPart = "31";
    if (parseInt(monthPart, 10) > 12) monthPart = "12";

    if (monthPart === "02" && parseInt(dayPart, 10) > 29) dayPart = "29";
    else if (
      ["04", "06", "09", "11"].includes(monthPart) &&
      parseInt(dayPart, 10) > 30
    )
      dayPart = "30";
    value = dayPart + (value.length >= 2 ? "/" + monthPart : "");
    value = value + (value.length >= 4 ? "/" + yearPartPart : "");
    if (
      newValue?.length === 2 &&
      familyForm?.values?.joinedDate.charAt(
        familyForm?.values?.joinedDate?.length - 1
      ) === "/"
    ) {
      value = dayPart;
    }
    familyForm.setFieldValue("joinedDate", value);
  };
  const handledateOfResidenceChange = (newValue: string) => {
    let value = newValue.replace(/\D/g, "");
    let dayPart = value.slice(0, 2);
    let monthPart = value.slice(2, 4);
    let yearPartPart = value.slice(4, 8);

    if (dayPart === "00") dayPart = "01";
    if (monthPart === "00") monthPart = "01";

    if (parseInt(dayPart, 10) > 31) dayPart = "31";
    if (parseInt(monthPart, 10) > 12) monthPart = "12";

    if (monthPart === "02" && parseInt(dayPart, 10) > 29) dayPart = "29";
    else if (
      ["04", "06", "09", "11"].includes(monthPart) &&
      parseInt(dayPart, 10) > 30
    )
      dayPart = "30";
    value = dayPart + (value.length >= 2 ? "/" + monthPart : "");
    value = value + (value.length >= 4 ? "/" + yearPartPart : "");
    if (
      newValue?.length === 2 &&
      familyForm?.values?.dateOfResidence.charAt(
        familyForm?.values?.dateOfResidence?.length - 1
      ) === "/"
    ) {
      value = dayPart;
    }
    familyForm.setFieldValue("dateOfResidence", value);
  };

  const { data: lsgdsData } = useQuery(`/admin/lsgds`);

  const lsgdsList = (lsgdsData as any)?.items.map(
    (lsgds: any, index: number) => ({
      value: lsgds?.id,
      key: index,
      label: `${lsgds?.districtEng}, ${lsgds?.talukEng},  ${lsgds?.panchayathEng},  Ward No - ${lsgds?.wardNo}`,
    })
  );

  const familyForm = useFormik({
    initialValues: {
      houseNo: currentAddress?.houseNo ?? "",
      houseNameEng: currentAddress?.houseNameEng ?? "",
      houseNameMal: currentAddress?.houseNameMal ?? "",
      streetNameEng: currentAddress?.streetNameEng ?? "",
      streetNameMal: currentAddress?.streetNameMal ?? "",
      rationCardNumber: (data as any)?.data?.rationCardNumber ?? "",
      rationCardType: (data as any)?.data?.rationCardType ?? null,
      kudumbaUnitNameEng: (data as any)?.data?.kudumbaUnitNameEng ?? null,
      kudumbaUnitNameMal: (data as any)?.data?.kudumbaUnitNameMal ?? null,
      microFinanceNameEng: (data as any)?.data?.microFinanceNameEng ?? "",
      microFinanceNameMal: (data as any)?.data?.microFinanceNameMal ?? null,
      residenceType: currentAddress?.residenceType ?? null,
      currentAddress: currentAddress?.currentAddress ?? true,
      dateOfResidence: currentAddress?.dateOfResidence ?? null,
      joinedDate: (data as any)?.data?.joinedDate ?? null,
      annualIncome: (data as any)?.data?.annualIncome ?? "",
      addressID: (data as any)?.data?.addressID ?? null,
      memberID: (data as any)?.data?.memberID ?? null,
      wardNo: currentAddress?.lsgdID ?? null,
      ownLand: (data as any)?.data?.ownLand ?? false,
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: getValidationSchema([
      "houseNo",
      "houseNameEng",
      "streetNameEng",
      "wardNo",
      "joinedDate",
      "dateOfResidence",
      "residenceType",
      "houseNameMal",
      "streetNameMal",
      "rationCardNumber",
      "kudumbaUnitNameEng",
    ]),
    validate: (values: any) => {
      // const errors: any = {};
    },
    onSubmit: async (formData: any, { resetForm }) => {
      if (!formData.dateOfResidence) {
        formData.dateOfResidence = null;
      }
      if (formData.ownLand) {
        formData.ownLand = formData.ownLand.toString();
      }
      if (newAddress) {
        if (id) {
          await modifyData(
            `admin/family/add-aadress/${id}`,
            "post",
            {
              ...formData,
            },
            familyForm
          );
        }
      } else {
        if (id) {
          await modifyData(
            `admin/family/${id}`,
            "patch",
            {
              ...formData,
            },
            familyForm
          );
        }
      }
      fetchData();
      setEditCurrentAddressStatus(false);
      setNewAddress(false);
      setEditStatus(false);
    },
  });

  const handleFieldChange = (name: string, value: string) => {
    familyForm.setFieldValue(name, value);
  };

  const onCheckChange = () => {
    familyForm.setFieldValue("ownLand", !familyForm.values.ownLand);
  };

  const closeFamilyEdit = () => {
    fetchData();
    setSelectedMicroFinance("");
    setSelectedKudumbaUnit("");
    setEditStatus(false);
  };

  const showFamilyEdit = () => {
    setSelectedKudumbaUnit(
      `${(data as any)?.data?.kudumbaUnitNameEng} - ${
        (data as any)?.data?.kudumbaUnitNameMal
      }`
    );
    setSelectedMicroFinance(
      `${(data as any)?.data?.microFinanceNameEng} - ${
        (data as any)?.data?.microFinanceNameMal
      }`
    );
    setEditStatus(true);
  };

  const closeAddressForm = () => {
    fetchData();

    familyForm.setFieldValue("houseNo", currentAddress?.houseNo);
    familyForm.setFieldValue("houseNameEng", currentAddress?.houseNameEng);
    familyForm.setFieldValue("houseNameMal", currentAddress?.houseNameMal);
    familyForm.setFieldValue("streetNameEng", currentAddress?.streetNameEng);
    familyForm.setFieldValue("streetNameMal", currentAddress?.streetNameMal);
    familyForm.setFieldValue("residenceType", currentAddress?.residenceType);
    familyForm.setFieldValue("currentAddress", currentAddress?.currentAddress);
    familyForm.setFieldValue(
      "dateOfResidence",
      currentAddress?.dateOfResidence
    );
    familyForm.setFieldValue("lsgdID", currentAddress?.lsgdID);
    familyForm.setFieldValue("wardNo", currentAddress?.lsgdID);
    familyForm.setTouched({}, false);
    familyForm.setErrors({});
    setEditCurrentAddressStatus(false);
    setNewAddress(false);
  };
  const openNewAddress = () => {
    familyForm.setFieldValue("houseNo", "");
    familyForm.setFieldValue("houseNameEng", "");
    familyForm.setFieldValue("houseNameMal", "");
    familyForm.setFieldValue("streetNameEng", "");
    familyForm.setFieldValue("streetNameMal", "");
    familyForm.setFieldValue("residenceType", null);
    familyForm.setFieldValue("currentAddress", true);
    familyForm.setFieldValue("dateOfResidence", null);
    familyForm.setFieldValue("lsgdID", null);
    familyForm.setFieldValue("wardNo", null);
    setNewAddress(true);
  };

  const addNewMember = () => {
    navigate(`/branchMembership/members/create`, { state: { familyID: id } });
  };

  const onSelectKudumbaUnit = (value: any) => {
    setSelectedKudumbaUnit(value);
    let parts = value.split(" - ");
    let eng = parts[0];
    let mal = parts[1];
    familyForm.setFieldValue("kudumbaUnitNameEng", eng);
    familyForm.setFieldValue("kudumbaUnitNameMal", mal);
  };

  const onSelectMicrpFinance = (value: any) => {
    setSelectedMicroFinance(value);
    let parts = value.split(" - ");
    let eng = parts[0];
    let mal = parts[1];
    familyForm.setFieldValue("microFinanceNameEng", eng);
    familyForm.setFieldValue("microFinanceNameMal", mal);
  };

  let microFinanceList = Array.from(
    Object.entries(MicroFinanceUnit),
    ([key, value]) => ({
      label: value,
      value: value,
    })
  );

  let KudumbaUnitList = Array.from(
    Object.entries(KudumbaUnit),
    ([key, value]) => ({
      label: value,
      value: value,
    })
  );

  return (
    <ViewContainer>
      <div className={editPermission ? "disabled" : ""}>
        <div className="main-div">
          <List dense className="border-custom">
            <Grid container sx={{ mb: 1 }} spacing={2}>
              <Grid item md={11} xs={8}>
                <span>
                  <b>Family Details</b>
                </span>
              </Grid>
              <Grid item md={1} xs={1} sx={{ justifyContent: "flex-end" }}>
                {editStatus ? (
                  <Tooltip arrow title="Cancel">
                    <IconButton
                      className="TitleBarIcon"
                      size="medium"
                      id={`Fmily-edit`}
                      onClick={() => closeFamilyEdit()}
                      aria-label={`Fmily-edit`}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip arrow title="Edit">
                    <IconButton
                      className="TitleBarIcon"
                      size="medium"
                      id={`Fmily-edit`}
                      onClick={() => showFamilyEdit()}
                      aria-label={`Fmily-edit`}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
            <Box
              sx={{ padding: "20px" }}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <TableContainer component={Paper}>
                <Table size="small" sx={{ "& td": { border: 0 } }}>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <strong>Family Code : </strong>
                      </TableCell>
                      <TableCell>
                        {familyData?.familyCode || "No Data"}
                      </TableCell>
                      <TableCell>
                        <strong>Head :</strong>
                      </TableCell>
                      <TableCell>
                        {familyHead?.fullNameEng || "No Data"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ mt: 3 }}>
                        <strong>Ration Card Number :</strong>
                      </TableCell>
                      <TableCell>
                        {!editStatus ? (
                          <> {familyData?.rationCardNumber || "No Data"}</>
                        ) : (
                          <Grid sx={{ mt: 2 }}>
                            <InputTextfield
                              form={familyForm}
                              value={familyForm?.values?.rationCardNumber || ""}
                              fieldProps={{
                                id: "family-rationCardNumber",
                                name: "rationCardNumber",
                                type: "text",
                                required: true,
                                placeholder: "Ration Card Number",
                              }}
                            />
                          </Grid>
                        )}
                      </TableCell>
                      <TableCell>
                        <strong>Ration Card Type :</strong>
                      </TableCell>
                      <TableCell className="mt-3 ">
                        {!editStatus ? (
                          <>{familyData?.rationCardType || "No Data"}</>
                        ) : (
                          <Grid sx={{ mt: 2 }}>
                            <ZSSelectBox
                              fieldProps={{
                                id: "family-rationCardType",
                                name: "rationCardType",
                                placeholder: "Select an option",
                              }}
                              options={[
                                {
                                  label: RationCardType["APL"],
                                  value: RationCardType["APL"],
                                },
                                {
                                  label: RationCardType["BPL"],
                                  value: RationCardType["BPL"],
                                },
                              ]}
                              form={familyForm}
                              value={familyForm.values?.rationCardType}
                            />
                          </Grid>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>Annual Income:</strong>
                      </TableCell>
                      <TableCell>
                        {!editStatus ? (
                          <> {familyData?.annualIncome || "No Data"}</>
                        ) : (
                          <TextField
                            id="fmily-annualIncome"
                            variant="outlined"
                            placeholder="Annual Income"
                            fullWidth
                            value={familyForm.values.annualIncome}
                            onChange={(e) =>
                              handleFieldChange("annualIncome", e.target.value)
                            }
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        <strong>
                          Joined Date
                          {!editStatus ? null : (
                            <Typography component="span" color="error">
                              *
                            </Typography>
                          )}
                          :
                        </strong>
                      </TableCell>
                      <TableCell>
                        {!editStatus ? (
                          <> {familyData?.joinedDate || "No Data"}</>
                        ) : (
                          <Grid sx={{ mt: 2 }}>
                            <InputTextfield
                              form={familyForm}
                              value={familyForm?.values?.joinedDate || ""}
                              fieldProps={{
                                id: "family-joinedDate",
                                name: "joinedDate",
                                // label: "Date Of JOIN",
                                type: "text",
                                required: false,
                                placeholder: "DD/MM/YYYY",
                              }}
                              onChange={handlejoinedDateChange}
                            />
                          </Grid>
                        )}
                      </TableCell>
                    </TableRow>
                    {!editStatus ? (
                      <TableRow>
                        <TableCell>
                          <strong>Kudumba Unit :</strong>
                        </TableCell>
                        <TableCell>
                          {familyData?.kudumbaUnitNameEng || "No Data"}
                        </TableCell>
                        <TableCell>
                          <strong>Micro Finance :</strong>
                        </TableCell>
                        <TableCell>
                          {familyData?.microFinanceNameEng || "No Data"}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <>
                        <TableRow>
                          <TableCell>
                            <strong>
                              Kudumba Unit
                              <Typography component="span" color="error">
                                *
                              </Typography>
                              :
                            </strong>
                          </TableCell>
                          <TableCell colSpan={6}>
                            <Grid sx={{ mt: 2 }}>
                              <ZSSelectBox
                                fieldProps={{
                                  id: "family-kudumbaUnitNameMal",
                                  name: "kudumbaUnitNameMal",
                                  placeholder: "Select an option",
                                }}
                                options={KudumbaUnitList}
                                // form={familyForm}
                                value={selectedKudumbaUnit}
                                onChange={onSelectKudumbaUnit}
                              />
                            </Grid>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>
                            <strong>Micro Finance :</strong>
                          </TableCell>
                          <TableCell colSpan={6}>
                            <Grid sx={{ mt: 2 }}>
                              <ZSSelectBox
                                fieldProps={{
                                  id: "family-microFinanceNameMal",
                                  name: "microFinanceNameMal",
                                  placeholder: "Select an option",
                                }}
                                options={microFinanceList}
                                onChange={onSelectMicrpFinance}
                                // form={familyForm}
                                value={selectedMicroFinance}
                              />
                            </Grid>
                          </TableCell>
                        </TableRow>
                      </>
                    )}

                    <TableRow>
                      <TableCell>
                        <strong>Own Land :</strong>
                      </TableCell>
                      <TableCell>
                        {!editStatus ? (
                          <>{familyData?.ownLand ? "Yes" : "No" || "No Data"}</>
                        ) : (
                          <FormControlLabel
                            control={
                              <Checkbox
                                className="selectAll"
                                checkedIcon={<CheckBoxOutlined />}
                                disableRipple
                                checked={familyForm.values?.ownLand}
                                onChange={onCheckChange}
                              />
                            }
                            labelPlacement="start"
                            label={""}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    {editStatus ? (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              familyForm.handleSubmit();
                            }}
                            disabled={editPermission}
                          >
                            Save
                          </Button>
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </List>

          <List dense className="border-custom">
            <Grid container sx={{ mb: 1 }} spacing={2}>
              <Grid
                item
                md={editCurrentAddressStatus || newAddress ? 11 : 11}
                xs={8}
              >
                <span>
                  <b>Currrent Address</b>
                </span>
              </Grid>

              {editCurrentAddressStatus || newAddress ? (
                <Grid item md={1} xs={1} sx={{ justifyContent: "flex-end" }}>
                  <Tooltip arrow title="Cancel">
                    <IconButton
                      className="TitleBarIcon"
                      size="medium"
                      id={`Fmily-edit`}
                      onClick={closeAddressForm}
                      aria-label={`Fmily-edit`}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              ) : (
                <>
                  <Grid
                    item
                    md={1}
                    xs={1}
                    sx={{ justifyContent: "flex-end", display: "flex" }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Tooltip arrow title="Add New">
                        <IconButton
                          className="TitleBarIcon"
                          size="medium"
                          id={`currentAddress-edit`}
                          onClick={openNewAddress}
                          aria-label={`currentAddress-edit`}
                        >
                          <AddIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip arrow title="Edit">
                        <IconButton
                          className="TitleBarIcon"
                          size="medium"
                          id={`currentAddress-edit`}
                          onClick={() =>
                            setEditCurrentAddressStatus(
                              !editCurrentAddressStatus && !newAddress
                            )
                          }
                          aria-label={`currentAddress-edit`}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
            <Box
              sx={{ padding: "15px" }}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <TableContainer component={Paper}>
                <Table size="small" sx={{ "& td": { border: 0 } }}>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <strong>
                          House No
                          {!editCurrentAddressStatus && !newAddress ? null : (
                            <Typography component="span" color="error">
                              *
                            </Typography>
                          )}
                          :
                        </strong>
                      </TableCell>
                      <TableCell>
                        {!editCurrentAddressStatus && !newAddress ? (
                          <> {currentAddress?.houseNo || "No Data"}</>
                        ) : (
                          <Grid sx={{ mt: 2 }}>
                            <InputTextfield
                              form={familyForm}
                              fieldProps={{
                                id: "family-houseNo",
                                name: "houseNo",
                                label: "House No",
                                type: "text",
                                placeholder: "House No",
                                required: false,
                              }}
                            />
                          </Grid>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>
                          House Name Eng
                          {!editCurrentAddressStatus && !newAddress ? null : (
                            <Typography component="span" color="error">
                              *
                            </Typography>
                          )}
                          :
                        </strong>
                      </TableCell>
                      <TableCell>
                        {!editCurrentAddressStatus && !newAddress ? (
                          <> {currentAddress?.houseNameEng || "No Data"}</>
                        ) : (
                          <Grid sx={{ mt: 2 }}>
                            <InputTextfield
                              form={familyForm}
                              fieldProps={{
                                id: "family-houseNameEng",
                                name: "houseNameEng",
                                label: "House Name (Eng)",
                                type: "text",
                                placeholder: "House Name (English)",
                                required: true,
                              }}
                            />
                          </Grid>
                        )}
                      </TableCell>
                      <TableCell>
                        <strong>
                          House Name Mal
                          {!editCurrentAddressStatus && !newAddress ? null : (
                            <Typography component="span" color="error">
                              *
                            </Typography>
                          )}
                          :
                        </strong>
                      </TableCell>
                      <TableCell>
                        {!editCurrentAddressStatus && !newAddress ? (
                          <> {currentAddress?.houseNameMal || "No Data"}</>
                        ) : (
                          <Grid sx={{ mt: 2 }}>
                            <InputTextfield
                              form={familyForm}
                              fieldProps={{
                                id: "family-houseNameMal",
                                name: "houseNameMal",
                                label: "House Name (Mal)",
                                type: "text",
                                placeholder: "House Name (Malayalam)",
                                required: true,
                              }}
                            />
                          </Grid>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>
                          Street Name Eng
                          {!editCurrentAddressStatus && !newAddress ? null : (
                            <Typography component="span" color="error">
                              *
                            </Typography>
                          )}
                          :
                        </strong>
                      </TableCell>
                      <TableCell>
                        {!editCurrentAddressStatus && !newAddress ? (
                          <> {currentAddress?.streetNameEng || "No Data"}</>
                        ) : (
                          <Grid sx={{ mt: 2 }}>
                            <InputTextfield
                              form={familyForm}
                              fieldProps={{
                                id: "family-streetNameEng",
                                name: "streetNameEng",
                                label: "Street Name (Eng)",
                                type: "text",
                                placeholder: "Street Name (English)",
                                required: true,
                              }}
                            />
                          </Grid>
                        )}
                      </TableCell>
                      <TableCell>
                        <strong>
                          Street Name Mal
                          {!editCurrentAddressStatus && !newAddress ? null : (
                            <Typography component="span" color="error">
                              *
                            </Typography>
                          )}
                          :
                        </strong>
                      </TableCell>
                      <TableCell>
                        {!editCurrentAddressStatus && !newAddress ? (
                          <> {currentAddress?.streetNameMal || "No Data"}</>
                        ) : (
                          <Grid sx={{ mt: 2 }}>
                            <InputTextfield
                              form={familyForm}
                              fieldProps={{
                                id: "family-streetNameMal",
                                name: "streetNameMal",
                                label: "Street Name (Mal)",
                                type: "text",
                                placeholder: "Street Name (Malayalam)",
                                required: true,
                              }}
                            />
                          </Grid>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>
                          Date Of Residence
                          {!editCurrentAddressStatus && !newAddress ? null : (
                            <Typography component="span" color="error">
                              *
                            </Typography>
                          )}
                          :
                        </strong>
                      </TableCell>
                      <TableCell sx={{ mt: 2 }}>
                        {!editCurrentAddressStatus && !newAddress ? (
                          <> {currentAddress?.dateOfResidence || "No Data"}</>
                        ) : (
                          <Grid sx={{ mt: 2 }}>
                            <InputTextfield
                              form={familyForm}
                              value={familyForm?.values?.dateOfResidence || ""}
                              fieldProps={{
                                id: "family-dateOfResidence",
                                name: "dateOfResidence",
                                type: "text",
                                required: false,
                                placeholder: "DD/MM/YYYY",
                              }}
                              onChange={handledateOfResidenceChange}
                            />
                          </Grid>
                        )}
                      </TableCell>
                      <TableCell>
                        <strong>
                          Residence Type
                          {!editCurrentAddressStatus && !newAddress ? null : (
                            <Typography component="span" color="error">
                              *
                            </Typography>
                          )}
                          :
                        </strong>
                      </TableCell>
                      <TableCell>
                        {!editCurrentAddressStatus && !newAddress ? (
                          <> {currentAddress?.residenceType || "No Data"}</>
                        ) : (
                          <Grid sx={{ mt: 2 }}>
                            <ZSSelectBox
                              fieldProps={{
                                id: "family-residenceType",
                                name: "residenceType",
                                placeholder: "Select an option",
                              }}
                              options={[
                                {
                                  label: ResidenceType["Apartments"],
                                  value: ResidenceType["Apartments"],
                                },
                                {
                                  label: ResidenceType["Terrace"],
                                  value: ResidenceType["Terrace"],
                                },
                                {
                                  label: ResidenceType["Tiled"],
                                  value: ResidenceType["Tiled"],
                                },
                                {
                                  label: ResidenceType["Thatched"],
                                  value: ResidenceType["Thatched"],
                                },
                              ]}
                              form={familyForm}
                              value={familyForm.values?.residenceType}
                            />
                          </Grid>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>
                          Ward No
                          {!editCurrentAddressStatus && !newAddress ? null : (
                            <Typography component="span" color="error">
                              *
                            </Typography>
                          )}
                          :
                        </strong>
                      </TableCell>
                      {!editCurrentAddressStatus && !newAddress ? (
                        <>
                          <TableCell>
                            {`${currentAddress?.lsgds?.districtEng}, ${currentAddress?.lsgds?.talukEng}, ${currentAddress?.lsgds?.panchayathEng}, Ward No - ${currentAddress?.lsgds?.wardNo}` ||
                              "No Data"}
                          </TableCell>
                        </>
                      ) : (
                        <TableCell colSpan={6}>
                          <Grid sx={{ mt: 2 }}>
                            <ZSSelectBox
                              fieldProps={{
                                id: "family-wardNo",
                                name: "wardNo",
                                placeholder: "Select an option",
                              }}
                              options={lsgdsList}
                              form={familyForm}
                              value={familyForm.values?.wardNo}
                            />
                          </Grid>
                        </TableCell>
                      )}
                    </TableRow>

                    {editCurrentAddressStatus || newAddress ? (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              familyForm.handleSubmit();
                            }}
                            disabled={editPermission}
                          >
                            Save
                          </Button>
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </TableBody>
                </Table>
              </TableContainer>

              {familyData?.familyAddresses?.length > 1 ? (
                <Grid container sx={{ mb: 1, mt: 2 }} spacing={2}>
                  <Grid item md={11} xs={8}>
                    <span>
                      <b>Address History</b>
                    </span>
                  </Grid>
                  <AddressHistoryList
                    addressHistory={familyData?.familyAddresses}
                    addressId={currentAddress?.id}
                  />
                </Grid>
              ) : null}
            </Box>
          </List>

          <List dense className="border-custom">
            <Grid container sx={{ mb: 1 }} spacing={2}>
              <Grid item md={11} xs={8}>
                <span>
                  <b>Family Members</b>
                </span>
              </Grid>

              <>
                <Grid item md={1} xs={1} sx={{ justifyContent: "flex-end" }}>
                  <Tooltip arrow title="Add New Member">
                    <IconButton
                      className="TitleBarIcon"
                      size="medium"
                      id={`currentAddress-edit`}
                      onClick={addNewMember}
                      aria-label={`currentAddress-edit`}
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </>
            </Grid>
            <Box display="flex" flexDirection="column" alignItems="center">
              <FamilyMembersList
                family={currentAddress}
                familyMembers={familyMembers}
              ></FamilyMembersList>
            </Box>
          </List>
        </div>
      </div>
    </ViewContainer>
  );
};

export default FamilyView;
