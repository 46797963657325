import "./styles.css";
import { Box, Grid } from "@mui/material";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import InputTextfield from "../../../shared/components/textField";
import { getValidationSchema } from "../../../shared/validations/schema";
import useMutation from "../../../shared/components/hooks/useMutation";
import Logo from "../../../assets/img/logo-connect.svg";

const ForgotPassword = () => {
  const { loading, modifyData } = useMutation();

  const textFields = [
    {
      id: "email",
      name: "email",
      label: "Email Address",
      placeholder: "Enter your email address",
      type: "text",
    },
  ];

  const forgotPasswordForm = useFormik({
    initialValues: {
      email: "",
    },
    validateOnBlur: true,
    validationSchema: getValidationSchema(["email"]),
    onSubmit: async (formData: any) => {
      const response = await modifyData(
        `/sendPasswordResetLink`,
        "post",
        formData
      );
      if (response) forgotPasswordForm.resetForm();
    },
  });

  return (
    <Grid container spacing={1} className="login-row">
      <Grid item md={4}></Grid>

      <Grid item md={4} className="">
        <img src={Logo} className="login-logo" alt="" />
        <Box className="fomrCard">
          <form
            onSubmit={forgotPasswordForm.handleSubmit}
            autoComplete="off"
            className="forgotPasswordForm"
          >
            <InputTextfield
              className="mediumInputBox"
              form={forgotPasswordForm}
              fieldProps={textFields[0]}
            />
            <Grid item md={12}></Grid>
            <Grid item md={6}>
              <LoadingButton
                className="signInButton"
                loading={loading}
                variant="contained"
                type="submit"
                fullWidth
                size="small"
              >
                Forgot Password
              </LoadingButton>
            </Grid>
          </form>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
