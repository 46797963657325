import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FC, useContext } from "react";
import TitleBar from "../../../../../shared/components/titleBar";
import { EntityContext } from "../../../../../contexts/EntityContext";
import DataTable from "../../../../../shared/components/dataTable";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import NoPermissionContent from "../../../../../shared/components/noPermissionBanner";
import Loader from "../../../../../shared/components/loader/linearProgress";
import SearchBox from "../../../../../layouts/SidebarLayout/Header/SearchBox";

const WelfareList: FC = () => {
  const navigate = useNavigate();
  const { payload, setPayload } = useContext(SidebarContext);

  const { loading, data, fetchData, error } = useQuery("/admin/welfare", {
    params: {
      ...payload,
      welfare: "all",
    },
  });
  const { setEntityName } = useContext(EntityContext);

  const navigateToDetails = () => {
    setEntityName({
      entityName: ["Create"],
      entityTitle: "Welfare",
    });
    navigate("/welfareSchemes/welfare/create");
  };
  const welfare = (data as any)?.items?.map((welfare: any) => {
    return {
      ...welfare,

      welfareName: welfare?.welfareTypeName,
      welfareTypeName: welfare?.welfareType?.welfareTypeName,
    };
  });

  const welfareTypeDisplayColumns = [
    "welfareName",
    "welfareTypeName",
    "enabled",
  ];

  return (
    <Container className="tableListContainer">
      <TitleBar
        title="Welfare"
        buttonTitle="Create"
        onClick={navigateToDetails}
        headerTitle="Welfare"
        disabled={
          (data as any)?.entityPermissions
            ? !(data as any)?.entityPermissions?.includes("Welfare-Create")
            : false
        }
      >
        <SearchBox />
      </TitleBar>
      {loading ? (
        <Loader />
      ) : (error as any)?.statusCode === 403 ? (
        <NoPermissionContent />
      ) : (
        <DataTable
          entityName="Welfare"
          data={welfare}
          displayColumns={welfareTypeDisplayColumns}
          linkColumn="name"
          linkUrl="/welfareSchemes/welfare"
          pagination={{ take: payload.take, skip: payload.skip }}
          setPagination={setPayload}
          totalItems={(data as any)?.totalItems}
          deleteApiUrl="/admin/welfare"
          refetchFunction={fetchData}
        />
      )}
    </Container>
  );
};

export default WelfareList;
