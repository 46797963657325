import Certificate from "../components/certificate";
import CertificateList from "../components/certificatetList";
import ApprovalIcon from "@mui/icons-material/Approval";
const certificateRoute = [
  {
    path: "certificate",
    element: <CertificateList />,
    name: "Certificates",
    index: 5,
    link: "certificate",
    icon: ApprovalIcon,
  },
  {
    path: "certificate/create",
    element: <Certificate />,
  },
  {
    path: "certificate/:id",
    element: <Certificate />,
  },
];
export default certificateRoute;
