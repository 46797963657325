import "./styles.css";
import { useContext, useEffect } from "react";
import { EntityContext } from "../../../../contexts/EntityContext";
import {
  getLocalStorageData,
  clearLocalStorageData,
} from "../../../../shared/utils/localStorage";

const Dashboard = () => {
  const { setEntityName } = useContext(EntityContext);
  useEffect(() => {
    getLocalStorageData("token");
    setEntityName({ entityName: "Dashboard", entityTitle: "Dashboard" });
  }, [setEntityName]);

  useEffect(() => {
    const token = getLocalStorageData("token");
    if (token) {
    } else {
      clearLocalStorageData();
      window.location.href = "/login";
    }
  }, []);
  return (
    <>
      {/* <FormContainer>
        <img className="graphDashboard" src={DashboardImage} alt="dashboard" />
      </FormContainer> */}
      <br></br>
      {/* <Sample /> */}
    </>
  );
};

export default Dashboard;
