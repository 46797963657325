import {
  Checkbox,
  Chip,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Box,
} from "@mui/material";
import "./styles.css";
import { FC, useState } from "react";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import PaginationComponent from "../pagination";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import NoDataFound from "../noDataFoundBanner";
import DeletePermissionDialog from "../deletePermissionModal";
import useMutation from "../hooks/useMutation";
import { Link, useNavigate } from "react-router-dom";
import { takeLastElement } from "./helper";
type DataTableProps = {
  data: Object[];
  entityName?: string;
  linkColumn?: string;
  linkUrl?: string;
  pagination: any;
  setPagination: Function;
  displayColumns: string[];
  sortableColumns?: string[];
  totalItems?: number;
  deleteApiUrl?: string;
  refetchFunction?: Function;
  showCheckboxes?: boolean;
  extraAction?: any;
  newAction?: any;
  onUpdateStatus?: (ids: any[]) => void;
  renderCell?: (row: any, col: string) => React.ReactNode;
};

const DataTable: FC<DataTableProps> = ({
  data,
  entityName,
  linkColumn,
  linkUrl,
  pagination,
  setPagination,
  displayColumns,
  sortableColumns,
  totalItems,
  deleteApiUrl,
  refetchFunction = () => {},
  showCheckboxes = false,
  extraAction,
  newAction,
  onUpdateStatus = () => {},
  renderCell,
}) => {
  const { modifyData } = useMutation(linkUrl);
  const [deletedRecord, setDeletedRecord] = useState<any>();
  const [checkedIds, setCheckedIds] = useState<any[]>([]);
  const navigate = useNavigate();
  const invertOrderDirection: any = {
    ASC: "DESC",
    DESC: "ASC",
  };

  const handleDelete = async () => {
    await modifyData(`${deleteApiUrl}/${deletedRecord.id}`, "delete");
    setDeletedRecord(null);
    refetchFunction();
  };
  const handleCheckboxChange = (id: number) => {
    const updatedCheckedIds = checkedIds.includes(id)
      ? checkedIds.filter((item) => item !== id)
      : [...checkedIds, id];
    setCheckedIds(updatedCheckedIds);
    onUpdateStatus(updatedCheckedIds);
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const allIds = event.target.checked
      ? data.map((item: any) => {
          return { id: item.id, memberID: item.memberID };
        })
      : [];
    setCheckedIds(allIds?.map((data) => data?.id));
    onUpdateStatus(allIds);
  };
  const handleSort = (columnName: any) => {
    setPagination((current: any) => {
      return {
        ...current,
        sortBy: columnName,
        sortOrder:
          current.sortBy === columnName
            ? invertOrderDirection[current.sortOrder]
            : "ASC",
      };
    });
  };

  const booleanStatus: any = {
    verified: {
      true: "Verified",
      false: "Not Verified",
    },
    status: {
      true: "Success",
      false: "Failed",
    },
    enabled: {
      true: "Enabled",
      false: "Disabled",
    },
  };

  return (
    <>
      {data?.length > 0 ? (
        <>
          {deletedRecord ? (
            <DeletePermissionDialog
              open={deletedRecord ? true : false}
              handleClose={() => {
                setDeletedRecord(null);
              }}
              deletedData={{
                name: linkColumn ? deletedRecord[linkColumn] : "",
                content: entityName ?? "",
              }}
              handleDelete={handleDelete}
            />
          ) : (
            ""
          )}
          <div className="scrollBar">
            <Table
              className={linkUrl ? "dataTable editableDataTable" : "dataTable"}
            >
              <TableHead>
                <TableRow className="headerRow">
                  {showCheckboxes && (
                    <TableCell align="left" className="columnName">
                      <Checkbox
                        checked={
                          checkedIds.length > 0 &&
                          checkedIds.length === data.length
                        }
                        indeterminate={
                          checkedIds.length > 0 &&
                          checkedIds.length < data.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                  )}

                  <TableCell align="left" className="columnName">
                    #
                  </TableCell>
                  {displayColumns.map((title, columnIndex: number) => (
                    <TableCell
                      align={title in booleanStatus ? "center" : "left"}
                      className="columnName"
                      key={columnIndex}
                      onClick={() => handleSort(title)}
                    >
                      <div className="boxSort">
                        {title === "createdAt"
                          ? "Date & Time"
                          : takeLastElement(title)
                              ?.replace(/([a-z])([A-Z])/g, "$1 $2")
                              .replace("Eng", "")}
                        {sortableColumns?.includes(title) ? (
                          <Box>
                            {pagination?.sortOrder === "ASC" &&
                            pagination?.sortBy === title ? (
                              <KeyboardArrowUpIcon
                                className="sortIcon"
                                fontSize="small"
                              />
                            ) : (
                              <KeyboardArrowDownIcon
                                className="sortIcon"
                                fontSize="small"
                              />
                            )}
                          </Box>
                        ) : null}
                      </div>
                    </TableCell>
                  ))}
                  {deleteApiUrl || extraAction ? (
                    <TableCell align="center" className="columnName">
                      Actions
                    </TableCell>
                  ) : (
                    ""
                  )}
                </TableRow>
              </TableHead>

              <TableBody>
                {data.map((dataItem: any, dataIndex: number) => (
                  <TableRow
                    key={dataIndex}
                    onClick={() => {
                      linkUrl && navigate(`${linkUrl}/${dataItem.id}`);
                    }}
                  >
                    {showCheckboxes && (
                      <TableCell align="left" className="tableCell">
                        <Checkbox
                          checked={checkedIds.includes(dataItem.id)}
                          onChange={() => handleCheckboxChange(dataItem.id)}
                        />
                      </TableCell>
                    )}

                    <TableCell align="left" className="tableCell">
                      {pagination?.skip * pagination?.take + dataIndex + 1}
                    </TableCell>
                    {displayColumns.map((title, columnIndex: number) => (
                      <TableCell
                        align={
                          typeof dataItem[title] == "boolean"
                            ? "center"
                            : "left"
                        }
                        className="tableCell"
                        key={columnIndex}
                      >
                        {renderCell ? (
                          renderCell(dataItem, title)
                        ) : linkColumn === title ? (
                          <Link
                            className="link"
                            to={linkUrl + `/${dataItem.id}`}
                          >
                            {dataItem[title]}
                          </Link>
                        ) : typeof dataItem[title] == "boolean" ? (
                          <Chip
                            label={
                              booleanStatus?.[title]?.[dataItem[title as any]]
                            }
                            variant="outlined"
                            className={
                              dataItem[title] ? "chipEnabled" : "chipDisabled"
                            }
                          />
                        ) : title === "createdAt" ? (
                          new Date(dataItem[title]).toLocaleString()
                        ) : (
                          <span>
                            {dataItem[title]?.length > 35 ? (
                              <span>
                                {dataItem[title].slice(0, 35)}
                                <Tooltip title={dataItem[title]} arrow>
                                  <span className="readMore">
                                    {"....."}
                                    Read more
                                  </span>
                                </Tooltip>
                              </span>
                            ) : (
                              dataItem[title]
                            )}
                          </span>
                        )}
                      </TableCell>
                    ))}

                    <TableCell align="center" className="tableCell">
                      {deleteApiUrl ? (
                        <Tooltip title={`Delete ${entityName}`} arrow>
                          <IconButton
                            id={`delete-${entityName}-${dataItem.id}`}
                            className="deleteIcon"
                            onClick={(e) => {
                              e.stopPropagation();
                              setDeletedRecord(dataItem);
                            }}
                          >
                            <DeleteTwoToneIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      ) : null}
                      {extraAction ? (
                        <IconButton
                          id={"row"}
                          className="bellIcon"
                          onClick={() => newAction(dataItem)}
                        >
                          {extraAction}
                        </IconButton>
                      ) : null}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Grid className="paginationGrid">
              <PaginationComponent
                totalItems={totalItems}
                pagination={pagination}
                setPagination={setPagination}
              />
            </Grid>
          </div>
        </>
      ) : (
        <NoDataFound />
      )}
    </>
  );
};

export default DataTable;
