import { Grid } from "@mui/material";
import { FC, useContext } from "react";
import { useFormik } from "formik";
import { getValidationSchema } from "../../../../../shared/validations/schema";
import InputTextfield from "../../../../../shared/components/textField";
import { FormContainer } from "../../../../../shared/components/formContainer";
import { useParams } from "react-router-dom";
import { ZSAutocomplete } from "../../../../../shared/components/autoComplete";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import useLazyQuery from "../../../../../shared/components/hooks/useLazyQuery";

const Administrator: FC = () => {
  const { id } = useParams();
  const { payload, setPayload } = useContext(SidebarContext);

  const { data } = useLazyQuery(`/admin/administrators/${id}`, null);
  const { modifyData } = useMutation("/user-management/admin");

  const { data: rolesData } = useQuery("/admin/roles", {
    params: {
      ...payload,
      take: 10,
      sortOrder: "ASC",
      enabled: true,
    },
  });

  const selectedRole: any =
    data && (data as any)?.data?.user?.roleId
      ? {
          value: (data as any)?.data?.user?.roleId,
          label: (data as any)?.data?.user?.role?.name,
        }
      : null;

  const roles = (rolesData as any)?.items?.map((role: any) => ({
    value: role?.id,
    label: role?.name,
  }));

  const textFields = [
    {
      id: "admin-firstName",
      name: "firstName",
      label: "First Name",
      type: "text",
      placeholder: "Enter first name",
      required: true,
    },
    {
      id: "admin-lastName",
      name: "lastName",
      label: "Last Name",
      type: "text",
      placeholder: "Enter last name",
      required: true,
    },
    {
      id: "admin-email",
      name: "email",
      label: "Email Address",
      type: "text",
      placeholder: "Enter email address",
      required: true,
    },
    {
      id: "admin-password",
      name: "password",
      label: "Password",
      type: "password",
      placeholder: "Enter password",
      required: true,
    },
    {
      id: "admin-phone",
      name: "phone",
      label: "Phone",
      type: "text",
      placeholder: "Enter phone number",
      required: true,
    },
  ];

  const adminForm = useFormik({
    initialValues: {
      firstName: (data as any)?.data?.firstName ?? "",
      lastName: (data as any)?.data?.lastName ?? "",
      email: (data as any)?.data?.email ?? "",
      phone: (data as any)?.data?.phone ?? "",
      password: (data as any)?.data?.password ?? "",
      roleId: (data as any)?.data?.user?.roleId ?? "",
    },
    enableReinitialize: true,
    validateOnBlur: true,

    validationSchema: getValidationSchema([
      "firstName",
      "lastName",
      "email",
      "password",
      "phone",
      "roleId",
    ]),

    onSubmit: async (formData: any) => {
      if (id) {
        modifyData(
          `/admin/administrators/${id}`,
          "put",
          {
            ...formData,
          },
          adminForm
        );
      } else {
        modifyData(
          `/admin/administrators`,
          "post",
          {
            ...formData,
          },
          adminForm
        );
      }
    },
  });

  const titleBar = {
    title: "Create",
    buttonTitle: "Save",
    headerTitle: "User",
    onClick: () => adminForm.handleSubmit(),
    dirty: adminForm.dirty,
  };

  return (
    <>
      <FormContainer titleBar={titleBar}>
        <Grid container alignItems="stretch" columnSpacing={4} rowSpacing={2}>
          {textFields.map((field, index: number) => (
            <Grid item xs={4} key={field.id}>
              <InputTextfield form={adminForm} fieldProps={field} key={index} />
            </Grid>
          ))}
          {data && (
            <Grid item md={4} sm={6} xs={12}>
              <ZSAutocomplete
                label="Role"
                placeholder="Select role"
                defaultValue={selectedRole}
                options={roles}
                required
                setValue={(selectedOption: any) => {
                  adminForm.setFieldTouched("roleId", true);
                  if (selectedOption?.value) {
                    adminForm.setFieldValue("roleId", selectedOption?.value);
                  } else {
                    adminForm.setFieldValue("roleId", "");
                  }
                }}
                setBlur={() => {
                  adminForm.setFieldTouched("roleId", true);
                }}
                refetchFunction={(searchString: string) => {
                  setPayload((prevData: any) => {
                    return { ...prevData, search: searchString };
                  });
                }}
                errorMessage={
                  ((adminForm.touched as any)?.roleId &&
                    (adminForm.errors as any)?.roleId) ??
                  null
                }
              />
            </Grid>
          )}
        </Grid>
      </FormContainer>
    </>
  );
};
export default Administrator;
