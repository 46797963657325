/* eslint-disable react-hooks/exhaustive-deps */

import { FC, useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  List,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
// import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import { FormContainer } from "../../../../../shared/components/formContainer";
import TabPanel from "../../../../../shared/components/tabpanel/tabpanel";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import InputTextfield from "../../../../../shared/components/textField";
import { getValidationSchema } from "../../../../../shared/validations/schema";
import { useFormik } from "formik";
import { ZSSelectBox } from "../../../../../shared/components/selectBox";
import { Gender } from "../../../../../shared/enum";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import { EntityContext } from "../../../../../contexts/EntityContext";
import { Member } from "../../../../../shared/interfaces/member";
import { SidebarContext } from "../../../../../contexts/SidebarContext";

const MemberView: FC = () => {
  const { id } = useParams();
  const [editStatus, setEditStatus] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const { modifyData } = useMutation();
  const { data, fetchData } = useQuery(`/admin/members/${id}`, null);
  const { setEntityName } = useContext(EntityContext);
  const { payload } = useContext(SidebarContext);
  const editPermission = (data as any)?.entityPermissions
    ? !(data as any)?.entityPermissions?.includes("Member-Update")
    : false;

  useEffect(() => {
    setEntityName({
      entityName: ["Edit"],
      entityTitle: "Edit Member",
    });
  }, [setEntityName]);

  const memberData: Member = (data as any)?.data || {};
  const { data: relationData } = useQuery("/admin/relation-member", {
    params: {
      ...payload,
      memberID: memberData?.id,
    },
  });

  const memberRelations = (relationData as any)?.items;
  const closeMemberEdit = () => {
    fetchData();
    setEditStatus(false);
  };
  const memberForm = useFormik({
    initialValues: {
      fullNameEng: (data as any)?.data?.fullNameEng ?? "",
      fullNameMal: (data as any)?.data?.fullNameMal ?? "",
      memberCode: (data as any)?.data?.code ?? "",
      email: (data as any)?.data?.email ?? "",
      phone: (data as any)?.data?.phone ?? null,
      sndpMemberRegNo: (data as any)?.data?.sndpMemberRegNo ?? "",
      joinedSangamNo: (data as any)?.data?.joinedSangamNo ?? "",
      dob: (data as any)?.data?.dob ?? "",
      gender: (data as any)?.data?.gender ?? null,
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: getValidationSchema([
      "fullNameEng",
      "fullNameMal",
      "phone",
      "email",
      "gender",
    ]),
    validate: (values: any) => {
      const errors: any = {};
      if (memberForm.values?.phone && memberForm.values?.phone.length !== 10) {
        errors.phone = "Invalid Phone Number";
      }
      return errors;
    },
    onSubmit: async (formData: any, { resetForm }) => {
      if (!formData.phone) {
        formData.phone = null;
      }
      if (id) {
        modifyData(
          `/admin/members/${id}`,
          "put",
          {
            ...formData,
          },
          memberForm
        );
      } else {
        modifyData(
          `/admin/members`,
          "post",
          {
            ...formData,
          },
          memberForm
        );
      }
      fetchData();
      resetForm();
      setEditStatus(false);
    },
  });
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };
  const familyHead: Member | null =
    memberData?.familyMembers?.find((member: Member) => member.isFamilyHead) ||
    null;
  return (
    <FormContainer
      tabs={
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="Profile" />
          <Tab label="Education" />
          <Tab label="Occupation" />
          <Tab label="Vehicle" />
          <Tab label="Relationship" />
        </Tabs>
      }
    >
      <div className={""}>
        <div className="main-div">
          <Typography>
            {memberData.code}--{memberData.fullNameEng}
          </Typography>
          <TabPanel value={activeTab} index={0}>
            <List dense className="border-custom">
              <Grid container sx={{ mb: 1 }} spacing={2}>
                <Grid item md={11} xs={8}>
                  <span>
                    <b>Profile Details</b>
                  </span>
                </Grid>
                <Grid item md={1} xs={1} sx={{ justifyContent: "flex-end" }}>
                  {editStatus ? (
                    <Tooltip arrow title="Cancel">
                      <IconButton
                        className="TitleBarButton"
                        size="medium"
                        id={`member-edit`}
                        onClick={() => closeMemberEdit()}
                        aria-label={`member-edit`}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip arrow title="Edit">
                      <IconButton
                        className="TitleBarButton"
                        size="medium"
                        id={`member-edit`}
                        onClick={() => setEditStatus(!editStatus)}
                        aria-label={`member-edit`}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
              <Box
                sx={{ padding: "15px" }}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <TableContainer component={Paper}>
                  <Table size="small" sx={{ "& td": { border: 0 } }}>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <strong>
                            Full Name English
                            {!editStatus ? null : (
                              <Typography component="span" color="error">
                                *
                              </Typography>
                            )}
                            :
                          </strong>
                        </TableCell>
                        <TableCell>
                          {!editStatus ? (
                            <>{memberData.fullNameEng || "No Data"}</>
                          ) : (
                            <Grid sx={{ mt: 2 }}>
                              <InputTextfield
                                form={memberForm}
                                value={memberForm?.values?.fullNameEng || ""}
                                fieldProps={{
                                  id: "fullNameEng",
                                  name: "fullNameEng",
                                  type: "text",
                                  required: true,
                                }}
                              />
                            </Grid>
                          )}
                        </TableCell>

                        <TableCell>
                          <strong>
                            Full Name Malayalam
                            {!editStatus ? null : (
                              <Typography component="span" color="error">
                                *
                              </Typography>
                            )}
                            :
                          </strong>
                        </TableCell>
                        <TableCell>
                          {!editStatus ? (
                            <>{memberData.fullNameMal || "No Data"}</>
                          ) : (
                            <Grid sx={{ mt: 2 }}>
                              <InputTextfield
                                form={memberForm}
                                value={memberForm?.values?.fullNameMal || ""}
                                fieldProps={{
                                  id: "fullNameMal",
                                  name: "fullNameMal",
                                  type: "text",
                                  required: true,
                                }}
                              />
                            </Grid>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Member Code:</strong>
                        </TableCell>
                        <TableCell>{memberData.code || "No Data"}</TableCell>
                        <TableCell>
                          <strong>
                            DOB
                            {!editStatus ? null : (
                              <Typography component="span" color="error">
                                *
                              </Typography>
                            )}
                            :
                          </strong>
                        </TableCell>
                        <TableCell>{memberData.dob || "No Data"}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>
                            Gender
                            {!editStatus ? null : (
                              <Typography component="span" color="error">
                                *
                              </Typography>
                            )}
                            :
                          </strong>
                        </TableCell>
                        <TableCell>
                          {!editStatus ? (
                            <>{memberData.gender || "No Data"}</>
                          ) : (
                            <Grid sx={{ mt: 2 }}>
                              <ZSSelectBox
                                fieldProps={{
                                  id: "member-gender",
                                  name: "gender",
                                  placeholder: "Select an option",
                                }}
                                options={[
                                  { label: Gender.Male, value: Gender.Male },
                                  {
                                    label: Gender.Female,
                                    value: Gender.Female,
                                  },
                                  { label: Gender.Other, value: Gender.Other },
                                ]}
                                form={memberForm}
                                value={memberForm.values?.gender}
                              />
                            </Grid>
                          )}
                        </TableCell>
                        <TableCell>
                          <strong>Email:</strong>
                        </TableCell>
                        <TableCell>
                          {!editStatus ? (
                            <>{memberData.email || "No Data"}</>
                          ) : (
                            <Grid sx={{ mt: 2 }}>
                              <InputTextfield
                                form={memberForm}
                                value={memberForm?.values?.email || ""}
                                fieldProps={{
                                  id: "email",
                                  name: "email",
                                  type: "email",
                                  required: true,
                                }}
                              />
                            </Grid>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>
                            Phone
                            {!editStatus ? null : (
                              <Typography component="span" color="error">
                                *
                              </Typography>
                            )}
                            :
                          </strong>
                        </TableCell>
                        <TableCell>
                          {!editStatus ? (
                            <>{memberData.phone || "No Data"}</>
                          ) : (
                            <Grid sx={{ mt: 2 }}>
                              <InputTextfield
                                form={memberForm}
                                value={memberForm?.values?.phone || ""}
                                fieldProps={{
                                  id: "phone",
                                  name: "phone",
                                  type: "text",
                                  required: true,
                                }}
                              />
                            </Grid>
                          )}
                        </TableCell>
                        <TableCell>
                          <strong>SNDP Member Reg No:</strong>
                        </TableCell>
                        <TableCell>
                          {!editStatus ? (
                            <>{memberData.sndpMemberRegNo || "No Data"}</>
                          ) : (
                            <Grid sx={{ mt: 2 }}>
                              <InputTextfield
                                form={memberForm}
                                value={
                                  memberForm?.values?.sndpMemberRegNo || ""
                                }
                                fieldProps={{
                                  id: "sndpMemberRegNo",
                                  name: "sndpMemberRegNo",
                                  type: "text",
                                  required: true,
                                }}
                              />
                            </Grid>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Status:</strong>
                        </TableCell>
                        <TableCell>{memberData.status || "No Data"}</TableCell>
                        <TableCell>
                          <strong>Joined Sangam No:</strong>
                        </TableCell>
                        <TableCell>
                          {!editStatus ? (
                            <>{memberData.joinedSangamNo || "No Data"}</>
                          ) : (
                            <Grid sx={{ mt: 2 }}>
                              <InputTextfield
                                form={memberForm}
                                value={memberForm?.values?.joinedSangamNo || ""}
                                fieldProps={{
                                  id: "joinedSangamNo",
                                  name: "joinedSangamNo",
                                  type: "joinedSangamNo",
                                  required: true,
                                }}
                              />
                            </Grid>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Union Membership Status:</strong>
                        </TableCell>
                        <TableCell>
                          {memberData.unionMembershipStatus || "No Data"}
                        </TableCell>
                      </TableRow>
                      <TableRow></TableRow>
                      {editStatus ? (
                        <TableRow>
                          <TableCell colSpan={6} align="center">
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                memberForm.handleSubmit();
                              }}
                              disabled={editPermission}
                            >
                              Save
                            </Button>
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </List>

            <List dense className="border-custom">
              <Grid container sx={{ mb: 1 }} spacing={2}>
                <Grid item xs={8}>
                  <span>
                    <b>Family Details</b>
                  </span>
                </Grid>
              </Grid>
              <Box
                sx={{ padding: "25px" }}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <TableContainer component={Paper}>
                  <Table size="small" sx={{ "& td": { border: 0 } }}>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <strong>Family code:</strong>
                        </TableCell>
                        <TableCell>
                          {memberData?.family?.familyCode || "No Data"}
                        </TableCell>
                        <TableCell>
                          <strong>Head:</strong>
                        </TableCell>
                        <TableCell>
                          {familyHead?.fullNameEng || "No Data"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Ration card no:</strong>
                        </TableCell>
                        <TableCell>
                          {memberData?.family?.rationCardNumber || "No Data"}
                        </TableCell>
                        <TableCell>
                          <strong>Ration Card Type:</strong>
                        </TableCell>
                        <TableCell>
                          {memberData?.family?.rationCardType || "No Data"}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <strong>Annual Income:</strong>
                        </TableCell>
                        <TableCell>
                          {memberData?.family?.annualIncome || "No Data"}
                        </TableCell>
                        <TableCell>
                          <strong>Joined Date:</strong>
                        </TableCell>
                        <TableCell>
                          {memberData?.family?.joinedDate || "No Data"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Kudumba Unit:</strong>
                        </TableCell>
                        <TableCell>
                          {memberData?.family?.kudumbaUnitNameEng || "No Data"}
                        </TableCell>
                        <TableCell>
                          <strong>Micro Finance:</strong>
                        </TableCell>
                        <TableCell>
                          {memberData?.family?.microFinanceNameEng || "No Data"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </List>
            <List dense className="border-custom">
              <Grid container sx={{ mb: 1 }} spacing={2}>
                <Grid item xs={8}>
                  <span>
                    <b>Current Address</b>
                  </span>
                </Grid>
              </Grid>
              <Box
                sx={{ padding: "25px" }}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <TableContainer component={Paper}>
                  <Table size="small" sx={{ "& td": { border: 0 } }}>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <strong>House No:</strong>
                        </TableCell>
                        <TableCell>
                          {memberData?.family?.familyAddresses[0]?.houseNo ||
                            "No Data"}
                        </TableCell>
                        <TableCell>
                          <strong>House Name Eng:</strong>
                        </TableCell>
                        <TableCell>
                          {memberData?.family?.familyAddresses[0]
                            ?.houseNameEng || "No Data"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>House Name Mal:</strong>
                        </TableCell>
                        <TableCell>
                          {memberData?.family?.familyAddresses[0]
                            ?.houseNameMal || "No Data"}
                        </TableCell>
                        <TableCell>
                          <strong>Street Name Eng:</strong>
                        </TableCell>
                        <TableCell>
                          {memberData?.family?.familyAddresses[0]
                            ?.streetNameEng || "No Data"}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <strong>Street Name Mal</strong>
                        </TableCell>
                        <TableCell>
                          {memberData?.family?.familyAddresses[0]
                            ?.streetNameMal || "No Data"}
                        </TableCell>
                        <TableCell>
                          <strong>Date of Residence:</strong>
                        </TableCell>
                        <TableCell>
                          {memberData?.family?.familyAddresses[0]
                            ?.dateOfResidence || "No Data"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Residence Type:</strong>
                        </TableCell>
                        <TableCell>
                          {memberData?.family?.familyAddresses[0]
                            ?.residenceType || "No Data"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              {/* {memberData?.family?.familyAddresses?.currentAddress === false ? (
                <>
                  <Grid container sx={{ mb: 1, mt: 2 }} spacing={2}>
                    <Grid item md={11} xs={8}>
                      <span>
                        <b>Address History</b>
                      </span>
                    </Grid>
                  </Grid>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <TableContainer component={Paper}>
                      <Table size="small">
                        <TableHead>
                          <TableCell>
                            <strong>House No</strong>
                          </TableCell>
                          <TableCell>
                            <strong>House Name</strong>
                          </TableCell>
                          <TableCell>
                            <strong>Street Name</strong>
                          </TableCell>
                          <TableCell>
                            <strong>Date Of Residence</strong>
                          </TableCell>
                          <TableCell>
                            <strong>Ward NO</strong>
                          </TableCell>
                        </TableHead>
                        <TableBody>
                          {memberData?.family?.addressHistory?.map(
                            (address: any, index: any) => (
                              <>
                                <TableRow>
                                  <TableCell> {address.houseNo} </TableCell>
                                  <TableCell>{address.houseNameEng}</TableCell>
                                  <TableCell>
                                    {address?.streetNameEng}
                                  </TableCell>
                                  <TableCell>
                                    {address.dateOfResidence}
                                  </TableCell>
                                  <TableCell>
                                    {`${address?.lsgds?.districtEng}, ${address?.lsgds?.talukEng}, ${address?.lsgds?.panchayathEng}, Ward No - ${address?.lsgds?.wardNo}`}
                                  </TableCell>
                                </TableRow>
                              </>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </>
              ) : null} */}
            </List>
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            <List dense className="border-custom">
              <Grid container sx={{ mb: 1 }} spacing={2}>
                <Grid item xs={8}>
                  <span>
                    <b>Education Details</b>
                  </span>
                </Grid>
              </Grid>

              <Box
                sx={{ padding: "25px" }}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                {memberData?.courses && memberData.courses.length > 0 ? (
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Course Name</TableCell>
                          <TableCell>Specialization</TableCell>
                          {/* <TableCell>Action</TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {memberData?.courses?.map((data: any) => (
                          <TableRow>
                            <TableCell>
                              {data.courseName ? data?.courseName : "No Data"}
                            </TableCell>
                            <TableCell>
                              {data.specialization
                                ? data?.specialization
                                : "No Data"}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Typography variant="body1" color="textSecondary">
                    Education details not updated
                  </Typography>
                )}
              </Box>
            </List>
          </TabPanel>
          <TabPanel value={activeTab} index={2}>
            <List dense className="border-custom">
              <Grid container sx={{ mb: 1 }} spacing={2}>
                <Grid item xs={8}>
                  <span>
                    <b>Occupation Details</b>
                  </span>
                </Grid>
              </Grid>
              <Box
                sx={{ padding: "25px" }}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                {memberData?.occupations &&
                memberData.occupations.length > 0 ? (
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Sector</TableCell>
                          <TableCell>Company Name</TableCell>
                          <TableCell>Job Title</TableCell>
                          <TableCell>Start Date</TableCell>
                          <TableCell>End Date</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {memberData?.occupations?.map((data: any) => (
                          <TableRow>
                            <TableCell>
                              {data.sector ? data?.sector : "No Data"}
                            </TableCell>
                            <TableCell>
                              {data.companyName ? data?.companyName : "No Data"}
                            </TableCell>

                            <TableCell>
                              {data.jobTitle ? data?.jobTitle : "No Data"}
                            </TableCell>
                            <TableCell>
                              {data.startDate ? data?.startDate : "No Data"}
                            </TableCell>
                            <TableCell>
                              {data.endDate ? data?.endDate : "No Data"}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Typography variant="body1" color="textSecondary">
                    Occupation details not updated
                  </Typography>
                )}
              </Box>
            </List>
          </TabPanel>
          <TabPanel value={activeTab} index={3}>
            <List dense className="border-custom">
              <Grid container sx={{ mb: 1 }} spacing={2}>
                <Grid item xs={8}>
                  <span>
                    <b>Vehicle Details</b>
                  </span>
                </Grid>
              </Grid>
              <Box
                sx={{ padding: "25px" }}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                {memberData?.vehicles && memberData?.vehicles.length > 0 ? (
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Registration Number</TableCell>
                          <TableCell>Vehicle Type</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {memberData?.vehicles?.map((data: any) => (
                          <TableRow>
                            <TableCell>
                              {data.regNumber ? data?.regNumber : "No Data"}
                            </TableCell>
                            <TableCell>
                              {data.vehicleType ? data?.vehicleType : "No Data"}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Typography variant="body1" color="textSecondary">
                    Vehicle details not updated
                  </Typography>
                )}
              </Box>
            </List>
          </TabPanel>
          <TabPanel value={activeTab} index={4}>
            <List dense className="border-custom">
              <Grid container sx={{ mb: 1 }} spacing={2}>
                <Grid item xs={8}>
                  <span>
                    <b>Relationship Details</b>
                  </span>
                </Grid>
              </Grid>
              <Box
                sx={{ padding: "25px" }}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                {memberRelations && memberRelations.length > 0 ? (
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Member Code</TableCell>
                          <TableCell>Member Name</TableCell>
                          <TableCell>Relationship</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {memberRelations?.map((data: any) => (
                          <TableRow>
                            <TableCell>
                              {data.relationWithmember?.code
                                ? data?.relationWithmember?.code
                                : "No Data"}
                            </TableCell>
                            <TableCell>
                              {data.relationWithmember?.fullNameEng
                                ? data.relationWithmember?.fullNameEng
                                : "No Data"}
                            </TableCell>
                            <TableCell>
                              {data.relationship
                                ? data?.relationship
                                : "No Data"}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Typography variant="body1" color="textSecondary">
                    Relathionship details not updated
                  </Typography>
                )}
              </Box>
            </List>
          </TabPanel>
        </div>
      </div>
    </FormContainer>
  );
};
export default MemberView;
