import { LoadingButton } from "@mui/lab";
import { Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FC, useState } from "react";

interface ConfirmDialogProps {
  openConfirmDialog: boolean;
  setOpenConfirmDialog: Function;
  handleSubmit: Function;
  title?: string;
  message?: any;
  loading?: any;
}
const ConfirmDialog: FC<ConfirmDialogProps> = ({
  openConfirmDialog,
  setOpenConfirmDialog,
  handleSubmit,
  message,
  title,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div>
      <Dialog
        open={openConfirmDialog}
        onClose={() => {
          setOpenConfirmDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography color="black" fontWeight="bold">
            {title}
          </Typography> 
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton variant="outlined" onClick={() => handleSubmit(false)}>
            Cancel
          </LoadingButton>
          <LoadingButton
            id="confirmation"
            variant="contained"
            onClick={() => {
              handleSubmit(true);
              setLoading(true);
            }}
            loading={loading}
            autoFocus
          >
            OK
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ConfirmDialog;
