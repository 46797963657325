import "./styles.css";
import { useContext } from "react";
import { Box, IconButton, Tooltip, Container, Grid } from "@mui/material";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
// import HeaderUserBox from "./Userbox";
import { SidebarContext } from "../../../contexts/SidebarContext";
import ZsBreadcrumbs from "../../../shared/components/breadcrumbs";

// import { EntityContext } from "../../../contexts/EntityContext";

function Header() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  // const { entity } = useContext(EntityContext);

  return (
    <Container className="headerContainer">
      <Grid container className="subContainer">
        <Grid item xs={2.5} className="userContainer">
          {/* <HeaderUserBox /> */}
          <Box
            component="span"
            sx={{
              display: { lg: "none", xs: "inline-block" },
              margin: 0.3,
            }}
          >
            <Tooltip arrow title="Toggle Menu">
              <IconButton color="primary" onClick={toggleSidebar}>
                {!sidebarToggle ? (
                  <MenuTwoToneIcon fontSize="small" />
                ) : (
                  <CloseTwoToneIcon fontSize="small" />
                )}
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
      <ZsBreadcrumbs />
    </Container>
  );
}

export default Header;
