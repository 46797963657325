import WelfareTypes from "../components/welfareTypes";
import WelfareTypeList from "../components/welfareTypeList";
import DiamondIcon from "@mui/icons-material/Diamond";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import { Navigate } from "react-router-dom";
const welfareTypeRoute = {
  icon: DiamondIcon,
  name: "Welfare Schemes",
  link: "/welfareSchemes",
  path: "welfareSchemes",
  element: "",
  index: 6,
  children: [
    {
      path: "welfareType",
      element: <WelfareTypeList />,
      name: "Welfare Type",
      index: 0,
      link: "/welfareSchemes/welfareType",
      icon: SelfImprovementIcon,
    },
    {
      path: "welfareType/create",
      element: <WelfareTypes />,
    },
    {
      path: "welfareType/:id",
      element: <WelfareTypes />,
    },

    {
      path: "",
      element: <Navigate to="welfareType" replace />,
    },
  ],
};
export default welfareTypeRoute;
