import "./styles.css";
import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { InputLabel } from "@mui/material";

type QuillEditorProps = {
  value: string;
  onChange: Function;
  height?: string;
  direction?: "ltr" | "rtl";
};

const QuillEditor: React.FC<QuillEditorProps> = ({
  value,
  onChange,
  height = "120px",
  direction = "ltr",
}) => {
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", { italic: { tooltip: "Italic (Ctrl+I)" } }, "underline"],
      ["blockquote", "code-block"],
      [{ background: [] }, { color: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],
    ],
  };

  return (
    <div className="quillContainer">
      <InputLabel>Description</InputLabel>
      <ReactQuill
        className={`my-custom-quill-editor ${direction === "rtl" ? "rtl" : ""}`}
        style={{ height }}
        value={value ? value : "<p><br></p>"}
        onChange={(newValue, delta, source, editor) => {
          // console.log(delta, editor.getText());
          // console.log("content", editor.getContents());
          // editor.setText()
          onChange(newValue, editor.getText());
        }}
        modules={modules}
      />
    </div>
  );
};

export default QuillEditor;
