/* eslint-disable react-hooks/exhaustive-deps */
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FC, useContext } from "react";
import TitleBar from "../../../../../shared/components/titleBar";
import { EntityContext } from "../../../../../contexts/EntityContext";
import DataTable from "../../../../../shared/components/dataTable";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import NoPermissionContent from "../../../../../shared/components/noPermissionBanner";
import Loader from "../../../../../shared/components/loader/linearProgress";
import SearchBox from "../../../../../layouts/SidebarLayout/Header/SearchBox";

const CourseList: FC = () => {
  const navigate = useNavigate();
  const { payload, setPayload } = useContext(SidebarContext);

  const { loading, data, fetchData, error } = useQuery("/admin/course", {
    params: {
      ...payload,
    },
  });
  const { setEntityName } = useContext(EntityContext);

  const navigateToDetails = () => {
    setEntityName({
      entityName: ["Create"],
      entityTitle: "Course",
    });
    navigate("/settings/course/create");
  };

  const course = (data as any)?.items?.map((course: any) => {
    return {
      ...course,
      level: course?.level,
      course: course?.courseName,
      specialization: course?.specialization,
    };
  });

  const courseDisplayColumns = ["level", "course", "specialization"];

  return (
    <Container className="tableListContainer">
      <TitleBar
        title="Course"
        buttonTitle="Create"
        onClick={navigateToDetails}
        headerTitle="Course"
        disabled={
          (data as any)?.entityPermissions
            ? !(data as any)?.entityPermissions?.includes("Course-Create")
            : false
        }
      >
        <SearchBox />
      </TitleBar>
      {loading ? (
        <Loader />
      ) : (error as any)?.statusCode === 403 ? (
        <NoPermissionContent />
      ) : (
        <DataTable
          entityName="Course"
          data={course}
          displayColumns={courseDisplayColumns}
          linkColumn="name"
          linkUrl="/settings/course"
          pagination={{ take: payload.take, skip: payload.skip }}
          setPagination={setPayload}
          totalItems={(data as any)?.totalItems}
          deleteApiUrl="/admin/course"
          refetchFunction={fetchData}
        />
      )}
    </Container>
  );
};

export default CourseList;
