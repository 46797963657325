import "./styles.css";
import { Grid, Link, Box } from "@mui/material";
import { useFormik } from "formik";
import { setLocalStorageData } from "../../../../shared/utils/localStorage";
import { LoadingButton } from "@mui/lab";
import Logo from "../../../../assets/img/logo-connect.svg";
import InputTextfield from "../../../../shared/components/textField";
import { getValidationSchema } from "../../../../shared/validations/schema";
import { useContext, useState } from "react";
import { SnackBarAlertContext } from "../../../../contexts/SnackbarContext";
import { useNavigate } from "react-router-dom";
const Login = (props: any) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const snackBarAlertContext = useContext(SnackBarAlertContext);
  const textFields = [
    {
      id: "login-email",
      name: "email",
      placeholder: "Enter your email address / phone number",
      type: "text",
      required: true,
    },
    {
      id: "login-password",
      name: "password",
      placeholder: "Enter your password",
      type: "password",
      required: true,
    },
  ];
  const loginForm = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: getValidationSchema(["email", "password"]),
    onSubmit: async (loginData: any) => {
      setLoading(true);
      const response: any = await fetch(
        process.env.REACT_APP_API_URL + "admin/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(loginData),
        }
      );
      if (response?.ok) {
        setLoading(false);
        setLocalStorageData("token", response.headers.get("token"));
        navigate("/dashboard");
      } else {
        setLoading(false);
        const error = await response.json();
        snackBarAlertContext.set({
          status: true,
          type: "error",
          message: error?.message,
        });
      }
    },
  });
  return (
    <div className="login-page-card">
      <Grid container spacing={1} className="login-row">
        <Grid item md={4}></Grid>
        <Grid item md={4} className="">
          <img src={Logo} className="login-logo" alt="" />
          <Box className="fomrCard">
            <form
              onSubmit={loginForm.handleSubmit}
              autoComplete="off"
              className="loginForm"
            >
              {textFields.map((field: any, index: number) => {
                return (
                  <InputTextfield
                    className="mediumInputBox"
                    form={loginForm}
                    fieldProps={field}
                    key={index}
                  />
                );
              })}
              <Grid container spacing={1}>
                <Grid item md={4}>
                  <LoadingButton
                    className="signInButton"
                    loading={loading}
                    variant="contained"
                    type="submit"
                    fullWidth
                    size="small"
                  >
                    Login
                  </LoadingButton>
                </Grid>
                <Grid item md={8} mt={4} sx={{ textAlign: "right" }}>
                  <Link
                    href="/forgot-password"
                    className="forgot-password-link"
                  >
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
export default Login;
