import { Navigate } from "react-router-dom";
import WelfareRequest from "../components/welfareRequest";
import DiamondIcon from "@mui/icons-material/Diamond";
const requestRoute = {
  icon: DiamondIcon,
  name: "Welfare Schemes",
  link: "/welfareSchemes",
  path: "welfareSchemes",
  element: "",
  index: 6,
  children: [
    // {
    //   path: "welfareRequest",
    //   element: <WelfareRequestList />,
    //   name: "Welfare Request",
    //   index: 2,
    //   link: "/welfareSchemes/welfareRequest",
    //   icon: RequestPageIcon,
    // },

    {
      path: "welfareRequest/:id",
      element: <WelfareRequest />,
    },
    {
      path: "",
      element: <Navigate to="welfareType" replace />,
    },
  ],
};
export default requestRoute;
