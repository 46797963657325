import {
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { FC, useContext, useState } from "react";
import DataTable from "../../../../../shared/components/dataTable";
import TitleBar from "../../../../../shared/components/titleBar";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import Loader from "../../../../../shared/components/loader/linearProgress";
import NoPermissionContent from "../../../../../shared/components/noPermissionBanner";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import ConfirmDialog from "../../../../../shared/components/confirmDialogModal";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import SaveIcon from "@mui/icons-material/Save";
import NotifyIcon from "@mui/icons-material/Notifications";
import SearchBox from "../../../../../layouts/SidebarLayout/Header/SearchBox";
import { yogamMembershipRequest } from "../../../../../shared/interfaces/yogam-membership-request";

const UnionApprovalList: FC = () => {
  const { payload, setPayload } = useContext(SidebarContext);
  const [openNotifyModal, setOpenNotifyModal] = useState<boolean>(false);
  const [selectedMemberRequest, setSelectedMemberRequest] = useState<any>(null);
  const [selectedIds, setSelectedIds] = useState<any[]>([]);
  const [message, setMessage] = useState("");
  const { modifyData } = useMutation("/yogamMemberships/unionApproval");
  const { loading, data, fetchData, error } = useQuery(
    "/admin/yogam-membership-request",
    {
      params: {
        ...payload,
      },
    }
  );

  const [formData, setFormData] = useState<any>({});

  const renderCell = (row: any, col: string) => {
    if (col === "status") {
      return (
        <FormControl fullWidth>
          <InputLabel>Select Status</InputLabel>
          <Select
            value={formData[row.id]?.unionStatus || row.unionStatus}
            onChange={(e) => {
              const updatedStatus = e.target.value;
              setFormData((prev: any) => ({
                ...prev,
                [row.id]: {
                  ...prev[row.id],
                  unionStatus: updatedStatus,
                },
              }));

              setSelectedIds((prevSelected) => {
                if (!prevSelected.some((item) => item.id === row.id)) {
                  return [...prevSelected, row];
                }
                return prevSelected;
              });
            }}
            label="Select Status"
          >
            <MenuItem key={0} value="Pending">
              Pending
            </MenuItem>
            <MenuItem key={1} value="Approved">
              Approved
            </MenuItem>
            <MenuItem key={2} value="Submitted">
              Submitted
            </MenuItem>
            <MenuItem key={3} value="Rejected">
              Rejected
            </MenuItem>
          </Select>
        </FormControl>
      );
    }
    if (
      col === "membershipNumber" &&
      formData[row.id]?.unionStatus === "Approved"
    ) {
      return (
        <TextField
          value={formData[row.id]?.sndpMemberRegNo || ""}
          onChange={(e) => {
            const updatedRegNo = e.target.value;
            setFormData((prev: any) => ({
              ...prev,
              [row.id]: {
                ...prev[row.id],
                sndpMemberRegNo: updatedRegNo,
              },
            }));
          }}
          label="SNDP Member Registration No."
          fullWidth
        />
      );
    }

    if (col === "remarks" && formData[row.id]?.unionStatus === "Rejected") {
      return (
        <TextField
          value={formData[row.id]?.remarks || ""}
          onChange={(e) => {
            const updatedRemarks = e.target.value;
            setFormData((prev: any) => ({
              ...prev,
              [row.id]: {
                ...prev[row.id],
                remarks: updatedRemarks,
              },
            }));
          }}
          label="Remarks"
          fullWidth
        />
      );
    }
    if (col === "actions") {
      return (
        <>
          <IconButton
            onClick={() => handleNotifyModalOpen(row)}
            color="secondary"
            aria-label="send notification"
          >
            <NotificationsActiveIcon fontSize="small" />
          </IconButton>
        </>
      );
    }
    return row[col];
  };

  const handleSubmit = async () => {
    try {
      const updates = selectedIds.map((item) => ({
        id: item.id,
        memberID: item.memberID,
        unionStatus: formData[item.id]?.unionStatus,
        sndpMemberRegNo:
          formData[item.id]?.unionStatus === "Approved"
            ? formData[item.id]?.sndpMemberRegNo
            : null,
        remarks:
          formData[item.id]?.unionStatus === "Rejected"
            ? formData[item.id]?.remarks
            : null,
      }));

      if (updates.length > 0) {
        await modifyData(
          "/admin/yogam-membership-request/union-update-status",
          "put",
          updates
        );
      }
      fetchData();
    } catch (error) {
      console.error("Failed to update union membership requests:", error);
    }
  };

  const membershipRequestDisplayColumns = [
    "memberCode",
    "fullName",
    "houseNameEng",
    "familyHead",
    "status",
    "membershipNumber",
    "remarks",
    "actions",
  ];
  let membershipList: yogamMembershipRequest[] = [];
  if (data && (data as any)?.items) {
    membershipList = (data as any)?.items.map((item: any) => {
      return {
        id: item.id,
        memberID: item?.memberID,
        memberCode: item?.member?.code,
        fullName: item?.member?.fullNameEng,
        membershipNumber: item?.member?.sndpMemberRegNo,
        houseNameEng: item?.member?.family?.familyAddresses[0]?.houseNameEng,
        familyHead: item?.member?.family?.familyMembers[0]?.fullNameEng,
        unionStatus: item?.unionStatus,
        remarks: item?.remarks,
      };
    });
  }

  const handleNotifyModalOpen = (member: any) => {
    if (typeof member === "object" && !Array.isArray(member)) {
      setSelectedMemberRequest([member]);
      setMessage(
        ` ${member?.fullName} is eligible to apply for Yogam Membership are selected to sent notifcation.Please confirm to proceed.`
      );
    } else if (Array.isArray(member)) {
      setSelectedMemberRequest(member);
      setMessage(
        `${member?.length} Members eligible to apply for Yogam Membership are selected to sent notifcation.Please confirm to proceed.`
      );
    }

    setOpenNotifyModal(true);
  };

  const handleClose = () => {
    setOpenNotifyModal(false);
  };

  const handleNotificationSubmit = () => {
    modifyData(`/notification/send`, "post", {
      memberIds: selectedMemberRequest,
      message: message,
    });
    console.log("Notification sent to member.");
    setOpenNotifyModal(false);
  };

  return (
    <Container className="tableListContainer">
      <TitleBar title="Union Approval" headerTitle="Union Approval">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SearchBox />
          </Grid>
          <Grid item xs={2}>
            <IconButton color="secondary" onClick={handleSubmit}>
              <SaveIcon />
            </IconButton>
          </Grid>

          <Grid item xs={2}>
            <IconButton
              color="secondary"
              onClick={() => handleNotifyModalOpen(selectedMemberRequest)}
            >
              <NotifyIcon />
            </IconButton>
          </Grid>
        </Grid>
      </TitleBar>
      {loading ? (
        <Loader />
      ) : (error as any)?.statusCode === 403 ? (
        <NoPermissionContent />
      ) : (
        <>
          <DataTable
            entityName="Union Approval"
            data={membershipList}
            displayColumns={membershipRequestDisplayColumns}
            linkColumn="fullNameEng"
            linkUrl=""
            pagination={{ take: payload.take, skip: payload.skip }}
            setPagination={setPayload}
            totalItems={(data as any)?.totalItems}
            showCheckboxes={true}
            refetchFunction={fetchData}
            onUpdateStatus={setSelectedIds}
            newAction={handleNotifyModalOpen}
            renderCell={renderCell}
          />
          <>
            {openNotifyModal === true ? (
              <ConfirmDialog
                title={"Notify Member"}
                openConfirmDialog={openNotifyModal}
                setOpenConfirmDialog={handleClose}
                message={message}
                handleSubmit={handleNotificationSubmit}
              />
            ) : null}
          </>
        </>
      )}
    </Container>
  );
};

export default UnionApprovalList;
