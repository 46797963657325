import NoDataImage from "./images/noDataFount.svg";
import "./styles.css";
function NoDataFound() {
  return (
    <div className="scrollBar">
      <img src={NoDataImage} alt="NoDataImage" className="noData" />
    </div>
  );
}

export default NoDataFound;
