import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { Checkbox, Grid, InputLabel, List, Typography } from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import InputTextfield from "../../../../../shared/components/textField";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import { getValidationSchema } from "../../../../../shared/validations/schema";
import useLazyQuery from "../../../../../shared/components/hooks/useLazyQuery";
import { FormContainer } from "../../../../../shared/components/formContainer";
import { ZSAutocomplete } from "../../../../../shared/components/autoComplete";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import axios from "axios";
import FileInput from "../../../../../shared/components/fileInput";
const Event: FC = () => {
  const { id } = useParams();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [options, setOptions] = useState([]);
  const { payload, setPayload } = useContext(SidebarContext);
  const { loading: apiLoading, modifyData } = useMutation(
    "/eventManagement/events"
  );
  const { data } = useLazyQuery(`/admin/event/${id}`);
  const { data: eventData } = useQuery("/admin/event-type", {
    params: {
      ...payload,
      take: 10,
      sortOrder: "ASC",
    },
  });

  const eventTypes = (eventData as any)?.items?.map((eventType: any) => ({
    value: eventType?.id,
    label: `${eventType?.eventTypeName}  `,
  }));

  const selectedeventType: any =
    data && (data as any)?.data?.eventType
      ? {
          value: (data as any)?.data?.eventTypeId,
          label: (data as any)?.data?.eventType?.eventTypeName,
        }
      : null;

  const handleStartDateChange = (newValue: string) => {
    let value = newValue.replace(/\D/g, "");
    let dayPart = value.slice(0, 2);
    let monthPart = value.slice(2, 4);
    let yearPartPart = value.slice(4, 8);

    if (dayPart === "00") dayPart = "01";
    if (monthPart === "00") monthPart = "01";

    if (parseInt(dayPart, 10) > 31) dayPart = "31";
    if (parseInt(monthPart, 10) > 12) monthPart = "12";

    if (monthPart === "02" && parseInt(dayPart, 10) > 29) dayPart = "29";
    else if (
      ["04", "06", "09", "11"].includes(monthPart) &&
      parseInt(dayPart, 10) > 30
    )
      dayPart = "30";
    value = dayPart + (value.length >= 2 ? "/" + monthPart : "");
    value = value + (value.length >= 4 ? "/" + yearPartPart : "");

    if (
      newValue?.length === 2 &&
      eventForm?.values?.startDate.charAt(
        eventForm?.values?.startDate?.length - 1
      ) === "/"
    ) {
      value = dayPart;
    }
    eventForm.setFieldValue("startDate", value);
  };

  const handleEndDateChange = (newValue: string) => {
    let value = newValue.replace(/\D/g, "");
    let dayPart = value.slice(0, 2);
    let monthPart = value.slice(2, 4);
    let yearPartPart = value.slice(4, 8);

    if (dayPart === "00") dayPart = "01";
    if (monthPart === "00") monthPart = "01";

    if (parseInt(dayPart, 10) > 31) dayPart = "31";
    if (parseInt(monthPart, 10) > 12) monthPart = "12";

    if (monthPart === "02" && parseInt(dayPart, 10) > 29) dayPart = "29";
    else if (
      ["04", "06", "09", "11"].includes(monthPart) &&
      parseInt(dayPart, 10) > 30
    )
      dayPart = "30";
    value = dayPart + (value.length >= 2 ? "/" + monthPart : "");
    value = value + (value.length >= 4 ? "/" + yearPartPart : "");

    if (
      newValue?.length === 2 &&
      eventForm?.values?.endDate.charAt(
        eventForm?.values?.endDate?.length - 1
      ) === "/"
    ) {
      value = dayPart;
    }

    eventForm.setFieldValue("endDate", value);

    if (eventForm.values.startDate) {
      const [startDay, startMonth, startYear] =
        eventForm.values.startDate.split("/");
      const [endDay, endMonth, endYear] = value.split("/");

      const startDate = new Date(`${startYear}-${startMonth}-${startDay}`);
      const endDate = new Date(`${endYear}-${endMonth}-${endDay}`);

      if (startDate > endDate) {
        eventForm.setFieldError(
          "endDate",
          "End date must be after or equal to start date"
        );
      } else {
        eventForm.setFieldError("endDate", "");
      }
    }
  };

  const eventForm = useFormik({
    initialValues: {
      eventTypeId: Number((data as any)?.data?.eventTypeId) ?? "",
      name: (data as any)?.data?.name ?? "",
      startDate: (data as any)?.data?.startDate ?? "",
      endDate: (data as any)?.data?.endDate ?? "",
      location: (data as any)?.data?.location ?? null,
      description: (data as any)?.data?.description ?? null,
      enabled: (data as any)?.data?.enabled ?? true,
      image: (data as any)?.data?.image ?? null,
    },
    enableReinitialize: true,
    validateOnBlur: true,

    validationSchema: getValidationSchema([
      "eventTypeId",
      "name",
      "startDate",
      "endDate",
      "location",
    ]),

    onSubmit: async (formData: any) => {
      const errors: any = {};
      if (!formData.startDate) {
        formData.startDate = null;
      }
      if (!formData.endDate) {
        formData.endDate = null;
      }
      if (formData.startDate && formData.endDate) {
        const [startDay, startMonth, startYear] = formData.startDate.split("/");
        const [endDay, endMonth, endYear] = formData.endDate.split("/");

        const startDate = new Date(`${startYear}-${startMonth}-${startDay}`);
        const endDate = new Date(`${endYear}-${endMonth}-${endDay}`);

        if (startDate > endDate) {
          errors.endDate = "End date must be after or equal to start date";
        }
      }

      eventForm.setErrors(errors);
      if (Object.keys(errors).length === 0) {
        if (id) {
          modifyData(
            `/admin/event/${id}`,
            "put",
            {
              ...formData,
            },
            eventForm
          );
        } else {
          modifyData(
            `/admin/event`,
            "post",
            {
              ...formData,
            },
            eventForm
          );
        }
      }
    },
  });

  const handleEventTypeValue = (selectedOption: any) => {
    eventForm.setFieldTouched("eventTypeId", true);
    if (selectedOption?.value) {
      eventForm.setValues((prevData: any) => ({
        ...prevData,
        eventTypeId: Number(selectedOption?.value),
      }));
    } else {
      eventForm.setValues((prevData: any) => ({
        ...prevData,
        eventTypeId: "",
      }));
    }
  };

  const editPermission = (data as any)?.entityPermissions
    ? !(data as any)?.entityPermissions?.includes("Event-Update")
    : false;

  const titleBar = {
    title: "Create Event",
    buttonTitle: "Save",
    headerTitle: "Event",
    onClick: () => eventForm.handleSubmit(),
    dirty: eventForm.dirty,
    buttonLoading: apiLoading,
  };

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await axios.get("/admin/eventType");

        const fetchedOptions = response.data.map(
          (item: { label: any; value: any }) => ({
            label: item.label,
            value: item.value,
          })
        );
        setOptions(fetchedOptions);
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    };

    fetchOptions();
  }, []);

  return (
    <FormContainer titleBar={titleBar}>
      <div className="main-div">
        <Grid container alignItems="stretch" columnSpacing={4} rowSpacing={0}>
          <Grid item md={2} sm={2} xs={12}>
            <InputLabel className="cityImageFieldLabel">
              Event Type
              <Typography component="span" color="error">
                *
              </Typography>
              :
            </InputLabel>
          </Grid>
          <Grid item md={4} sm={4} xs={12}>
            <ZSAutocomplete
              options={eventTypes}
              required
              placeholder="Event type"
              setValue={handleEventTypeValue}
              defaultValue={selectedeventType}
              setBlur={() => {
                eventForm.setFieldTouched("eventTypeId", true);
              }}
              refetchFunction={(searchString: string) => {
                setPayload((prevData: any) => {
                  return { ...prevData, search: searchString };
                });
              }}
              errorMessage={
                ((eventForm.touched as any)?.eventTypeId &&
                  (eventForm.errors as any)?.eventTypeId) ??
                null
              }
            />
          </Grid>
        </Grid>

        <List dense className="border-custom">
          <Grid
            container
            alignItems="stretch"
            columnSpacing={4}
            rowSpacing={0}
            sx={{ mb: 3 }}
          >
            <Grid item md={2} sm={2} xs={12}>
              <InputLabel className="cityImageFieldLabel">
                Name
                <Typography component="span" color="error">
                  *
                </Typography>
                :
              </InputLabel>
            </Grid>
            <Grid item md={4} sm={4} xs={12}>
              <InputTextfield
                form={eventForm}
                value={eventForm?.values?.name || ""}
                fieldProps={{
                  id: "name",
                  name: "name",
                  type: "text",
                  required: true,
                  placeholder: "Event Name",
                }}
              />
            </Grid>

            <Grid item md={2} sm={2} xs={12}>
              <InputLabel className="cityImageFieldLabel">
                Venue
                <Typography component="span" color="error">
                  *
                </Typography>
                :
              </InputLabel>
            </Grid>
            <Grid item md={4} sm={4} xs={12}>
              <InputTextfield
                form={eventForm}
                value={eventForm?.values?.location || ""}
                fieldProps={{
                  id: "location",
                  name: "location",
                  type: "text",
                  placeholder: "Venue",
                  required: true,
                }}
              />
            </Grid>

            <Grid item container spacing={3}>
              <Grid item md={2} sm={2} xs={12}>
                <InputLabel className="cityImageFieldLabel">
                  Start Date :
                </InputLabel>
              </Grid>
              <Grid item md={4} sm={4} xs={12}>
                <InputTextfield
                  form={eventForm}
                  value={eventForm?.values?.startDate || ""}
                  fieldProps={{
                    id: "startDate",
                    name: "startDate",
                    label: "Start Date",
                    type: "text",
                    required: true,
                    placeholder: "DD/MM/YYYY",
                  }}
                  onChange={handleStartDateChange}
                />
              </Grid>
              <Grid item md={2} sm={2} xs={12}>
                <InputLabel className="cityImageFieldLabel">
                  End Date :
                </InputLabel>
              </Grid>
              <Grid item md={4} sm={4} xs={12}>
                <InputTextfield
                  form={eventForm}
                  value={eventForm?.values?.endDate || ""}
                  fieldProps={{
                    id: "endDate",
                    name: "endDate",
                    label: "End Date",
                    type: "text",
                    required: true,
                    placeholder: "DD/MM/YYYY",
                  }}
                  onChange={handleEndDateChange}
                />
              </Grid>
            </Grid>

            <Grid item md={2} sm={2} xs={12}>
              <InputLabel className="cityImageFieldLabel">
                Description:
              </InputLabel>
            </Grid>
            <Grid item md={4} sm={4} xs={12}>
              <InputTextfield
                form={eventForm}
                multiline
                fieldProps={{
                  id: "description",
                  name: "description",
                  label: "description",
                  type: "text",
                  required: false,
                  placeholder: "Event description",
                  capitalizedFirst: true,
                }}
              />
            </Grid>
            <Grid item md={2} sm={2} xs={12}>
              <InputLabel className="cityImageFieldLabel">Image :</InputLabel>
            </Grid>
            <Grid item md={4} sm={4} xs={12}>
              <FileInput
                form={eventForm}
                fieldProps={{
                  id: "image",
                  name: "image",
                  type: "file",
                  required: true,
                }}
                onChange={(file: any) => {}}
              />
            </Grid>

            <Grid item md={6} sm={6} xs={12}>
              <InputLabel className="cityImageFieldLabel">
                <Checkbox checked /> Sent notifications to all members
              </InputLabel>

              <InputLabel className="cityImageFieldLabel">
                <Checkbox checked /> Sent notifications to all members
              </InputLabel>
            </Grid>
          </Grid>
        </List>
        <div className={editPermission ? "disabled" : ""}></div>
      </div>
    </FormContainer>
  );
};
export default Event;
