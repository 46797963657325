import { Container } from "@mui/material";
import { FC, useContext } from "react";
import DataTable from "../../../../../shared/components/dataTable";
import TitleBar from "../../../../../shared/components/titleBar";
import { useNavigate } from "react-router-dom";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import Loader from "../../../../../shared/components/loader/linearProgress";
import NoPermissionContent from "../../../../../shared/components/noPermissionBanner";
import SearchBox from "../../../../../layouts/SidebarLayout/Header/SearchBox";
import { Family } from "../../../../../shared/interfaces/family";
import { Address } from "../../../../../shared/interfaces/address";

const FamilyList: FC = () => {
  const navigate = useNavigate();
  const { payload, setPayload } = useContext(SidebarContext);
  const { loading, data, fetchData, error } = useQuery("/admin/family", {
    params: { ...payload },
  });

  const onButtonClick = () => {
    navigate("/branchMembership/families/create");
  };

  const familyDisplayColumns = [
    "code",
    "houseNameEng",
    "houseNo",
    "streetNameEng",
    "kudumbaUnitNameEng",
  ];

  let familyList: Family[] = [];
  if (data && (data as any)?.items) {
    familyList = (data as any)?.items.map((item: any) => {
      const address: Address = item?.familyAddresses?.find(
        (address: Address) => address.currentAddress
      );
      return {
        id: item.id,
        code: item?.familyCode,
        houseNo: address?.houseNo,
        streetNameEng: address?.streetNameEng,
        houseNameEng: address?.houseNameEng,
        kudumbaUnitNameEng: item?.kudumbaUnitNameEng,
      };
    });
  }
  const sortableColumnsMember = [
    // "code", "houseNameEng",
    "kudumbaUnitNameEng",
  ];

  return (
    <Container className="tableListContainer">
      <TitleBar
        title="Families"
        buttonTitle="Create" // Pass 'Create' to get add button
        onClick={onButtonClick}
        headerTitle="Family"
        disabled={
          (data as any)?.entityPermissions
            ? !(data as any)?.entityPermissions?.includes("Family-Create")
            : false
        }
      >
        <SearchBox />
      </TitleBar>
      {loading ? (
        <Loader />
      ) : (error as any)?.statusCode === 403 ? (
        <NoPermissionContent />
      ) : (
        <DataTable
          entityName="Family"
          data={familyList}
          displayColumns={familyDisplayColumns}
          sortableColumns={sortableColumnsMember}
          linkColumn="houseNameEng"
          linkUrl="/branchMembership/families"
          pagination={{ take: payload.take, skip: payload.skip }}
          setPagination={setPayload}
          totalItems={(data as any)?.totalItems}
          deleteApiUrl="/admin/family"
          refetchFunction={fetchData}
        />
      )}
    </Container>
  );
};

export default FamilyList;
