import User from "../components/administrator";
import UserList from "../components/administratorList";
import PersonIcon from "@mui/icons-material/Person";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { Navigate } from "react-router-dom";
const administratorRoute = {
  icon: PersonIcon,
  name: "User Management",
  link: "/user-management",
  path: "user-management",
  element: "",
  index: 7,
  children: [
    {
      path: "admin",
      element: <UserList />,
      name: "User",
      link: "/user-management/admin",
      icon: ManageAccountsIcon,
      index: 0,
    },
    {
      path: "admin/create",
      element: <User />,
    },
    {
      path: "admin/:id",
      element: <User />,
    },
    {
      path: "",
      element: <Navigate to="role" replace />,
    },
  ],
};
export default administratorRoute;
