import { Container, IconButton } from "@mui/material";
import { FC, useContext, useState } from "react";
import TitleBar from "../../../../../shared/components/titleBar";
import DataTable from "../../../../../shared/components/dataTable";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import NoPermissionContent from "../../../../../shared/components/noPermissionBanner";
import Loader from "../../../../../shared/components/loader/linearProgress";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import ConfirmDialog from "../../../../../shared/components/confirmDialogModal";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
const MembershipList: FC = () => {
  const { modifyData } = useMutation("/yogamMemberships/membershipList");
  const { payload, setPayload } = useContext(SidebarContext);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [message, setMessage] = useState("");
  const { loading, data, fetchData, error } = useQuery(
    "/admin/members/membership",
    {
      params: {
        ...payload,
        // unionMembershipStatus: statusFilter === "all" ? null : statusFilter,
      },
    }
  );

  const members = (data as any)?.items?.map((member: any) => {
    return {
      ...member,

      code: member?.code,
      fullNameEng: member?.fullNameEng,
      houseNameEng: member?.family?.address?.houseNameEng,
      gender: member?.gender,
      sangamNo: member?.joinedSangamNo,
      familyHead: member?.family?.memberHead?.fullNameEng,
      unionMembershipStatus: member?.unionMembershipStatus,
    };
  });
  const membershipDisplayColumns = [
    "code",
    "fullNameEng",
    "houseNameEng",
    "gender",
    "familyHead",
    "unionMembershipStatus",
  ];
  const handleModalOpen = (member: any) => {
    if (typeof member === "object" && !Array.isArray(member)) {
      setSelectedIds([member]);
      setMessage(
        `${member.fullNameEng} is eligible to apply for yogam membership.please sent notification`
      );
    } else if (Array.isArray(member)) {
      setSelectedIds(member);
      setMessage(
        `${member.length} members are eligible to apply yogam membership application`
      );
    }

    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
  };
  const handleNotificationSubmit = () => {
    modifyData(`/notification/send`, "post", {
      memberIds: selectedIds,
      message: message,
    });

    console.log("Notification sent to eligible members.");

    // Close the modal after sending the notification
    setOpenModal(false);
  };

  return (
    <Container className="tableListContainer">
      <TitleBar title="Eligible Members" headerTitle="Eligible Members">
        <IconButton
          className="TitleBarButton"
          size="medium"
          onClick={() => handleModalOpen(selectedIds)}
        >
          <CircleNotificationsIcon />
        </IconButton>
      </TitleBar>
      {loading ? (
        <Loader />
      ) : (error as any)?.statusCode === 403 ? (
        <NoPermissionContent />
      ) : (
        <>
          <DataTable
            entityName="Eligible Members"
            data={members}
            displayColumns={membershipDisplayColumns}
            linkColumn="name"
            pagination={{ take: payload.take, skip: payload.skip }}
            setPagination={setPayload}
            totalItems={(data as any)?.totalItems}
            refetchFunction={fetchData}
            extraAction={<NotificationsActiveIcon fontSize="small" />}
            newAction={handleModalOpen}
            showCheckboxes={true}
            onUpdateStatus={setSelectedIds}
          />
          <>
            {openModal === true ? (
              <ConfirmDialog
                title={"Notify Members"}
                openConfirmDialog={openModal}
                setOpenConfirmDialog={handleClose}
                message={message}
                handleSubmit={handleNotificationSubmit}
              />
            ) : null}
          </>
        </>
      )}
    </Container>
  );
};

export default MembershipList;
