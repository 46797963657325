import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FC, useContext, useEffect } from "react";
import TitleBar from "../../../../../shared/components/titleBar";
import DataTable from "../../../../../shared/components/dataTable";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import NoPermissionContent from "../../../../../shared/components/noPermissionBanner";
import Loader from "../../../../../shared/components/loader/linearProgress";
import SearchBox from "../../../../../layouts/SidebarLayout/Header/SearchBox";
import { Member } from "../../../../../shared/interfaces/member";
import { EntityContext } from "../../../../../contexts/EntityContext";

const MembersList: FC = () => {
  const navigate = useNavigate();
  const { payload, setPayload } = useContext(SidebarContext);
  const { setEntityName } = useContext(EntityContext);

  const { loading, data, fetchData, error } = useQuery("/admin/members", {
    params: { ...payload },
  });
  useEffect(() => {
    setEntityName({
      entityTitle: "Member ",
    });
  }, [setEntityName]);

  const onButtonClick = () => {
    navigate("/branchMembership/members/create");
  };

  const memberDisplayColumns = [
    "code",
    "fullNameEng",
    "houseNameEng",
    "gender",
    "familyHead",
  ];

  let memberList: Member[] = [];
  if (data && (data as any)?.items) {
    memberList = (data as any)?.items.map((item: any) => {
      return {
        id: item.id,
        code: item?.code,
        fullNameEng: item?.fullNameEng,
        gender: item?.gender,
        joinedSangamNo: item?.joinedSangamNo,
        houseNameEng: item?.family?.familyAddresses[0]?.houseNameEng,
        familyHead: item?.family?.familyMembers[0]?.fullNameEng,
      };
    });
  }

  const sortableColumnsMember = ["code", "fullNameEng"];

  return (
    <Container className="tableListContainer">
      <TitleBar
        title="Members"
        buttonTitle="Create"
        onClick={onButtonClick}
        disabled={
          (data as any)?.entityPermissions
            ? !(data as any)?.entityPermissions?.includes("Member-Create")
            : false
        }
      >
        <SearchBox />
      </TitleBar>
      {loading ? (
        <Loader />
      ) : (error as any)?.statusCode === 403 ? (
        <NoPermissionContent />
      ) : (
        <DataTable
          entityName="Members"
          data={memberList}
          displayColumns={memberDisplayColumns}
          sortableColumns={sortableColumnsMember}
          linkColumn="fullNameEng"
          linkUrl="/branchMembership/members"
          pagination={payload}
          setPagination={setPayload}
          totalItems={(data as any)?.totalItems}
          deleteApiUrl="/admin/members"
          refetchFunction={fetchData}
        />
      )}
    </Container>
  );
};

export default MembersList;
