import { Navigate } from "react-router-dom";
import MembershipRequestList from "../components/memberRequestList";
// import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import MemberRequestView from "../components/memberRequestView";
const memberRequestRoute = {
  icon: PeopleAltIcon,
  name: "Branch Memberships",
  link: "/branchMembership",
  path: "/branchMembership",
  element: "",
  index: 0,
  children: [
    {
      path: "membershipRequests",
      element: <MembershipRequestList />,
      name: "Membership Requests",
      index: 2,
      link: "/branchMembership/membershipRequests",
      icon: ManageAccountsIcon,
    },

    {
      path: "membershipRequests/:id",
      element: <MemberRequestView />,
    },
    {
      path: "",
      element: <Navigate to="family" replace />,
    },
  ],
};
export default memberRequestRoute;
