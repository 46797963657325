import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../shared/components/loader/linearProgress";
const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/dashboard");
  }, [navigate]);
  return <Loader />;
};

export default Home;
