import EventType from "../components/eventType";
import EventTypeList from "../components/eventTypeList";
import EventNoteIcon from "@mui/icons-material/EventNote";
import EventIcon from "@mui/icons-material/Event";
import { Navigate } from "react-router-dom";

const eventTypeRoute = {
  icon: EventIcon,
  name: "Event Management",
  link: "/eventManagement",
  path: "eventManagement",
  element: "",
  index: 4,
  children: [
    {
      path: "eventTypes",
      element: <EventTypeList />,
      name: "Event Types",
      index: 0,
      link: "eventManagement/eventTypes",
      icon: EventNoteIcon,
    },
    {
      path: "eventTypes/create",
      element: <EventType />,
    },
    {
      path: "eventTypes/:id",
      element: <EventType />,
    },

    {
      path: "",
      element: <Navigate to="event" replace />,
    },
  ],
};
export default eventTypeRoute;
