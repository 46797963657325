import { Box, styled } from "@mui/material";
import LoginLeft from "../../../assets/img/login-left.svg";
import TopRight from "../../../assets/img/right-top.svg";
import BottomRight from "../../../assets/img/right-bottom.svg";
import LogoWhite from "../../../assets/img/logo-white.svg";

interface HomePageWrapperProps {
  children?: React.ReactNode;
}

const HomePageWrapperBox = styled(Box)(() => ({
  height: "100%",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  overflow: "hidden",
  backgroundPosition: "center left",
  flex: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const HomePageWrapper: React.FC<HomePageWrapperProps> = ({
  children,
  ...rest
}) => {
  return (
    <HomePageWrapperBox className="login-page" {...rest}>
      <img src={LogoWhite} className="white-logo" alt="" />
      <img src={LoginLeft} className="login-left" alt="" />
      <img src={TopRight} className="topRight" alt="" />
      {children}
      <img src={BottomRight} className="bottomRight" alt="" />
    </HomePageWrapperBox>
  );
};
export default HomePageWrapper;
