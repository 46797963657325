import { Box, styled } from "@mui/material";
import { Link } from "react-router-dom";
import connectLogo from "../../../assets/img/logo-connect.svg";

const LogoWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  width: "220px",
  height: "49.871px",
}));
function Logo() {
  return (
    <Link to="/">
      <LogoWrapper>
        <img src={connectLogo} alt={connectLogo} width="180" />
      </LogoWrapper>
    </Link>
  );
}

export default Logo;
