import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FC, useContext } from "react";
import TitleBar from "../../../../../shared/components/titleBar";
import { EntityContext } from "../../../../../contexts/EntityContext";
import DataTable from "../../../../../shared/components/dataTable";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import NoPermissionContent from "../../../../../shared/components/noPermissionBanner";
import Loader from "../../../../../shared/components/loader/linearProgress";
import SearchBox from "../../../../../layouts/SidebarLayout/Header/SearchBox";

const UserList: FC = () => {
  const navigate = useNavigate();
  const { setEntityName } = useContext(EntityContext);
  const { payload, setPayload } = useContext(SidebarContext);

  const { loading, data, fetchData, error } = useQuery(
    "/admin/administrators",
    {
      params: {
        ...payload,
      },
    }
  );

  const navigateToDetails = () => {
    setEntityName({
      entityName: ["Create"],
      entityTitle: "User",
    });

    navigate("/user-management/admin/create");
  };

  const adminDisplayColumns = ["firstName", "lastName", "email"];

  return (
    <>
      <Container className="tableListContainer">
        <TitleBar
          title="User"
          buttonTitle="Create"
          headerTitle="User"
          onClick={navigateToDetails}
        >
          <SearchBox />
        </TitleBar>
        {loading ? (
          <Loader />
        ) : (error as any)?.statusCode === 403 ? (
          <NoPermissionContent />
        ) : (
          <DataTable
            entityName="User"
            data={(data as any)?.items ?? []}
            displayColumns={adminDisplayColumns}
            linkColumn="firstName"
            linkUrl="/user-management/admin"
            pagination={{ take: payload.take, skip: payload.skip }}
            setPagination={setPayload}
            totalItems={(data as any)?.totalItems}
            deleteApiUrl="/admin/administrators"
            refetchFunction={fetchData}
          />
        )}
      </Container>
    </>
  );
};

export default UserList;
