import { useEffect } from "react";
import { clearLocalStorageData } from "../../../../shared/utils/localStorage";
import useMutation from "../../../../shared/components/hooks/useMutation";

const Logout = () => {
  const { modifyData } = useMutation();

  useEffect(() => {
    modifyData(`/admin/logout`, "post");
    window.location.href = "/login";
    clearLocalStorageData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <></>;
};

export default Logout;
