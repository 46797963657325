/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { Grid, Checkbox, FormControlLabel } from "@mui/material";
import { FC, useEffect } from "react";
import ToggleSwitch from "../../../../shared/components/switch";
import InputTextfield from "../../../../shared/components/textField";
import useMutation from "../../../../shared/components/hooks/useMutation";
import { getValidationSchema } from "../../../../shared/validations/schema";
import useLazyQuery from "../../../../shared/components/hooks/useLazyQuery";
import { FormContainer } from "../../../../shared/components/formContainer";
import QuillEditor from "../../../../shared/components/quillEditor";
import { ErrorMessage } from "../../../../shared/components/errorMessage";

const Certificate: FC = () => {
  const { id } = useParams();
  const { loading: apiLoading, modifyData } = useMutation("/certificate");
  const { loading, data, fetchData } = useLazyQuery(
    `/admin/certificate/${id}`,
    null,
    "admin/certificate"
  );

  const textFields = [
    {
      id: "name",
      name: "name",
      label: "Certificate Name",
      type: "text",
      placeholder: "Enter Certificate name",
      required: true,
      capitalizedFirst: true,
    },
  ];
  const certificateForm = useFormik({
    initialValues: {
      name: (data as any)?.data?.name ?? "",
      description: (data as any)?.data?.description ?? "",
      validityPeriod: (data as any)?.data?.validityPeriod ?? "",
      isValidityPeriod: (data as any)?.data?.isValidityPeriod ?? false,
      enabled: (data as any)?.data?.enabled ?? true,
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: getValidationSchema([
      "name",
      "validityPeriod",
      "isValidityPeriod",
    ]),

    onSubmit: async (formData: any) => {
      if (id) {
        modifyData(
          `/admin/certificate/${id}`,
          "put",
          {
            ...formData,
          },
          certificateForm
        );
      } else {
        modifyData(
          `/admin/certificate`,
          "post",
          {
            ...formData,
          },
          certificateForm
        );
      }
    },
  });

  useEffect(() => {
    if (id) fetchData();
  }, [id]);

  const editPermission = (data as any)?.entityPermissions
    ? !(data as any)?.entityPermissions?.includes("Certificate-Update")
    : false;

  const titleBar = {
    title: id ? (data ? `${(data as any)?.data?.name}` : "") : "Create",
    buttonTitle: "Save",
    onClick: () => certificateForm.handleSubmit(),
    dirty: certificateForm.dirty,
    disabled:
      editPermission || Object.keys(certificateForm?.errors).length !== 0,
    buttonLoading: apiLoading,
  };

  return (
    <FormContainer titleBar={titleBar} loading={loading}>
      <div className={editPermission ? "disabled" : ""}>
        <ToggleSwitch
          status={certificateForm?.values?.enabled}
          handleChange={() => {
            certificateForm?.setFieldValue(
              "enabled",
              !certificateForm?.values?.enabled
            );
          }}
        />
        <Grid container alignItems="stretch" columnSpacing={4} rowSpacing={2}>
          {textFields.map((field, index: number) => (
            <Grid item md={4} sm={6} xs={12} key={field.id}>
              <InputTextfield
                form={certificateForm}
                fieldProps={field}
                key={index}
              />
            </Grid>
          ))}
          <Grid item md={4} sm={6} xs={12}>
            <InputTextfield
              form={certificateForm}
              multiline
              fieldProps={{
                id: "validityPeriod",
                name: "validityPeriod",
                label: "Validity Period",
                type: "text",
                required: true,
                placeholder: "Enter validity period",
                capitalizedFirst: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  required
                  checked={certificateForm.values.isValidityPeriod}
                  onChange={(event) => {
                    certificateForm.setFieldValue(
                      "isValidityPeriod",
                      event.target.checked
                    );
                  }}
                />
              }
              label="Is Validity Period"
            />
          </Grid>
          <Grid item xs={12}>
            <QuillEditor
              value={certificateForm?.values?.description}
              height="121px"
              onChange={(content: any) => {
                certificateForm?.setFieldValue("description", content);
              }}
            />
            <Grid className="description">
              <ErrorMessage
                message={
                  (certificateForm?.touched as any)?.description &&
                  (certificateForm?.errors as any)?.description
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </FormContainer>
  );
};
export default Certificate;
