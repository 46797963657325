import {
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { FC, useContext, useState } from "react";
import DataTable from "../../../../../shared/components/dataTable";
import TitleBar from "../../../../../shared/components/titleBar";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import Loader from "../../../../../shared/components/loader/linearProgress";
import NoPermissionContent from "../../../../../shared/components/noPermissionBanner";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import PrintIcon from "@mui/icons-material/Print";
import ConfirmDialog from "../../../../../shared/components/confirmDialogModal";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import SaveIcon from "@mui/icons-material/Save";
import NotifyIcon from "@mui/icons-material/Notifications";
import SearchBox from "../../../../../layouts/SidebarLayout/Header/SearchBox";
import { yogamMembershipRequest } from "../../../../../shared/interfaces/yogam-membership-request";

const MembershipRequestList: FC = () => {
  const { payload, setPayload } = useContext(SidebarContext);
  const [openNotifyModal, setOpenNotifyModal] = useState<boolean>(false);
  const [openPrintModal, setOpenPrintModal] = useState<boolean>(false);
  const [selectedMemberRequest, setSelectedMemberRequest] = useState<any>(null);
  const [selectedIds, setSelectedIds] = useState<any[]>([]);
  const [message, setMessage] = useState("");
  const { modifyData } = useMutation(
    "/yogamMemberships/yogamMembershipRequests"
  );
  const { loading, data, fetchData, error } = useQuery(
    "/admin/yogam-membership-request",
    {
      params: {
        ...payload,
      },
    }
  );
  // const handlePrint = async (row: { id: any }) => {
  //   try {
  //     // eslint-disable-next-line react-hooks/rules-of-hooks
  //     const { loading, data, fetchData, error } = useLazyQuery(
  //       `/member/membershipApplication/${row.id}`,
  //       {
  //         params: {
  //           ...payload,
  //         },
  //       }
  //     );
  //     // const { url } = response.data;

  //     // const link = document.createElement("a");
  //     // link.href = url;
  //     // link.setAttribute("download", `member_${row.id}.pdf`);
  //     // document.body.appendChild(link);
  //     // link.click();
  //     // document.body.removeChild(link);
  //   } catch (error) {
  //     console.error("Error generating PDF:", error);
  //   }
  // };

  const [formData, setFormData] = useState<any>({});

  const renderCell = (row: any, col: string) => {
    if (col === "status") {
      return (
        <FormControl fullWidth>
          <InputLabel>Select Status</InputLabel>
          <Select
            value={formData[row.id]?.branchStatus || row.branchStatus}
            onChange={(e) => {
              const updatedStatus = e.target.value;
              setFormData((prev: any) => ({
                ...prev,
                [row.id]: {
                  ...prev[row.id],
                  branchStatus: updatedStatus,
                },
              }));

              // Automatically add row to selectedIds if status is changed
              setSelectedIds((prevSelected) => {
                if (!prevSelected.some((item) => item.id === row.id)) {
                  return [...prevSelected, row];
                }
                return prevSelected;
              });
            }}
            label="Select Status"
          >
            <MenuItem key={0} value="Pending">
              Pending
            </MenuItem>
            <MenuItem key={1} value="Approved">
              Approved
            </MenuItem>
            <MenuItem key={2} value="Submitted">
              Submitted
            </MenuItem>
            <MenuItem key={3} value="Rejected">
              Rejected
            </MenuItem>
          </Select>
        </FormControl>
      );
    }
    if (col === "remarks" && formData[row.id]?.branchStatus === "Rejected") {
      return (
        <TextField
          value={formData[row.id]?.remarks || ""}
          onChange={(e) => {
            const updatedRemarks = e.target.value;
            setFormData((prev: any) => ({
              ...prev,
              [row.id]: {
                ...prev[row.id],
                remarks: updatedRemarks,
              },
            }));
          }}
          label="Remarks"
          fullWidth
        />
      );
    }
    if (col === "actions") {
      return (
        <>
          <IconButton
            onClick={() => handleNotifyModalOpen(row)}
            color="secondary"
            aria-label="send notification"
          >
            <NotificationsActiveIcon fontSize="small" />
          </IconButton>
          <IconButton
            onClick={() => handlePrintModalOpen(row)}
            color="secondary"
            aria-label="print member details"
          >
            <PrintIcon />
          </IconButton>
        </>
      );
    }
    return row[col];
  };

  const handleSubmit = async () => {
    try {
      const updates = selectedIds.map((item) => ({
        id: item.id,
        branchStatus: formData[item.id]?.branchStatus,
        remarks:
          formData[item.id]?.branchStatus === "Rejected"
            ? formData[item.id]?.remarks
            : null,
      }));

      if (updates.length > 0) {
        await modifyData(
          "/admin/yogam-membership-request/update-status",
          "put",
          updates
        );
      }
      fetchData();
    } catch (error) {
      console.error("Failed to update membership applications:", error);
    }
  };

  const membershipRequestDisplayColumns = [
    "memberCode",
    "fullName",
    "houseNameEng",
    "familyHead",
    "status",
    "remarks",
    "actions",
  ];

  let membershipList: yogamMembershipRequest[] = [];
  if (data && (data as any)?.items) {
    membershipList = (data as any)?.items.map((item: any) => {
      return {
        id: item.id,
        memberID: item?.memberID,
        memberCode: item?.member?.code,
        fullName: item?.member?.fullNameEng,
        houseNameEng: item?.member?.family?.familyAddresses[0]?.houseNameEng,
        familyHead: item?.member?.family?.familyMembers[0]?.fullNameEng,
        branchStatus: item?.branchStatus,
        remarks: item?.remarks,
      };
    });
  }

  const handleNotifyModalOpen = (member: any) => {
    if (typeof member === "object" && !Array.isArray(member)) {
      setSelectedMemberRequest([member]);
      setMessage(
        `Application of ${member?.fullName} is ready for signature. Send notifications for office visit.Please confirm to proceed.`
      );
    } else if (Array.isArray(member)) {
      setSelectedMemberRequest(member);
      setMessage(
        `${member?.length} members applications are ready for signature. Send notifications for office visits.Please confirm to proceed.`
      );
    }

    setOpenNotifyModal(true);
  };

  const handlePrintModalOpen = (member: any) => {
    if (typeof member === "object" && !Array.isArray(member)) {
      setSelectedMemberRequest([member]);
      setMessage(
        ` The processed application of ${member?.fullName} has been selected for printing.Please confirm to proceed.`
      );
    } else if (Array.isArray(member)) {
      setSelectedMemberRequest(member);
      setMessage(
        `${member?.length} members processed applications have been selected for printing.Please confirm to proceed.`
      );
    }

    setOpenPrintModal(true);
  };
  const handleClose = () => {
    setOpenNotifyModal(false);
  };

  const handlePrintClose = () => {
    setOpenPrintModal(false);
  };

  const handleNotificationSubmit = () => {
    modifyData(`/notification/send`, "post", {
      memberIds: selectedMemberRequest,
      message: message,
    });
    console.log("Notification sent to member.");
    setOpenNotifyModal(false);
  };

  return (
    <Container className="tableListContainer">
      <TitleBar
        title="Yogam Membership Requests"
        headerTitle="Yogam Membership Requests"
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SearchBox />
          </Grid>
          <Grid item xs={2}>
            <IconButton color="secondary" onClick={handleSubmit}>
              <SaveIcon />
            </IconButton>
          </Grid>

          <Grid item xs={2}>
            <IconButton
              color="secondary"
              onClick={() => handleNotifyModalOpen(selectedMemberRequest)}
            >
              <NotifyIcon />
            </IconButton>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              onClick={() => handlePrintModalOpen(selectedMemberRequest)}
              color="secondary"
              aria-label="print member details"
            >
              <PrintIcon />
            </IconButton>
          </Grid>
        </Grid>
      </TitleBar>
      {loading ? (
        <Loader />
      ) : (error as any)?.statusCode === 403 ? (
        <NoPermissionContent />
      ) : (
        <>
          <DataTable
            entityName="Yogam Membership Requests"
            data={membershipList}
            displayColumns={membershipRequestDisplayColumns}
            linkColumn="fullNameEng"
            linkUrl=""
            pagination={{ take: payload.take, skip: payload.skip }}
            setPagination={setPayload}
            totalItems={(data as any)?.totalItems}
            showCheckboxes={true}
            refetchFunction={fetchData}
            onUpdateStatus={setSelectedIds}
            newAction={handleNotifyModalOpen}
            renderCell={renderCell}
          />
          <>
            {openNotifyModal === true ? (
              <ConfirmDialog
                title={"Notify Member"}
                openConfirmDialog={openNotifyModal}
                setOpenConfirmDialog={handleClose}
                message={message}
                handleSubmit={handleNotificationSubmit}
              />
            ) : null}
          </>
          <>
            {openPrintModal === true ? (
              <ConfirmDialog
                title={"Print Application"}
                openConfirmDialog={openPrintModal}
                setOpenConfirmDialog={handlePrintClose}
                message={message}
                handleSubmit={handleNotificationSubmit}
              />
            ) : null}
          </>
        </>
      )}
    </Container>
  );
};

export default MembershipRequestList;
