import UnionApprovalList from "../components/unionApprovalList";
import ListAltIcon from "@mui/icons-material/ListAlt";
import GroupsIcon from "@mui/icons-material/Groups";
const memberRequestRoute = {
  icon: GroupsIcon,
  name: "Yogam Memberships",
  link: "/yogamMemberships",
  path: "yogamMemberships",
  element: "",
  index: 3,
  children: [
    {
      path: "unionApproval",
      element: <UnionApprovalList />,
      name: "Union Approval",
      index: 3,
      link: "/yogamMemberships/unionApproval",
      icon: ListAltIcon,
    },
  ],
};
export default memberRequestRoute;
