/* eslint-disable react-hooks/exhaustive-deps */
import { InputLabel, Typography } from "@mui/material";
import { FC } from "react";
import "./styles.css";

type InputFormLabelProps = {
  label: string;
  required?: boolean;
};
const InputFormLabel: FC<InputFormLabelProps> = ({
  required = false,
  label,
}) => {
  return (
    <InputLabel sx={{ mt: 1 }} className="inputLabel">
      {label}
      {required ? (
        <>
          <Typography component="span" color="error">
            *
          </Typography>
        </>
      ) : null}
      &nbsp;&nbsp;:
    </InputLabel>
  );
};

export default InputFormLabel;
