const regularExpressions = {
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  password: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/,
  dateMonth: /^\d{2}\/\d{2}$/,
  alphabetsNumbers: /^[a-zA-Z0-9 ]*$/,
  address: /^[^!@#$%^&*()_+={}|[\]\\:";'<>?/]+$/,
  alphabetsOnly: /^[^\d!@#$%^&*()_+={}|[\]\\:";'<>?,./]+$/,
  tooManySpace: /  +/g,
  rationCard: /^([a-zA-Z0-9]){10}\s*$/,
};
export default regularExpressions;
