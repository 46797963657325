// import User from "../components/administrator";
import ResetPassword from "../components/resetPassword";
import PersonIcon from "@mui/icons-material/Person";
import SyncLockIcon from "@mui/icons-material/SyncLock";
import { Navigate } from "react-router-dom";
const resetPasswordRoute = {
  icon: PersonIcon,
  name: "User Management",
  link: "/user-management",
  path: "user-management",
  element: "",
  index: 7,
  children: [
    {
      path: "resetPassword",
      element: <ResetPassword />,
      name: "Reset Password",
      link: "/user-management/resetPassword",
      icon: SyncLockIcon,
      index: 2,
    },
    {
      path: "",
      element: <Navigate to="role" replace />,
    },

    // {
    //   path: "admin/create",
    //   element: <User />,
    // },
    // {
    //   path: "admin/:id",
    //   element: <User />,
    // },
  ],
};
export default resetPasswordRoute;
