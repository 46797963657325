import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { Grid, InputLabel, List, Typography } from "@mui/material";
import { FC } from "react";
import InputTextfield from "../../../../../shared/components/textField";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import { getValidationSchema } from "../../../../../shared/validations/schema";
import useLazyQuery from "../../../../../shared/components/hooks/useLazyQuery";
import { FormContainer } from "../../../../../shared/components/formContainer";

const EventType: FC = () => {
  const { id } = useParams();
  const { loading: apiLoading, modifyData } = useMutation(
    "/eventManagement/eventTypes"
  );

  const { data } = useLazyQuery(`/admin/event-type/${id}`);

  const eventTypeForm = useFormik({
    initialValues: {
      eventTypeName: (data as any)?.data?.eventTypeName ?? "",
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: getValidationSchema(["eventTypeName"]),

    onSubmit: async (formData: any) => {
      if (id) {
        modifyData(
          `/admin/event-type/${id}`,
          "put",
          {
            ...formData,
          },
          eventTypeForm
        );
      } else {
        modifyData(
          `/admin/event-type`,
          "post",
          {
            ...formData,
          },
          eventTypeForm
        );
      }
    },
  });

  const editPermission = (data as any)?.entityPermissions
    ? !(data as any)?.entityPermissions?.includes("EventType-Update")
    : false;

  const titleBar = {
    title: "Create Event type",
    buttonTitle: "Save",
    headerTitle: "Event Type",
    onClick: () => eventTypeForm.handleSubmit(),
    dirty: eventTypeForm.dirty,
    buttonLoading: apiLoading,
  };

  return (
    <FormContainer titleBar={titleBar}>
      <div className="main-div">
        <List dense className="border-custom">
          <Grid
            container
            alignItems="stretch"
            columnSpacing={4}
            rowSpacing={0}
            sx={{ mb: 3 }}
          >
            <Grid item md={2} sm={2} xs={12}>
              <InputLabel className="cityImageFieldLabel">
                Event Type
                <Typography component="span" color="error">
                  *
                </Typography>
                :
              </InputLabel>
            </Grid>
            <Grid item md={4} sm={4} xs={12}>
              <InputTextfield
                form={eventTypeForm}
                value={eventTypeForm?.values?.eventTypeName || ""}
                fieldProps={{
                  id: "eventTypeName",
                  name: "eventTypeName",
                  type: "text",
                  required: true,
                  placeholder: "Event Type Name",
                }}
              />
            </Grid>
          </Grid>
        </List>
        <div className={editPermission ? "disabled" : ""}></div>
      </div>
    </FormContainer>
  );
};
export default EventType;
