import { Grid } from "@mui/material";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import InputTextfield from "../../../../../shared/components/textField";
import { getValidationSchema } from "../../../../../shared/validations/schema";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import { FormContainer } from "../../../../../shared/components/formContainer";
import useLazyQuery from "../../../../../shared/components/hooks/useLazyQuery";

const App = () => {
  const { id } = useParams();

  const { loading: apiLoading, modifyData } = useMutation(
    "/users/resetPassword"
  );

  const { loading, data } = useLazyQuery(
    `/admin/resetPassword`,
    null,
    "/users/resetPassword"
  );

  const textFields = [
    {
      id: "login-old-password",
      name: "oldPassword",
      label: "Old Password",
      placeholder: "Enter old password",
      type: "password",
    },
    {
      id: "login-new-password",
      name: "newPassword",
      label: "New Password",
      placeholder: "Enter new password",
      type: "password",
    },
    {
      id: "login-confirm-password",
      name: "confirmPassword",
      label: "Confirm Password",
      placeholder: "Confirm new password",
      type: "password",
    },
  ];

  const passwordResetForm = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validateOnBlur: true,
    validationSchema: getValidationSchema(["newPassword", "confirmPassword"]),
    onSubmit: async (formData: any) => {
      modifyData(
        `/admin/resetPassword`,
        "put",
        {
          ...formData,
        },
        passwordResetForm
      );
    },
  });

  const titleBar = {
    title: id
      ? data
        ? `${(data as any)?.data?.EventName}`
        : ""
      : "Reset Password",
    buttonTitle: "Save",
    onClick: () => passwordResetForm.handleSubmit(),
    dirty: passwordResetForm.dirty,
    disabled: Object.keys(passwordResetForm?.errors).length !== 0,
    buttonLoading: apiLoading,
  };
  return (
    <FormContainer titleBar={titleBar} loading={loading}>
      <Grid container alignItems="stretch" columnSpacing={4} rowSpacing={2}>
        {textFields.map((field, index: number) => (
          <Grid item md={4} sm={6} xs={12} key={field.id}>
            <InputTextfield
              form={passwordResetForm}
              fieldProps={field}
              key={index}
            />
          </Grid>
        ))}
      </Grid>
    </FormContainer>
  );
};

export default App;
