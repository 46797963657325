import { FC, useEffect } from "react";
import { Grid, InputLabel } from "@mui/material";
import { useFormik } from "formik";
import useLazyQuery from "../../../../../shared/components/hooks/useLazyQuery";

import { useParams } from "react-router-dom";
import { FormContainer } from "../../../../../shared/components/formContainer";

import useMutation from "../../../../../shared/components/hooks/useMutation";

import { ZSSelectBox } from "../../../../../shared/components/selectBox";
import { ApplicationStatus } from "../../../../../shared/enum";

const WelfareRequest: FC = () => {
  const { id } = useParams();
  const { modifyData } = useMutation("/welfares/welfareRequest");

  const { loading, data, fetchData } = useLazyQuery(
    `/admin/welfareMember/${id}`,
    null,
    "/welfares/welfareMember"
  );

  const requestForm = useFormik({
    initialValues: {
      fullName: (data as any)?.data?.member?.fullName ?? "",
      welfareTypeName: (data as any)?.data?.welfareType?.welfareTypeName ?? "",
      paymentFrequency: (data as any)?.data?.paymentFrequency ?? "",
      applicationStatus: (data as any)?.data?.applicationStatus ?? "",
      welfareStatus: (data as any)?.data?.welfareStatus ?? "",
    },
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit: async (formData: any) => {
      if (id) {
        modifyData(
          `/admin/welfareMember/${id}`,
          "put",
          {
            ...formData,
          },
          requestForm
        );
      }
    },
  });

  useEffect(() => {
    if (id) fetchData();
  }, [fetchData, id]);
  const editPermission = (data as any)?.entityPermissions
    ? !(data as any)?.entityPermissions?.includes("Welfare-Update")
    : false;

  const titleBar = {
    title: id ? (data as any)?.data?.welfare?.welfareTypeName ?? "" : "",
    buttonTitle: id ? "Update" : "Create",
    headerTitle: "Welfare",
    onClick: () => requestForm.handleSubmit(),
    dirty: requestForm.dirty,
    disabled: editPermission || Object.keys(requestForm.errors).length !== 0,
  };

  return (
    <>
      <FormContainer titleBar={titleBar} loading={loading}>
        <div className={editPermission ? "disabled" : ""}>
          <Grid container alignItems="stretch" columnSpacing={4} rowSpacing={2}>
            <Grid item md={4} sm={6} xs={12}>
              <InputLabel className="allPermissionLabel">
                Name: {requestForm.initialValues.fullName}
              </InputLabel>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <InputLabel className="allPermissionLabel">
                Welfare Type: {requestForm.initialValues.welfareTypeName}
              </InputLabel>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <InputLabel className="allPermissionLabel">
                Payment Frequency: {requestForm.initialValues.paymentFrequency}
              </InputLabel>
            </Grid>

            <Grid item md={4} sm={6} xs={12}>
              <InputLabel className="allPermissionLabel">
                Welfare Status: {requestForm.initialValues.welfareStatus}
              </InputLabel>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <InputLabel className="allPermissionLabel">
                Application Status:
                <ZSSelectBox
                  fieldProps={{
                    id: "applicationStatus",
                    name: "applicationStatus",
                    required: true,
                    placeholder: "Select application status",
                  }}
                  form={requestForm}
                  options={[
                    { value: ApplicationStatus.Pending, label: "Pending" },
                    { value: ApplicationStatus.Approved, label: "Approved" },

                    { value: ApplicationStatus.Canceled, label: "Canceld" },
                    { value: ApplicationStatus.Rejected, label: "Rejected" },
                  ]}
                  errorMessage={false}
                />
              </InputLabel>
            </Grid>
          </Grid>
        </div>
      </FormContainer>
    </>
  );
};
export default WelfareRequest;
