/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Tooltip, Typography } from "@mui/material";
import { FC, Fragment, useContext, useEffect } from "react";
import "./styles.css";
import { EntityContext } from "../../../contexts/EntityContext";
import { LoadingButton } from "@mui/lab";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";

type TitleBarProps = {
  title: string;
  buttonTitle?: any;
  onClick?: Function;
  disabled?: boolean;
  headerTitle?: string;
  children?: React.ReactNode;
  buttonLoading?: boolean;
  addMemberButton?: boolean;
  search?: any;
};
const TitleBar: FC<TitleBarProps> = ({
  title,
  buttonTitle,
  onClick,
  disabled,
  headerTitle = null,
  children,
  buttonLoading = false,
}) => {
  const { setEntityName } = useContext(EntityContext);

  useEffect(() => {
    if (title) setEntityName({ entityName: title, entityTitle: headerTitle });
  }, [title]);

  return (
    <>
      <Grid container justifyContent="space-between" className="titleBar">
        <Grid item alignItems={"left"} className="title">
          <Typography className="titleBarTitle">{title}</Typography>
        </Grid>

        <Grid item alignItems={"right"} display={"flex"} gap={1}>
          {children ? <>{children ?? ""}</> : null}
          {buttonTitle && onClick ? (
            <>
              {buttonTitle === "Create" ? (
                <Tooltip arrow title="Create">
                  <IconButton
                    className="TitleBarIcon"
                    size="medium"
                    id={`${title}-${buttonTitle}`}
                    disabled={disabled ?? false}
                    onClick={() => onClick && onClick()}
                    aria-label={`${title}-${buttonTitle}`}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <LoadingButton
                  className="TitleBarButton"
                  loading={buttonLoading}
                  variant="contained"
                  size="small"
                  id={`${title}-${buttonTitle}`}
                  disabled={disabled ?? false}
                  onClick={() => onClick && onClick()}
                >
                  {buttonTitle}
                </LoadingButton>
              )}
            </>
          ) : (
            !children && <div className="TitleBarButton"></div>
          )}
        </Grid>
      </Grid>
      <hr className="divider" />
    </>
  );
};

export default TitleBar;
