import { FC, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  TextField,
  List,
  Typography,
} from "@mui/material";
import useLazyQuery from "../../../../../shared/components/hooks/useLazyQuery";
import { useParams } from "react-router-dom";
import TabPanel from "../../../../../shared/components/tabpanel/tabpanel";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import { RequestStatus } from "../../../../../shared/enum";
import EditIcon from "@mui/icons-material/Edit";
import { useFormik } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { getValidationSchema } from "../../../../../shared/validations/schema";
import InputTextfield from "../../../../../shared/components/textField";
import { Gender } from "../../../../../shared/enum";
import { ZSSelectBox } from "../../../../../shared/components/selectBox";
import { format } from "date-fns";
import { ViewContainer } from "../../../../../shared/components/viewContainer";

const MemberRequestView: FC = () => {
  const { id } = useParams();
  const [editStatus, setEditStatus] = useState(false);
  const [activeTab] = useState(0);
  const [showRemarks, setShowRemarks] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [isApproveSubmitting, setIsApproveSubmitting] = useState(false);
  const [isRejectSubmitting, setIsRejectSubmitting] = useState(false);
  const { modifyData } = useMutation("/branchMembership/membershipRequests");
  const { loading, data, fetchData } = useLazyQuery(
    `/admin/membership-request/${id}`,
    null,
    "/branchMembership/membershipRequests"
  );

  useEffect(() => {
    if (id) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const editPermission = (data as any)?.entidstyPermissions
    ? !(data as any)?.entityPermissions?.includes("Member-Update")
    : false;

  const memberData = (data as any)?.data || {};
  const isUpdatedStatus =
    memberData?.requestStatus === RequestStatus.Approved ||
    memberData?.requestStatus === RequestStatus.Rejected;

  const closeMemberEdit = () => {
    setEditStatus(false);
  };

  const handleStatusChange = async (newStatus: RequestStatus) => {
    if (id) {
      if (newStatus === RequestStatus.Rejected) {
        setIsRejectSubmitting(true);
        if (!remarks) {
          setShowRemarks(true);
          setIsRejectSubmitting(false);
          return;
        }
      } else {
        setIsApproveSubmitting(true);
      }
      await modifyData(
        `admin/membership-request/status/${id}`,
        "put",
        { ...memberForm.values, requestStatus: newStatus, remarks: remarks },
        memberForm
      );
      setIsApproveSubmitting(false);
      setIsRejectSubmitting(false);
    }
  };

  const memberForm = useFormik({
    initialValues: {
      fullNameEng: (data as any)?.data?.fullNameEng ?? "",
      fullNameMal: (data as any)?.data?.fullNameMal ?? "",
      familyID: (data as any)?.data?.familyID,
      email: (data as any)?.data?.email ?? "",
      phone: (data as any)?.data?.phone ?? null,
      gender: (data as any)?.data?.gender ?? null,
      requestStatus: (data as any)?.data?.requestStatus ?? null,
      remarks: "",
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: getValidationSchema([
      "fullNameEng",
      "email",
      "phone",
      "gender",
    ]),

    onSubmit: async (formData: any, { resetForm }) => {
      if (!formData.phone) {
        formData.phone = null;
      }
      if (id) {
        modifyData(
          `/admin/membership-request/${id}`,
          "put",
          {
            ...formData,
          },
          memberForm
        );
      } else {
        modifyData(
          `/admin/membership-request`,
          "post",
          {
            ...formData,
          },
          memberForm
        );
      }
      resetForm();
      setEditStatus(false);
    },
  });

  return (
    <ViewContainer loading={loading}>
      <div className={editPermission ? "disabled" : ""}>
        <div className="main-div">
          <TabPanel value={activeTab} index={0}>
            <List dense className="border-custom">
              <Grid container sx={{ mb: 1 }} spacing={2}>
                <Grid item md={11} xs={8}>
                  <span>
                    <b>Member Details</b>
                  </span>
                </Grid>
                {!isUpdatedStatus ? (
                  <Grid item md={1} xs={1} sx={{ justifyContent: "flex-end" }}>
                    {editStatus ? (
                      <Tooltip arrow title="Cancel">
                        <IconButton
                          className="TitleBarButton"
                          size="medium"
                          id={`request-edit`}
                          onClick={() => closeMemberEdit()}
                          aria-label={`request-edit`}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip arrow title="Edit">
                        <IconButton
                          className="TitleBarButton"
                          size="medium"
                          id={`request-edit`}
                          onClick={() => setEditStatus(!editStatus)}
                          aria-label={`request-edit`}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                ) : null}
              </Grid>
              {isUpdatedStatus ? (
                <Typography variant="body1" color="textSecondary">
                  {memberData?.requestStatus} by{" "}
                  {memberData?.approvedByUser?.admin?.firstName || "N/A"} on{" "}
                  {memberData?.updatedAt
                    ? format(new Date(memberData?.updatedAt), "dd/MM/yyyy")
                    : "N/A"}
                  .
                </Typography>
              ) : null}
              <Box
                sx={{ padding: "25px" }}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <TableContainer component={Paper}>
                  <Table size="small" sx={{ "& td": { border: 0 } }}>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <strong>Full Name English:</strong>
                        </TableCell>
                        <TableCell>
                          {!editStatus ? (
                            <>{memberData?.fullNameEng || "N/A"}</>
                          ) : (
                            <InputTextfield
                              form={memberForm}
                              value={memberForm?.values?.fullNameEng || ""}
                              fieldProps={{
                                id: "fullNameEng",
                                name: "fullNameEng",
                                type: "text",
                                required: true,
                              }}
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          <strong>Full Name Malayalam:</strong>
                        </TableCell>
                        <TableCell>
                          {!editStatus ? (
                            <>{memberData.fullNameMal || "N/A"}</>
                          ) : (
                            <InputTextfield
                              form={memberForm}
                              value={memberForm?.values?.fullNameMal || ""}
                              fieldProps={{
                                id: "fullNameMal",
                                name: "fullNameMal",
                                type: "text",
                                required: true,
                              }}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Email:</strong>
                        </TableCell>
                        <TableCell>
                          {!editStatus ? (
                            <>{memberData?.email || "N/A"}</>
                          ) : (
                            <InputTextfield
                              form={memberForm}
                              value={memberForm?.values?.email || ""}
                              fieldProps={{
                                id: "email",
                                name: "email",
                                type: "email",
                                required: true,
                              }}
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          <strong>Phone:</strong>
                        </TableCell>
                        <TableCell>
                          {!editStatus ? (
                            <>{memberData?.phone || "N/A"}</>
                          ) : (
                            <InputTextfield
                              form={memberForm}
                              value={memberForm?.values?.phone || ""}
                              fieldProps={{
                                id: "phone",
                                name: "phone",
                                type: "text",
                                required: true,
                              }}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Gender:</strong>
                        </TableCell>
                        <TableCell>
                          {!editStatus ? (
                            <>{memberData?.gender || "N/A"}</>
                          ) : (
                            <ZSSelectBox
                              fieldProps={{
                                id: "member-gender",
                                name: "gender",
                                placeholder: "Select an option",
                              }}
                              options={[
                                { label: Gender.Male, value: Gender.Male },
                                { label: Gender.Female, value: Gender.Female },
                                { label: Gender.Other, value: Gender.Other },
                              ]}
                              form={memberForm}
                              value={memberForm.values?.gender}
                            />
                          )}
                        </TableCell>
                        {showRemarks && !editStatus ? (
                          <>
                            <TableCell>
                              <strong>
                                Remarks{" "}
                                <Typography component="span" color="error">
                                  *
                                </Typography>
                              </strong>
                            </TableCell>
                            <TableCell>
                              <TextField
                                id="remarks"
                                variant="outlined"
                                placeholder="Remarks"
                                value={remarks}
                                onChange={(e) => setRemarks(e.target.value)}
                              />
                            </TableCell>
                          </>
                        ) : null}
                        {showRemarks && !editStatus ? (
                          <>
                            <TableCell>
                              <strong></strong>
                            </TableCell>
                          </>
                        ) : null}
                      </TableRow>
                      {editStatus ? (
                        <TableRow>
                          <TableCell colSpan={6} align="center">
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                memberForm.handleSubmit();
                              }}
                              disabled={editPermission}
                            >
                              Save
                            </Button>
                          </TableCell>
                        </TableRow>
                      ) : null}
                      {!editStatus && !isUpdatedStatus ? (
                        <TableRow>
                          <TableCell
                            colSpan={4}
                            style={{ textAlign: "left", fontWeight: "bold" }}
                          >
                            <Box
                              mt={2}
                              display="flex"
                              justifyContent="flex-end"
                            >
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  handleStatusChange(RequestStatus.Approved)
                                }
                                disabled={isApproveSubmitting}
                                startIcon={
                                  isApproveSubmitting ? (
                                    <CircularProgress size={20} />
                                  ) : null
                                }
                                style={{ marginRight: "10px" }}
                              >
                                Approve
                              </Button>
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() =>
                                  handleStatusChange(RequestStatus.Rejected)
                                }
                                disabled={isRejectSubmitting}
                                startIcon={
                                  isRejectSubmitting ? (
                                    <CircularProgress size={20} />
                                  ) : null
                                }
                              >
                                Reject
                              </Button>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </List>

            <List dense className="border-custom">
              <Grid container sx={{ mb: 1 }} spacing={2}>
                <Grid item xs={8}>
                  <span>
                    <b>Family Details</b>
                  </span>
                </Grid>
              </Grid>
              <Box
                sx={{ padding: "25px" }}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <TableContainer component={Paper}>
                  <Table size="small" sx={{ "& td": { border: 0 } }}>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <strong>Family code:</strong>
                        </TableCell>
                        <TableCell>
                          {memberData?.family?.familyCode || "N/A"}
                        </TableCell>
                        <TableCell>
                          <strong>Head:</strong>
                        </TableCell>
                        <TableCell>
                          {memberData?.fullNameEng || "N/A"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Ration card no:</strong>
                        </TableCell>
                        <TableCell>
                          {memberData?.family?.rationCardNumber || "N/A"}
                        </TableCell>
                        <TableCell>
                          <strong>Ration Card Type:</strong>
                        </TableCell>
                        <TableCell>
                          {memberData?.family?.rationCardType || "N/A"}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <strong>Annual Income:</strong>
                        </TableCell>
                        <TableCell>
                          {memberData?.family?.annualIncome || "N/A"}
                        </TableCell>
                        <TableCell>
                          <strong>Joined Date:</strong>
                        </TableCell>
                        <TableCell>
                          {memberData?.family?.joinedDate || "N/A"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Kudumba Unit:</strong>
                        </TableCell>
                        <TableCell>
                          {memberData?.family?.kudumbaUnitNameEng || "N/A"}
                        </TableCell>
                        <TableCell>
                          <strong>Micro Finance:</strong>
                        </TableCell>
                        <TableCell>
                          {memberData?.family?.microFinanceNameEng || "N/A"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </List>
          </TabPanel>
        </div>
      </div>
    </ViewContainer>
  );
};

export default MemberRequestView;
