import "./styles.css";
import { Chip, InputLabel, TextField } from "@mui/material";
import { FC } from "react";
import { ErrorMessage } from "../errorMessage";

type FieldProps = {
  id: string;
  name: string;
  type: string;
  required?: boolean;
  placeholder?: string;
  label?: string;
};
type FileInputProps = {
  fieldProps: FieldProps;
  form: any;
  onChange: (e: any) => void;
  error?: string | boolean;
};
const FileInput: FC<FileInputProps> = ({
  fieldProps,
  form,
  error,
  onChange,
}) => {
  const fileURL = form?.values?.[fieldProps?.name]
    ? URL.createObjectURL(form?.values?.[fieldProps?.name])
    : undefined;
  return (
    <div className="fileInputBoxContainer">
      {fieldProps?.label && (
        <InputLabel htmlFor={fieldProps?.id}>
          {fieldProps?.label}
          {fieldProps?.required ? " * " : ""}
        </InputLabel>
      )}
      {form?.values?.[fieldProps?.name] ? (
        <Chip
          className={
            Boolean(
              error ??
                ((form?.touched as any)?.[fieldProps?.name] &&
                  (form?.errors as any)?.[fieldProps?.name])
            )
              ? "fileChipError"
              : "fileChip"
          }
          label={(form?.values as any)?.[fieldProps?.name]?.name}
          variant="outlined"
          size="medium"
          component="a"
          href={fileURL}
          target="_blank"
          clickable
          onDelete={(e) => {
            e.preventDefault();
            form.setFieldValue(fieldProps?.name, null);
          }}
        />
      ) : (
        <TextField
          className="fileTextField"
          fullWidth
          id={fieldProps.id}
          type={fieldProps.type}
          placeholder="y"
          error={Boolean(
            error ??
              ((form?.touched as any)?.[fieldProps?.name] &&
                (form?.errors as any)?.[fieldProps?.name])
          )}
          onChange={(e: any) => {
            onChange(e?.target?.files?.[0]);
          }}
        />
      )}
      {typeof error !== "boolean" && (
        <ErrorMessage
          message={
            error ??
            ((form?.touched as any)?.[fieldProps?.name] &&
              (form?.errors as any)?.[fieldProps?.name])
          }
        />
      )}
    </div>
  );
};

export default FileInput;
