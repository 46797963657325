import { FC, useState, createContext, useEffect } from "react";
type SidebarContext = {
  toggleSidebar: () => void;
  closeSidebar: () => void;
  setPayload: (newValue: any) => void;
  sidebarToggle: any;
  routes: any;
  payload: any;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SidebarContext = createContext<SidebarContext>(
  {} as SidebarContext
);

const importAll = (context: any) => context.keys().map(context);

export const SidebarProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [routes, setRoutes] = useState();
  const [payload, setPayload] = useState<any>({
    skip: 0,
    take: 5,
    sortOrder: "DESC",
    sortBy: "createdAt",
    search: "",
  });
  useEffect(() => {
    const routeFilesContext = require.context(
      "../pages",
      true,
      /\/route\/index\.tsx$/
    );
    const routeFiles = importAll(routeFilesContext);

    const routes = routeFiles
      .map((file: any) =>
        Array.isArray(file?.default) ? file?.default : [file?.default]
      )
      .flat();
    setRoutes(routes);
  }, []);

  const [sidebarToggle, setSidebarToggle] = useState(false);
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };
  const closeSidebar = () => {
    setSidebarToggle(false);
  };

  return (
    <SidebarContext.Provider
      value={{
        toggleSidebar,
        closeSidebar,
        setPayload,
        routes,
        payload,
        sidebarToggle,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
