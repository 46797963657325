import Family from "../components/family";
import FamiliesList from "../components/familiesList";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import HouseIcon from "@mui/icons-material/House";
import { Navigate } from "react-router-dom";
import FamilyView from "../components/familyView";
const familyRoute = {
  icon: PeopleAltIcon,
  name: "Branch Memberships",
  link: "/branchMembership",
  path: "branchMembership",
  index: 1,
  element: "",
  children: [
    {
      path: "families",
      element: <FamiliesList />,
      name: "Families",
      index: 0,
      link: "branchMembership/families",
      icon: HouseIcon,
    },
    {
      path: "families/create",
      element: <Family />,
    },
    {
      path: "families/:id",
      element: <FamilyView />,
    },
    {
      path: "",
      element: <Navigate to="families" replace />,
    },
  ],
};
export default familyRoute;
