import { Container } from "@mui/material";
import { FC, useContext, useState, useEffect } from "react";
import DataTable from "../../../../../shared/components/dataTable";
import TitleBar from "../../../../../shared/components/titleBar";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import Loader from "../../../../../shared/components/loader/linearProgress";
import NoPermissionContent from "../../../../../shared/components/noPermissionBanner";
import SearchBox from "../../../../../layouts/SidebarLayout/Header/SearchBox";
import { membershipRequest } from "../../../../../shared/interfaces/membership-request";
import { ZSSelectBox } from "../../../../../shared/components/selectBox";

const MembershipRequestList: FC = () => {
  const { payload, setPayload } = useContext(SidebarContext);
  const [statusFilter, setStatusFilter] = useState<string | null>("Pending");

  const { loading, data, fetchData, error } = useQuery(
    "/admin/membership-request",
    {
      params: {
        ...payload,
        requestStatus: statusFilter === "All" ? null : statusFilter,
      },
    }
  );

  const memberRequestDisplayColumns = [
    "fullNameEng",
    "phone",
    "houseNameEng",
    "familyHead",
    "gender",
    "status",
  ];

  let membershipList: membershipRequest[] = [];
  if (data && (data as any)?.items) {
    membershipList = (data as any)?.items.map((item: any) => {
      return {
        id: item.id,
        fullNameEng: item?.fullNameEng,
        phone: item?.phone,
        houseNameEng: item?.family?.familyAddresses[0]?.houseNameEng,
        familyHead: item?.family?.familyMembers[0]?.fullNameEng,
        gender: item?.gender,
        status: item?.requestStatus,
      };
    });
  }

  useEffect(() => {
    if (statusFilter) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilter]);

  const sortableColumnsMemberRequest = ["fullNameEng"];

  return (
    <Container className="tableListContainer">
      <TitleBar
        title="Membership Requests"
        headerTitle="Membership Requests"
        children={
          <>
            <ZSSelectBox
              fieldProps={{
                id: "email-log-filter",
                name: "email-log-filter",
              }}
              value={statusFilter ?? "All"}
              onChange={(value: string) => setStatusFilter(value)}
              options={[
                { value: "All", label: "All" },
                { value: "Pending", label: "Pending" },
                { value: "Approved", label: "Approved" },
                { value: "Rejected", label: "Rejected" },
              ]}
            />
            <SearchBox />
          </>
        }
        disabled={
          (data as any)?.entityPermissions
            ? !(data as any)?.entityPermissions?.includes("Member-Create")
            : false
        }
      ></TitleBar>
      {loading ? (
        <Loader />
      ) : (error as any)?.statusCode === 403 ? (
        <NoPermissionContent />
      ) : (
        <DataTable
          entityName="Membership Requests"
          data={membershipList}
          displayColumns={memberRequestDisplayColumns}
          sortableColumns={sortableColumnsMemberRequest}
          linkColumn="fullNameEng"
          linkUrl="/branchMembership/membershipRequests"
          pagination={{ take: payload.take, skip: payload.skip }}
          setPagination={setPayload}
          totalItems={(data as any)?.totalItems}
          deleteApiUrl="/admin/membership-request"
          refetchFunction={fetchData}
        />
      )}
    </Container>
  );
};

export default MembershipRequestList;
