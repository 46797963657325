/* eslint-disable react-hooks/exhaustive-deps */

import { FC, useEffect } from "react";
import { Grid } from "@mui/material";
import { useFormik } from "formik";
import useLazyQuery from "../../../../../shared/components/hooks/useLazyQuery";

import { useParams } from "react-router-dom";
import { FormContainer } from "../../../../../shared/components/formContainer";
import InputTextfield from "../../../../../shared/components/textField";
import ToggleSwitch from "../../../../../shared/components/switch";
import useMutation from "../../../../../shared/components/hooks/useMutation";

import { ZSSelectBox } from "../../../../../shared/components/selectBox";
import { Level } from "../../../../../shared/enum";
import { getValidationSchema } from "../../../../../shared/validations/schema";

const Course: FC = () => {
  const { id } = useParams();
  const { modifyData } = useMutation("/settings/course");

  const { loading, data, fetchData } = useLazyQuery(
    `/admin/course/${id}`,
    null,
    "/settings/course"
  );

  const courseForm = useFormik({
    initialValues: {
      name: (data as any)?.data?.name ?? "",
      enabled: (data as any)?.data?.enabled ?? true,
      level: (data as any)?.data?.level ?? "",
      specialization: (data as any)?.data?.specialization ?? "",
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: getValidationSchema(["name"]),
    onSubmit: async (formData: any) => {
      if (id) {
        modifyData(
          `/admin/course/${id}`,
          "put",
          {
            ...formData,
          },
          courseForm
        );
      } else {
        modifyData(
          `/admin/course`,
          "post",
          {
            ...formData,
          },
          courseForm
        );
      }
    },
  });
  useEffect(() => {
    if (id) fetchData();
  }, [id]);
  const editPermission = (data as any)?.entityPermissions
    ? !(data as any)?.entityPermissions?.includes("course-Update")
    : false;

  const titleBar = {
    title: id ? (data as any)?.data?.name ?? "" : "Create",
    buttonTitle: id ? "Update" : "Create",
    headerTitle: "Course",
    onClick: () => courseForm.handleSubmit(),
    dirty: courseForm.dirty,
    disabled: editPermission || Object.keys(courseForm.errors).length !== 0,
  };

  return (
    <>
      <FormContainer titleBar={titleBar} loading={loading}>
        <div className={editPermission ? "disabled" : ""}>
          <Grid container alignItems="stretch" columnSpacing={4} rowSpacing={2}>
            <Grid item xs={12}>
              <ToggleSwitch
                status={courseForm?.values?.enabled}
                handleChange={() =>
                  courseForm.setFieldValue(
                    "enabled",
                    !courseForm?.values?.enabled
                  )
                }
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <InputTextfield
                form={courseForm}
                fieldProps={{
                  id: "courseName",
                  name: "name",
                  label: "Course Name",
                  type: "text",
                  placeholder: "Enter Course",
                  required: true,
                  capitalizedFirst: true,
                }}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <ZSSelectBox
                fieldProps={{
                  id: "level",
                  name: "level",
                  required: true,
                  label: "Level",
                  placeholder: "Select Level",
                }}
                form={courseForm}
                options={[
                  { value: Level.Matriculation, label: "Matriculation" },
                  { value: Level.HSC, label: "HSC" },
                  { value: Level.UG, label: "UG" },
                  { value: Level.PG, label: "PG" },
                  { value: Level.Other, label: "Other" },
                ]}
                errorMessage={false}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <InputTextfield
                form={courseForm}
                fieldProps={{
                  id: "specialization",
                  name: "specialization",
                  label: "Specialization",
                  type: "text",
                  placeholder: "Enter Specialization",
                  required: true,
                  capitalizedFirst: true,
                }}
              />
            </Grid>
          </Grid>
        </div>
      </FormContainer>
    </>
  );
};
export default Course;
