import { Navigate } from "react-router-dom";
import Welfare from "../components/welfare";
import WelfareList from "../components/welfareList";
import DiamondIcon from "@mui/icons-material/Diamond";
const welfareRoute = {
  icon: DiamondIcon,
  name: "Welfare Schemes",
  link: "/welfareSchemes",
  path: "welfareSchemes",
  element: "",
  index: 6,
  children: [
    {
      path: "welfare",
      element: <WelfareList />,
      name: "Welfare",
      index: 0,
      link: "welfareSchemes/welfare",
      icon: DiamondIcon,
    },
    {
      path: "welfare/create",
      element: <Welfare />,
    },
    {
      path: "welfare/:id",
      element: <Welfare />,
    },
    {
      path: "",
      element: <Navigate to="welfareType" replace />,
    },
  ],
};
export default welfareRoute;
