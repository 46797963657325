import Role from "../components/role";
import RoleList from "../components/roleList";
import ShieldIcon from "@mui/icons-material/Shield";
import PersonIcon from "@mui/icons-material/Person";
import { Navigate } from "react-router-dom";

const roleRoute = {
  icon: PersonIcon,
  name: "User Management",
  link: "/user-management",
  path: "/user-management",
  element: "",
  index: 7,
  children: [
    {
      path: "role",
      element: <RoleList />,
      name: "Role",
      index: 1,
      link: "user-management/role",
      icon: ShieldIcon,
    },
    {
      path: "role/create",
      element: <Role />,
    },
    {
      path: "role/:id",
      element: <Role />,
    },
    {
      path: "",
      element: <Navigate to="role" replace />,
    },
  ],
};
export default roleRoute;
