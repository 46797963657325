import { Container } from "@mui/material";
import { FC, useContext } from "react";
import DataTable from "../../../../shared/components/dataTable";
import TitleBar from "../../../../shared/components/titleBar";
import { useNavigate } from "react-router-dom";
import { SidebarContext } from "../../../../contexts/SidebarContext";
import useQuery from "../../../../shared/components/hooks/useQuery";
import Loader from "../../../../shared/components/loader/linearProgress";
import NoPermissionContent from "../../../../shared/components/noPermissionBanner";
import { EntityContext } from "../../../../contexts/EntityContext";

const CertificateList: FC = () => {
  const navigate = useNavigate();
  const { payload, setPayload } = useContext(SidebarContext);

  const { loading, data, fetchData, error } = useQuery("/admin/certificate", {
    params: {
      ...payload,
      certificate: "all",
    },
  });
  const { setEntityName } = useContext(EntityContext);

  const navigateToDetails = () => {
    setEntityName({
      entityName: ["Create"],
      entityTitle: "Certificate",
    });
    navigate("/certificate/create");
  };

  const certificateDisplayColumns = ["name", "validityPeriod", "enabled"];

  return (
    <Container className="tableListContainer">
      <TitleBar
        title="Certificate"
        buttonTitle="Create"
        onClick={navigateToDetails}
        headerTitle="Certificate"
        disabled={
          (data as any)?.entityPermissions
            ? !(data as any)?.entityPermissions?.includes("Certificate-Create")
            : false
        }
      />
      {loading ? (
        <Loader />
      ) : (error as any)?.statusCode === 403 ? (
        <NoPermissionContent />
      ) : (
        <DataTable
          entityName="Certificate"
          data={(data as any)?.items ?? []}
          displayColumns={certificateDisplayColumns}
          linkColumn="name"
          linkUrl="/certificate"
          pagination={{ take: payload.take, skip: payload.skip }}
          setPagination={setPayload}
          totalItems={(data as any)?.totalItems}
          deleteApiUrl="/admin/certificate"
          refetchFunction={fetchData}
        />
      )}
    </Container>
  );
};

export default CertificateList;
