// features and associated entities
export const Features = {
    // Member: { Write: ["Language", "Country"] ,specialPermissions: ["Send-Mail","Read", "Create","Update", "Delete"]},
    Member: { Write: ["Family"] },
    Family: {},
    Event: {},
    Welfare: {},
    Certificae: {}, // Service: { Write: ["Language"] },
    // Subscription: { Write: ["Language", "Service"] },
    // "Mail Upload": { Write: ["Member"] },
    // Address: { Write: ["Language", "City"] },
    // Translator: {},
    // "Email Log": {
    //   specialPermissions: ["Read"],
    // },
    // Owner: { Write: ["Role"] },
    // Staff: { Write: ["Role"] },
    // Role: {},
    // Language: {},
    // Currency: {},
    // Country: { Write: ["Language"] },
    // City: { Write: ["Language", "Country"] },
    // "Tax Category": {},
    // "Tax Rate": { Write: ["Country", "TaxCategory"] },
    // "Payment Method": {},
    // "Login Report": {
    //   specialPermissions: ["Read"],
    //   Read: ["Owner"],
    // },
    // "Payment Report": {
    //   specialPermissions: ["Read"],
    //   Read: ["Member"],
    // },
    // "Invoice Report": {
    //   specialPermissions: ["Read"],
    //   Read: ["Member"],
    // },
  };