/* eslint-disable array-callback-return */
import * as yup from "yup";
import regEx from "./regularExpression";

const validationSchema: any = {
  firstName: yup
    .string()
    .trim("First name cannot include leading and trailing spaces")
    .strict(true)
    .min(2, "First name must be more than 2 characters")
    .max(30, "First name cannot exceed more than 30 characters")
    .required("First name is a required field")
    .matches(regEx.alphabetsOnly, "Only alphabets are allowed for this field"),

  lastName: yup
    .string()
    .matches(regEx.alphabetsOnly, "Only alphabets are allowed for this field")
    .trim("Last name cannot include leading and trailing spaces")
    .strict(true)
    .min(1, "Last name must be more than 1 characters")
    .max(30, "Last name cannot exceed more than 30 characters")
    .required("Last name is a required field"),

  fullNameMal: yup
    .string()
    .trim("Full name in malayalam cannot include leading and trailing spaces")
    .strict(true)
    .min(2, "Full name in malayalam must be more than 2 characters")
    .max(30, "Full name in malayalam cannot exceed more than 30 characters")
    .required("Full name in malayalam is a required field")
    .matches(regEx.alphabetsOnly, "Only alphabets are allowed for this field"),

  fullNameEng: yup
    .string()
    .trim("Full name  in english cannot include leading and trailing spaces")
    .strict(true)
    .min(2, "Full name in english must be more than 2 characters")
    .max(30, "Full name in english cannot exceed more than 30 characters")
    .required("Full name in english is a required field")
    .matches(regEx.alphabetsOnly, "Only alphabets are allowed for this field"),
  phone: yup
    .string()
    .strict(true)
    .min(10, "Phone must be 10 characters")
    .max(10, "Phone must be 10 characters")
    .required("Phone is a required field"),
  name: yup
    .string()
    .trim("Name cannot include leading and trailing spaces")
    .strict(true)
    .min(2, "Name must be more than 2 characters")
    .max(30, "Name cannot exceed more than 30 characters")
    .required("Name is a required field")
    .matches(regEx.alphabetsOnly, "Only alphabets are allowed for this field"),
  houseNo: yup.string().required("House No is a required field"),
  houseNameEng: yup.string().required("House Name Eng is a required field"),
  streetNameEng: yup.string().required("Street Name Eng is a required field"),
  wardNo: yup.string().required("Please select a Ward no"),
  gender: yup.string().required("Gender is a required field"),
  kudumbaUnitNameEng: yup.string().required("Please select a Kudumba Unit"),
  dateOfResidence: yup
    .string()
    .required("Date of residance is a required field"),
  eventTypeName: yup
    .string()
    .trim("Event type name cannot include leading and trailing spaces")
    .strict(true)
    .min(2, "Event type name must be more than 2 characters")
    .max(30, "Event type name cannot exceed more than 30 characters")
    .required("Event type name is a required field")
    .matches(regEx.alphabetsOnly, "Only alphabets are allowed for this field"),
  eventTypeId: yup.string().required("Event type is a required field"),
  location: yup.string().required("Location is a required field"),
  validityPeriod: yup.string().required("Validity period is a required field"),
  residenceType: yup.string().required("Please select a residance type"),
  houseNameMal: yup
    .string()
    .required("House name malayalam is a required field"),
  streetNameMal: yup
    .string()
    .required("Street name malayalam is a required field"),
  rationCardNumber: yup
    .string()
    .matches(regEx.rationCard, "Ration Card No must be 10 characters"),
  isValidityPeriod: yup
    .string()
    .required("Is validity period is a required field"),
  email: yup
    .string()
    .trim("Email address cannot include leading and trailing spaces")
    .strict(true)
    .email("Invalid Email address format")
    .matches(regEx.email, "Invalid Email address format")
    .min(2, "Email address must be more than 2 characters")
    .max(50, "Email address cannot exceed more than 30 characters"),
  // .required("Email address is a required field"),
  dob: yup.string().required("Date of birth is a required field"),
  familyID: yup.string().required("Family is a required field"),
  password: yup
    .string()
    .test(
      "betweenSpaces",
      "Password has an excessive number of intermediate spaces",
      (value: any) => {
        if (value?.trim()?.match(regEx.tooManySpace)?.length > 0) {
          return false;
        } else {
          return true;
        }
      }
    )
    .min(8, "Password must be more than 8 characters")
    .max(16, "Password cannot exceed more than 16 characters")
    .required("Password is a required field"),

  newPassword: yup
    .string()
    .test(
      "betweenSpaces",
      "New password has an excessive number of intermediate spaces",
      (value: any) => {
        if (value?.trim()?.match(regEx.tooManySpace)?.length > 0) {
          return false;
        } else {
          return true;
        }
      }
    )
    .min(8, "New password must be more than 8 characters")
    .max(16, "New password cannot exceed more than 16 characters")
    .required("New password is a required field"),
  // remarks: yup.string().when("requestStatus", {
  //   is: "Rejected",
  //   then: yup.string().required("Remarks are required when rejecting"),
  // }),
  confirmPassword: yup
    .string()
    .required("Confirm password is a required field")
    .test(
      "betweenSpaces",
      "Conform password has an excessive number of intermediate spaces",
      (value: any) => {
        if (value?.trim()?.match(regEx.tooManySpace)?.length > 0) {
          return false;
        } else {
          return true;
        }
      }
    )
    .oneOf([yup.ref("newPassword")], "Confirm passwords must match"),

  roleId: yup.string().required("Role is a required field"),
  address: yup.string().strict(true).required("Address is a required field"),
  pinCode: yup.string().strict(true).required("PIN code is a required field"),
  joinedDate: yup
    .string()
    .strict(true)
    .required("Date of join is a required field"),
  startDate: yup
    .string()
    .required("Start Date is required")
    .matches(
      /^\d{2}\/\d{2}\/\d{4}$/,
      "Start Date must be in the format DD/MM/YYYY"
    ),
  endDate: yup
    .string()
    .required("End Date is required")
    .matches(
      /^\d{2}\/\d{2}\/\d{4}$/,
      "End Date must be in the format DD/MM/YYYY"
    ),
  welfareTypeName: yup
    .string()
    .matches(
      regEx.alphabetsOnly,
      "Welfare Type Name can only contain alphabets"
    )
    .test(
      "betweenSpaces",
      "Welfare Type Name has an excessive number of intermediate spaces",
      (value: any) => {
        if (value?.trim()?.match(regEx.tooManySpace)?.length > 0) {
          return false;
        } else {
          return true;
        }
      }
    )
    .min(2, " Welfare Type Name must be more than 2 characters")
    .max(30, " Welfare Type Name cannot exceed more than 30 characters")
    .required(" Welfare Type Name is a required field"),
};

export const getValidationSchema = (fields: string[]) => {
  const newValidationSchema: any = {};

  fields.map((fieldName) => {
    newValidationSchema[fieldName] = validationSchema[fieldName];
  });

  return yup.object().shape(newValidationSchema);
};
