/* eslint-disable react-hooks/exhaustive-deps */
import "./style.css";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import {
  Checkbox,
  FormControlLabel,
  FormLabel,
  Grid,
  List,
  TextField,
} from "@mui/material";
import { FC, Fragment, useContext, useEffect, useState } from "react";
import InputTextfield from "../../../../../shared/components/textField";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import { getValidationSchema } from "../../../../../shared/validations/schema";
import { FormContainer } from "../../../../../shared/components/formContainer";
import { ZSSelectBox } from "../../../../../shared/components/selectBox";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import { EntityContext } from "../../../../../contexts/EntityContext";
import { parse, isValid, format, isAfter } from "date-fns";

import {
  KudumbaUnit,
  MicroFinanceUnit,
  RationCardType,
  ResidenceType,
} from "../../../../../shared/enum";
import { CheckBoxOutlined } from "@mui/icons-material";
import InputFormLabel from "../../../../../shared/components/inputFormLabel";

const Family: FC = () => {
  const { id } = useParams();
  const { loading: apiLoading, modifyData } = useMutation(
    "/branchMembership/families"
  );
  const [selectedMicroFinance, setSelectedMicroFinance] = useState(null);
  const [selectedKudumbaUnit, setSelectedKudumbaUnit] = useState(null);
  const { setEntityName } = useContext(EntityContext);
  // const { parse, isValid } = require("date-fns");

  useEffect(() => {
    setEntityName({
      entityName: ["Create"],
      entityTitle: "Create Family",
    });
  }, [setEntityName]);

  const { data: lsgdsData } = useQuery(`/admin/lsgds`);

  const lsgdsList = (lsgdsData as any)?.items.map((lsgds: any) => ({
    value: lsgds?.id,
    label: `${lsgds?.districtEng}, ${lsgds?.talukEng},  ${lsgds?.panchayathEng},  Ward No - ${lsgds?.wardNo}`,
  }));

  let microFinanceList = Array.from(
    Object.entries(MicroFinanceUnit),
    ([key, value]) => ({
      label: value,
      value: value,
    })
  );

  const onSelectMicrpFinance = (value: any) => {
    setSelectedMicroFinance(value);
    let parts = value.split(" - ");
    let eng = parts[0];
    let mal = parts[1];
    familyForm.setFieldValue("microFinanceNameEng", eng);
    familyForm.setFieldValue("microFinanceNameMal", mal);
  };

  const onSelectKudumbaUnit = (value: any) => {
    setSelectedKudumbaUnit(value);
    let parts = value.split(" - ");
    let eng = parts[0];
    let mal = parts[1];
    familyForm.setFieldValue("kudumbaUnitNameEng", eng);
    familyForm.setFieldValue("kudumbaUnitNameMal", mal);
  };

  const handledateOfResidenceChange = (newValue: string) => {
    let value = newValue.replace(/\D/g, "");
    let dayPart = value.slice(0, 2);
    let monthPart = value.slice(2, 4);
    let yearPartPart = value.slice(4, 8);

    if (dayPart === "00") dayPart = "01";
    if (monthPart === "00") monthPart = "01";

    if (parseInt(dayPart, 10) > 31) dayPart = "31";
    if (parseInt(monthPart, 10) > 12) monthPart = "12";

    if (monthPart === "02" && parseInt(dayPart, 10) > 29) dayPart = "29";
    else if (
      ["04", "06", "09", "11"].includes(monthPart) &&
      parseInt(dayPart, 10) > 30
    )
      dayPart = "30";
    value = dayPart + (value.length >= 2 ? "/" + monthPart : "");
    value = value + (value.length >= 4 ? "/" + yearPartPart : "");
    if (
      newValue?.length === 2 &&
      familyForm?.values?.dateOfResidence?.charAt(
        familyForm?.values?.dateOfResidence?.length - 1
      ) === "/"
    ) {
      value = dayPart;
    }
    familyForm.setFieldValue("dateOfResidence", value);
  };
  const handledateOfJoinChange = (newValue: string) => {
    let value = newValue.replace(/\D/g, "");
    let dayPart = value.slice(0, 2);
    let monthPart = value.slice(2, 4);
    let yearPartPart = value.slice(4, 8);

    if (dayPart === "00") dayPart = "01";
    if (monthPart === "00") monthPart = "01";

    if (parseInt(dayPart, 10) > 31) dayPart = "31";
    if (parseInt(monthPart, 10) > 12) monthPart = "12";

    if (monthPart === "02" && parseInt(dayPart, 10) > 29) dayPart = "29";
    else if (
      ["04", "06", "09", "11"].includes(monthPart) &&
      parseInt(dayPart, 10) > 30
    )
      dayPart = "30";
    value = dayPart + (value.length >= 2 ? "/" + monthPart : "");
    value = value + (value.length >= 4 ? "/" + yearPartPart : "");
    if (
      newValue?.length === 2 &&
      familyForm?.values?.joinedDate?.charAt(
        familyForm?.values?.joinedDate?.length - 1
      ) === "/"
    ) {
      value = dayPart;
    }
    familyForm.setFieldValue("joinedDate", value);
  };

  const textFields = [
    {
      id: "family-houseNameEng",
      name: "houseNameEng",
      label: "House Name (Eng)",
      type: "text",
      placeholder: "House Name (English)",
      required: true,
    },
    {
      id: "family-houseNameMal",
      name: "houseNameMal",
      label: "House Name (Mal)",
      type: "text",
      placeholder: "House Name (Malayalam)",
      required: true,
    },
    {
      id: "family-streetNameEng",
      name: "streetNameEng",
      label: "Street Name (Eng)",
      type: "text",
      placeholder: "Street Name (English)",
      required: true,
    },
    {
      id: "family-streetNameMal",
      name: "streetNameMal",
      label: "Street Name (Mal)",
      type: "text",
      placeholder: "Street Name (Malayalam)",
      required: true,
    },
  ];
  const familyForm = useFormik({
    initialValues: {
      houseNo: "",
      houseNameEng: "",
      houseNameMal: "",
      streetNameEng: "",
      streetNameMal: "",
      rationCardNumber: "",
      rationCardType: null,
      kudumbaUnitNameEng: null,
      kudumbaUnitNameMal: null,
      microFinanceNameEng: "",
      microFinanceNameMal: null,
      residenceType: null,
      currentAddress: true,
      dateOfResidence: "",
      joinedDate: "",
      annualIncome: "",
      addressID: null,
      memberID: null,
      wardNo: null,
      ownLand: false,
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: getValidationSchema([
      "houseNo",
      "houseNameEng",
      "streetNameEng",
      "wardNo",
      "joinedDate",
      "dateOfResidence",
      "residenceType",
      "houseNameMal",
      "streetNameMal",
      "rationCardNumber",
      "kudumbaUnitNameEng",
    ]),
    validate: (values: any) => {
      const errors: any = {};
      if (values?.joinedDate) {
        const isValidDate = validateDateFormat(
          values?.joinedDate,
          "dd/MM/yyyy"
        );
        if (!isValidDate) {
          errors.joinedDate = "Invalid Date formet ( DD/MM/YYYY )";
        } else {
          const isValidAndNotFuture = validateNotFutureDate(
            values?.joinedDate,
            "dd/MM/yyyy"
          );
          if (!isValidAndNotFuture) {
            errors.joinedDate = "Joined Date cannot be a future date";
          }
        }
      }
      if (values?.dateOfResidence) {
        const isValidDate = validateDateFormat(
          values?.dateOfResidence,
          "dd/MM/yyyy"
        );
        if (!isValidDate) {
          errors.dateOfResidence = "Invalid Date formet ( DD/MM/YYYY )";
        } else {
          const isValidAndNotFuture = validateNotFutureDate(
            values?.dateOfResidence,
            "dd/MM/yyyy"
          );
          if (!isValidAndNotFuture) {
            errors.dateOfResidence =
              "Date of residance cannot be a future date";
          }
        }
      }
      return errors;
    },
    onSubmit: async (formData: any) => {
      if (!formData.dateOfResidence) {
        formData.dateOfResidence = null;
      }
      if (id) {
        modifyData(
          `admin/family/${id}`,
          "patch",
          {
            ...formData,
          },
          familyForm
        );
      } else {
        modifyData(
          `admin/family`,
          "post",
          {
            ...formData,
          },
          familyForm
        );
      }
    },
  });

  const handleFieldChange = (name: string, value: string) => {
    familyForm.setFieldValue(name, value);
  };

  const onCheckChange = () => {
    familyForm.setFieldValue("ownLand", !familyForm.values?.ownLand);
  };
  function validateDateFormat(dateString: string, formatString: string) {
    const parsedDate = parse(dateString, formatString, new Date());
    return (
      isValid(parsedDate) && dateString === format(parsedDate, formatString)
    );
  }
  function validateNotFutureDate(dateString: string, formatString: string) {
    const parsedDate = parse(dateString, formatString, new Date());
    const today = new Date();
    return !isAfter(parsedDate, today);
  }
  const titleBar = {
    title: "Create Family",
    buttonTitle: "Save",
    headerTitle: "Family",
    onClick: () => familyForm.handleSubmit(),
    dirty: familyForm.dirty,
    buttonLoading: apiLoading,
  };

  return (
    <FormContainer titleBar={titleBar}>
      <div className="main-div">
        <Grid container alignItems="stretch" columnSpacing={4} rowSpacing={0}>
          <Grid item md={2} sm={2} xs={12}>
            <InputFormLabel
              label={"Date Of Join"}
              required={true}
            ></InputFormLabel>
          </Grid>
          <Grid item md={4} sm={4} xs={12}>
            <InputTextfield
              form={familyForm}
              value={familyForm?.values?.joinedDate || ""}
              fieldProps={{
                id: "family-joinedDate",
                name: "joinedDate",
                type: "text",
                required: true,
                placeholder: "DD/MM/YYYY",
              }}
              onChange={handledateOfJoinChange}
            />
          </Grid>
        </Grid>
        <FormLabel sx={{ mt: 2 }}>
          <b>Address</b>
        </FormLabel>
        <List dense className="border-custom">
          <Grid
            container
            mb={1}
            alignItems="stretch"
            columnSpacing={4}
            rowSpacing={0}
          >
            <Grid item md={2} sm={2} xs={12}>
              <InputFormLabel
                label={"House No"}
                required={true}
              ></InputFormLabel>
            </Grid>
            <Grid item md={4} sm={4} xs={12}>
              <InputTextfield
                form={familyForm}
                fieldProps={{
                  id: "family-houseNo",
                  name: "houseNo",
                  label: "House No",
                  type: "text",
                  placeholder: "House No",
                  required: false,
                }}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="stretch" columnSpacing={4} rowSpacing={0}>
            {textFields.map((field, index: number) => (
              <Fragment key={field.id}>
                <Grid item md={2} sm={2} xs={12}>
                  <InputFormLabel
                    label={field.label}
                    required={field.required}
                  ></InputFormLabel>
                </Grid>
                <Grid item mb={1} md={4} sm={4} xs={12}>
                  <InputTextfield
                    form={familyForm}
                    fieldProps={field}
                    key={index}
                  />
                </Grid>
              </Fragment>
            ))}

            <Grid item md={2} sm={2} xs={12}>
              <InputFormLabel
                label={"Date Of Residence"}
                required={true}
              ></InputFormLabel>
            </Grid>
            <Grid item mb={1} md={4} sm={4} xs={12}>
              <InputTextfield
                form={familyForm}
                value={familyForm?.values?.dateOfResidence || ""}
                fieldProps={{
                  id: "family-dateOfResidence",
                  name: "dateOfResidence",
                  label: "Date Of Residence",
                  type: "text",
                  required: false,
                  placeholder: "DD/MM/YYYY",
                }}
                onChange={handledateOfResidenceChange}
              />
            </Grid>
            <Grid item md={2} sm={2} xs={12}>
              <InputFormLabel
                label={"Residence Type"}
                required={true}
              ></InputFormLabel>
            </Grid>
            <Grid item mb={1} md={4} sm={4} xs={12}>
              <ZSSelectBox
                fieldProps={{
                  id: "family-residenceType",
                  name: "residenceType",
                  placeholder: "Select an option",
                }}
                options={[
                  {
                    label: ResidenceType["Apartments"],
                    value: ResidenceType["Apartments"],
                  },
                  {
                    label: ResidenceType["Terrace"],
                    value: ResidenceType["Terrace"],
                  },
                  {
                    label: ResidenceType["Tiled"],
                    value: ResidenceType["Tiled"],
                  },
                  {
                    label: ResidenceType["Thatched"],
                    value: ResidenceType["Thatched"],
                  },
                ]}
                form={familyForm}
                value={familyForm.values?.residenceType}
                errorMessage={
                  (familyForm.touched as any)?.residenceType &&
                  (familyForm.errors as any)?.residenceType
                    ? (familyForm.errors as any)?.residenceType
                    : null
                }
              />
            </Grid>
            <Grid item md={2} sm={2} xs={12}>
              <InputFormLabel
                label={"Ward No"}
                required={true}
              ></InputFormLabel>
            </Grid>
            <Grid item mb={1} md={6} sm={6} xs={12}>
              <ZSSelectBox
                fieldProps={{
                  id: "family-wardNo",
                  name: "wardNo",
                  placeholder: "Select an option",
                }}
                options={lsgdsList}
                form={familyForm}
                value={familyForm.values?.wardNo}
              />
            </Grid>
          </Grid>
        </List>

        <List dense className="border-custom">
          <Grid container alignItems="stretch" columnSpacing={4} rowSpacing={0}>
            <Grid item md={2} sm={2} xs={12}>
              <InputFormLabel label={"Ration Card Number"}></InputFormLabel>
            </Grid>
            <Grid item mb={1} md={4} sm={4} xs={12}>
              <InputTextfield
                form={familyForm}
                value={familyForm?.values?.rationCardNumber || ""}
                fieldProps={{
                  id: "family-rationCardNumber",
                  name: "rationCardNumber",
                  type: "text",
                  required: true,
                  placeholder: "Ration Card Number",
                }}
              />
            </Grid>
            <Grid item md={2} sm={2} xs={12}>
              <InputFormLabel label={"Ration Card Type"}></InputFormLabel>
            </Grid>
            <Grid item mb={1} md={4} sm={4} xs={12}>
              <ZSSelectBox
                fieldProps={{
                  id: "family-rationCardType",
                  name: "rationCardType",
                  placeholder: "Select an option",
                }}
                options={[
                  {
                    label: RationCardType["APL"],
                    value: RationCardType["APL"],
                  },
                  {
                    label: RationCardType["BPL"],
                    value: RationCardType["BPL"],
                  },
                ]}
                form={familyForm}
                value={familyForm.values?.rationCardType}
              />
            </Grid>
            <Grid item md={2} sm={2} xs={12}>
              <InputFormLabel
                label={"Kudumba Unit"}
                required={true}
              ></InputFormLabel>
            </Grid>
            <Grid item mb={1} md={4} sm={4} xs={12}>
              <ZSSelectBox
                fieldProps={{
                  id: "family-kudumbaUnitNameMal",
                  name: "kudumbaUnitNameMal",
                  placeholder: "Select an option",
                }}
                options={[
                  {
                    value: KudumbaUnit["Kumaranashan Memorial Family Unit"],
                    label: "Kumaranashan Memorial Family Unit",
                  },
                  {
                    value: KudumbaUnit["Dr. Palpu Memorial Family Unit"],
                    label: "Dr. Palpu Memorial Family Unit",
                  },
                  {
                    value: KudumbaUnit["TK Madhavan Memorial Family Unit"],
                    label: "TK Madhavan Memorial Family Unit",
                  },
                  {
                    value: KudumbaUnit["Kumaranashan Memorial Family Unit"],
                    label: "C. Keshavan Memorial Family Unit",
                  },
                ]}
                // form={familyForm}
                value={selectedKudumbaUnit}
                onChange={onSelectKudumbaUnit}
                errorMessage={
                  (familyForm.touched as any)?.kudumbaUnitNameEng &&
                  (familyForm.errors as any)?.kudumbaUnitNameEng
                    ? (familyForm.errors as any)?.kudumbaUnitNameEng
                    : null
                }
              />
            </Grid>
            <Grid item md={2} sm={2} xs={12}>
              <InputFormLabel label={"Annual Income"}></InputFormLabel>
            </Grid>
            <Grid item mb={1} md={4} sm={4} xs={12}>
              <TextField
                id="family-annualIncome"
                variant="outlined"
                placeholder="Annual Income"
                fullWidth
                value={familyForm.values.annualIncome}
                onChange={(e) =>
                  handleFieldChange("annualIncome", e.target.value)
                }
              />
            </Grid>
            <Grid item md={2} sm={2} xs={12}>
              <InputFormLabel label={"Micro Finance Name"}></InputFormLabel>
            </Grid>
            <Grid item mb={1} md={8} sm={8} xs={12}>
              <ZSSelectBox
                fieldProps={{
                  id: "family-microFinanceNameMal",
                  name: "microFinanceNameMal",
                  placeholder: "Select an option",
                }}
                options={microFinanceList}
                onChange={onSelectMicrpFinance}
                // form={familyForm}
                value={selectedMicroFinance}
              />
            </Grid>
            <Grid item md={2} sm={2} xs={12}></Grid>
            <Grid item md={2} sm={2} xs={12}>
              <InputFormLabel label={"Own Land"}></InputFormLabel>
            </Grid>
            <Grid item md={1} sm={1} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    className="selectAll"
                    checkedIcon={<CheckBoxOutlined />}
                    disableRipple
                    checked={familyForm.values?.ownLand}
                    onChange={onCheckChange}
                  />
                }
                labelPlacement="start"
                label={""}
              />
            </Grid>
          </Grid>
        </List>
      </div>
    </FormContainer>
  );
};
export default Family;
