import "./styles.css";
import { Box, Grid } from "@mui/material";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import InputTextfield from "../../../shared/components/textField";
import { getValidationSchema } from "../../../shared/validations/schema";
import useMutation from "../../../shared/components/hooks/useMutation";
import Logo from "../../../assets/img/logo-connect.svg";
import { useSearchParams } from "react-router-dom";

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const tokenValue = searchParams.get("token");
  const { loading, modifyData } = useMutation();

  const textFields = [
    {
      id: "new-password",
      name: "newPassword",
      label: "Password",
      placeholder: "Enter password",
      type: "password",
    },
    {
      id: "confirm-password",
      name: "confirmPassword",
      label: "Confirm Password",
      placeholder: "Confirm new password",
      type: "password",
    },
  ];

  const resetPasswordForm = useFormik({
    initialValues: {
      newPassword: "",
      token: tokenValue,
    },
    validateOnBlur: true,
    validationSchema: getValidationSchema(["newPassword", "confirmPassword"]),
    onSubmit: async (formData: any) => {
      const response = await modifyData(
        `/forgotResetPassword`,
        "post",
        formData
      );
      if (response) resetPasswordForm.resetForm();
    },
  });

  return (
    <Grid container spacing={1} className="login-row">
      <Grid item md={4}></Grid>

      <Grid item md={4} className="">
        <img src={Logo} className="login-logo" alt="" />
        <Box className="fomrCard">
          <form
            onSubmit={resetPasswordForm.handleSubmit}
            autoComplete="off"
            className="resetPasswordForm"
          >
            {textFields.map((field, index: number) => (
              <InputTextfield
                className="mediumInputBox"
                form={resetPasswordForm}
                fieldProps={field}
                key={index}
              />
            ))}

            <Grid item md={12}></Grid>
            <Grid item md={6}>
              <LoadingButton
                className="signInButton"
                loading={loading}
                variant="contained"
                type="submit"
                fullWidth
                size="small"
              >
                Reset Password
              </LoadingButton>
            </Grid>
          </form>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ResetPassword;
