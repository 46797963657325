import { FC, useContext, useEffect } from "react";
import { Grid } from "@mui/material";
import { useFormik } from "formik";
import useLazyQuery from "../../../../../shared/components/hooks/useLazyQuery";
import { useParams } from "react-router-dom";
import { FormContainer } from "../../../../../shared/components/formContainer";
import InputTextfield from "../../../../../shared/components/textField";
import ToggleSwitch from "../../../../../shared/components/switch";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import { ZSAutocomplete } from "../../../../../shared/components/autoComplete";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import { getValidationSchema } from "../../../../../shared/validations/schema";

const Welfare: FC = () => {
  const { id } = useParams();
  const { modifyData } = useMutation("/welfareSchemes/welfare");
  const { payload, setPayload } = useContext(SidebarContext);

  const { loading, data, fetchData } = useLazyQuery(
    `/admin/welfare/${id}`,
    null,
    "/welfareSchemes/welfare"
  );

  const { data: welfareTypeData } = useQuery("/admin/welfareType", {
    params: {
      ...payload,
      take: 10,
      sortOrder: "ASC",
      enabled: true,
    },
  });
  const selectedWelfareType: any =
    data && (data as any)?.data?.welfareType
      ? {
          value: (data as any)?.data?.welfareTypeId,
          label: (data as any)?.data?.welfareType?.welfareTypeName,
        }
      : null;

  const welfareTypes = (welfareTypeData as any)?.items?.map(
    (welfareType: any) => ({
      label: welfareType?.welfareTypeName,
      value: welfareType?.id,
    })
  );

  const welfareForm = useFormik({
    initialValues: {
      welfareTypeName: (data as any)?.data?.welfareTypeName ?? "",
      enabled: (data as any)?.data?.enabled ?? true,
      welfareTypeId: (data as any)?.data?.welfareType?.welfareTypeId ?? "",
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: getValidationSchema(["name", "rolePermissions"]),
    onSubmit: async (formData: any) => {
      if (id) {
        modifyData(
          `/admin/welfare/${id}`,
          "put",
          {
            ...formData,
          },
          welfareForm
        );
      } else {
        modifyData(
          `/admin/welfare`,
          "post",
          {
            ...formData,
          },
          welfareForm
        );
      }
    },
  });
  useEffect(() => {
    if (id) fetchData();
  }, [fetchData, id]);
  const editPermission = (data as any)?.entityPermissions
    ? !(data as any)?.entityPermissions?.includes("Welfare-Update")
    : false;

  const titleBar = {
    title: id ? (data as any)?.data?.welfareTypeName ?? "" : "Create",
    buttonTitle: id ? "Update" : "Create",
    headerTitle: "Welfare",
    onClick: () => welfareForm.handleSubmit(),
    dirty: welfareForm.dirty,
    disabled: editPermission || Object.keys(welfareForm.errors).length !== 0,
  };

  return (
    <>
      <FormContainer titleBar={titleBar} loading={loading}>
        <div className={editPermission ? "disabled" : ""}>
          <Grid container alignItems="stretch" columnSpacing={4} rowSpacing={2}>
            <Grid item xs={12}>
              <ToggleSwitch
                status={welfareForm?.values?.enabled}
                handleChange={() =>
                  welfareForm.setFieldValue(
                    "enabled",
                    !welfareForm?.values?.enabled
                  )
                }
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <InputTextfield
                form={welfareForm}
                fieldProps={{
                  id: "welfareTypeName",
                  name: "welfareTypeName",
                  label: "Welfare Name",
                  type: "text",
                  placeholder: "Enter Welfare",
                  required: true,
                  capitalizedFirst: true,
                }}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <ZSAutocomplete
                options={welfareTypes}
                label="Welfare Type"
                placeholder="Select Welfare Type"
                required
                setValue={(selectedOption: any) => {
                  welfareForm.setFieldTouched("welfareTypeId", true);
                  if (selectedOption?.value) {
                    welfareForm.setFieldValue(
                      "welfareTypeId",
                      selectedOption?.value
                    );
                  } else {
                    welfareForm.setFieldValue("welfareTypeId", "");
                  }
                }}
                defaultValue={selectedWelfareType}
                setBlur={() => {
                  welfareForm.setFieldTouched("welfareTypeId", true);
                }}
                errorMessage={
                  ((welfareForm.touched as any)?.welfareTypeId &&
                    (welfareForm.errors as any)?.welfareTypeId) ??
                  null
                }
                refetchFunction={(searchString: string) => {
                  setPayload((prevData: any) => {
                    return { ...prevData, search: searchString };
                  });
                }}
              />
            </Grid>
          </Grid>
        </div>
      </FormContainer>
    </>
  );
};
export default Welfare;
