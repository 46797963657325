import { Container } from "@mui/material";
import { FC, useContext } from "react";
import DataTable from "../../../../../shared/components/dataTable";
import TitleBar from "../../../../../shared/components/titleBar";
import { useNavigate } from "react-router-dom";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import Loader from "../../../../../shared/components/loader/linearProgress";
import NoPermissionContent from "../../../../../shared/components/noPermissionBanner";
import { EntityContext } from "../../../../../contexts/EntityContext";
import SearchBox from "../../../../../layouts/SidebarLayout/Header/SearchBox";

const EventList: FC = () => {
  const navigate = useNavigate();
  const { payload, setPayload } = useContext(SidebarContext);

  const { loading, data, fetchData, error } = useQuery("/admin/event", {
    params: {
      ...payload,
    },
  });
  const { setEntityName } = useContext(EntityContext);

  const navigateToDetails = () => {
    setEntityName({
      entityName: ["Create"],
      entityTitle: "Events",
    });
    navigate("/eventManagement/events/create");
  };

  const eventDisplayColumns = ["name", "startDate", "endDate"];

  return (
    <Container className="tableListContainer">
      <TitleBar
        title="Events"
        buttonTitle="Create"
        onClick={navigateToDetails}
        headerTitle="Event"
        disabled={
          (data as any)?.entityPermissions
            ? !(data as any)?.entityPermissions?.includes("Event-Create")
            : false
        }
      >
         
        <SearchBox />
      </TitleBar>
      {loading ? (
        <Loader />
      ) : (error as any)?.statusCode === 403 ? (
        <NoPermissionContent />
      ) : (
        <DataTable
          entityName="Event"
          data={(data as any)?.items ?? []}
          displayColumns={eventDisplayColumns}
          linkColumn="name"
          linkUrl="/eventManagement/events"
          pagination={{ take: payload.take, skip: payload.skip }}
          setPagination={setPayload}
          totalItems={(data as any)?.totalItems}
          deleteApiUrl="/admin/event"
          refetchFunction={fetchData}
        />
      )}
    </Container>
  );
};

export default EventList;
